import { moment, translator } from '@smart/design';
import ColorScheme from 'color-scheme';
import { find, get, isNaN, isNumber, toNumber } from 'lodash';

export interface IBaseProps {
  dataModel: MODEL.IAggregatedDataModel;
  aggregation: API.IAggregationObject;
}

class DataModelBuilder {
  public readonly valueValues: string[];
  public readonly serieValues: string[];
  public readonly xAxisValues: string[];

  public readonly hasY: boolean;
  public readonly hasX: boolean;
  public readonly hasSeries: boolean;

  protected readonly aggregationSteps = ['agg1', 'agg2', 'agg3'];
  protected readonly dataModel: MODEL.IAggregatedDataModel;
  protected readonly aggregation: API.IAggregationObject;
  protected readonly lastAggregation: API.IAggregationPipeline;
  protected readonly aggregationMeta: API.IAggregationMetaObject[];
  protected readonly dataHasSeries: boolean;

  protected readonly serieColorMap = new Map<string, string[]>();
  protected readonly valueColorMap = new Map<string, string[]>();

  protected readonly aggregateAsSeries: boolean;
  protected readonly groupAsSeries: boolean;
  protected readonly multipleAxis: boolean;
  protected readonly mutipleSeries: boolean;
  protected readonly multipleValues: boolean;
  protected readonly groupKeySeperator = '|';

  protected readonly colorScheme = new ColorScheme();

  protected readonly maxValue?: number;
  protected readonly minValue?: number;
  constructor(props: IBaseProps) {
    this.dataModel = props.dataModel;
    this.aggregation = props.dataModel.aggregation;
    this.lastAggregation = this.aggregation.agg3 || this.aggregation.agg2 || this.aggregation.agg1;

    this.aggregationMeta = props.aggregation.meta
      ? Object.entries(props.aggregation.meta)
          .sort((a, b) => a[1].order && b[1].order && a[1].order - b[1].order)
          .map(x => ({
            property: x[0],
            ...x[1],
          }))
      : [];

    this.valueValues = this.aggregationMeta.filter(x => x.use === 'value').map(x => x.property);
    this.serieValues = this.aggregationMeta.filter(x => x.use === 'series').map(x => x.property);
    this.xAxisValues = this.aggregationMeta.filter(x => x.use === 'xAxis').map(x => x.property);

    this.hasSeries = this.serieValues.length > 0;
    this.hasY = this.valueValues.length > 0;
    this.hasX = this.xAxisValues.length > 0;
    this.groupAsSeries = this.lastAggregation.by.some(v => this.serieValues.includes(v));
    this.aggregateAsSeries = this.lastAggregation.aggregate.some(v =>
      this.serieValues.includes(v.as)
    );
    this.multipleAxis = this.xAxisValues.length > 1;
    this.multipleValues = this.valueValues.length > 1;
    this.mutipleSeries = this.serieValues.length > 1;

    this.aggregationMeta.forEach(x => {
      if (x.use === 'series') {
        this.serieColorMap.set(x.property, this.createColorSchemes(x.color));
      } else if (x.use === 'value') {
        this.valueColorMap.set(x.property, this.createColorSchemes(x.color));
      }
    });

    try {
      const dataValues: number[] = this.dataModel.data
        .map(x => this.valueValues.map(c => x[c]))
        .flat()
        .filter(x => isNumber(x) && !isNaN(x)) as number[];

      this.maxValue = Math.max(...dataValues);
      this.minValue = Math.min(...dataValues);
    } catch (e) {
      //
    }
  }

  protected getColor(type: 'series' | 'value', key: string, index: number = 0) {
    if (type === 'series') {
      return this.serieColorMap.has(key) && this.serieColorMap.get(key)[index];
    } else if (type === 'value') {
      return this.valueColorMap.has(key) && this.valueColorMap.get(key)[index];
    }
  }

  protected convertDataKey = (key: string) => {
    if (this.hasTimeGroup(key)) return 'datetime';

    return key;
  }

  protected getMeta(property: string, value: string, fallback?: any) {
    return get(
      this.aggregationMeta.find(x => x.property === property),
      value,
      fallback
    );
  }

  protected getPostProcessValue(property: string, type: 'date' | 'number' | 'string') {
    const valuePostProcess = this.getMeta(property, 'valuePostProcess');
    if (valuePostProcess && valuePostProcess.type === type) {
      return valuePostProcess.value;
    }
    else if (property === "dayOfWeek") {
      return "dayOfWeek";
    }
    return null;
  }

  protected getDateFormatter(groupKey: string, fallbackFormatter = 'dddd DD MMMM YYYY') {
    return this.getPostProcessValue(groupKey, 'date') || fallbackFormatter;
  }

  protected getLabelName(groupKey: string, values: string) {
    if (!values) return;
    
    // Fixed dayOfWeek bug
    if (groupKey === "dayOfWeek") {
      return moment.weekdays(toNumber(values));
    }
    
    return values
      .split(this.groupKeySeperator)
      .map(v => {
        switch (groupKey) {
          case 'locationType':
            return translator.t(v) || v;
          case 'entityId':
            return (
              find(this.dataModel.data, {
                [groupKey]: v,
              })?.name || v
            );
          case 'day':
            return moment(v).format(this.getDateFormatter(groupKey, 'dddd DD MMMM YYYY'));
          case 'month':
            return moment(v).format(this.getDateFormatter(groupKey, 'MMMM'));
          case 'hour':
            return moment(v).format(this.getDateFormatter(groupKey, 'HH:mm'));
          case 'quarter':
            return moment(v).format(this.getDateFormatter(groupKey, 'Qo'));
          case 'week':
            return moment(v).format(this.getDateFormatter(groupKey, 'www'));
          case 'year':
            return moment(v).format(this.getDateFormatter(groupKey, 'YYYY'));
          case 'dayOfWeek':
            return moment.weekdays(toNumber(v));
          default:
            return v;
        }
      })
      .join('|');
  }

  protected hasTimeGroup(prop) {
    return ['hour', 'quarter', 'day', 'week', 'month', 'year'].includes(prop);
  }

  protected isGroupBy(id: string) {
    return this.lastAggregation.by.includes(id);
  }

  private createColorSchemes(color: string) {
    return ['default', 'pastel', 'soft'].reduce((acc, u) => {
      acc = acc.concat(
        this.colorScheme
          .from_hex((color || '#FFFFFF').replace('#', ''))
          .scheme('analogic')
          .distance(0.5)
          .variation(u)
          .colors()
          .map(z => `#${z}`)
      );
      return acc;
    }, []);
  }
}
export default DataModelBuilder;
