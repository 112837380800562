import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  SmartForm as SF,
  translator,
  Tabs,
  Wrapper,
  Seperator,
  helper,
  useDidUpdate,
  Actions,
  BS,
  BType,
} from '@smart/design';
import { useBuilder } from '../BuilderContext';
import DefaultFilterForm from './DefaultFilterForm';
import PropertyTypeModel from '../../../models/PropertyTypeModel';
import AggregationForm from './aggregation/AggregationForm';
import { aggregationArrayToObject, aggregationObjectToArray } from './common';
import { useComponentContext } from '../displays/ComponentContext';
interface IProps {
  form: Partial<FORM.IMapComponentForm>;
  onChange: (component: FORM.IMapComponentForm) => void;
}
const MapForm = (props: IProps) => {
  const { companyId, reportId, setFormComponent } = useBuilder();
  const [mapForm, setMapForm] = useState<Partial<FORM.IMapComponentForm>>(props.form);
  const componentContext = useComponentContext();
  const [a1, a2] = aggregationObjectToArray(props.form.aggregation, {
    agg1: {
      by: ['entityId'],
      aggregate: [],
    },
  });

  const aggregateForm = useRef<FORM.IIPipelineAggregation[]>(a1);
  const aggregateUsages = useRef<FORM.IAggregationUsage[]>(a2);

  useDidUpdate(() => {
    props.onChange({
      aggregation: mapForm.aggregation,
      id: mapForm.id,
      type: mapForm.type,
      mapType: mapForm.mapType,
      overrideFilter: mapForm.overrideFilter,
      property: mapForm.property,
      ranges: mapForm.ranges,
    });
  }, [mapForm]);
  return (
    <div>
      <SF.Form<FORM.IMapComponentForm>
        onChange={async (f, formState) => {
          setMapForm({
            ...mapForm,
            ...f,
          });
        }}
        noSubmit={true}
        form={[
          {
            property: 'property',
            type: 'searchSelect',
            value: props.form.property,
            required: () => true,
            fetch: async q => {
              const d = await PropertyTypeModel.load(companyId);
              return helper.searchArray(d, 'value', q);
            },
            preload: async () => {
              return PropertyTypeModel.load(companyId);
            },
            component: v => {
              return <div>{v}</div>;
            },
            label: translator.t('label.property'),
          },
          {
            property: 'mapType',
            type: 'select',
            value: props.form.mapType,
            required: () => true,
            options: [
              {
                label: 'choropleth',
                value: 'choropleth',
                id: 'choropleth',
              },
            ],
            component: v => {
              return <div>{v}</div>;
            },
            label: translator.t('label.mapType'),
          },
          {
            property: 'ranges',
            type: 'spreadsheet',
            value: props.form.ranges,
            rowKey: (row, index) => `${row?.name}_${index}`,
            required: () => true,
            columns: [
              {
                label: 'label',
                form: {
                  type: 'input',
                  required: () => false,
                  property: 'name',
                },
              },
              {
                label: 'min',
                form: {
                  type: 'input',
                  number: true,
                  property: 'min',
                },
              },
              {
                label: 'max',
                form: {
                  type: 'input',
                  number: true,
                  property: 'max',
                },
              },
              {
                label: 'color',
                form: {
                  type: 'color',
                  property: 'color',
                  mode: 'trigger',

                  picker: 'twitter',
                  required: () => true,
                },
              },
            ],

            label: translator.t('label.property'),
          },
        ]}
      >
        <SF.Field property="mapType" />
        <SF.Field property="property" />
        <SF.Field property="ranges" />
      </SF.Form>
      {mapForm.mapType && mapForm.ranges ? (
        <Tabs.Container>
          <Tabs.Tab tabId="aggregation" label={translator.t('label.aggregation')}>
            <Seperator />
            <AggregationForm
              aggregation={aggregateForm.current}
              usages={aggregateUsages.current}
              type="map"
              property={props.form.property}
              onApply={(agg, usages) => {
                setMapForm({
                  ...mapForm,
                  aggregation: aggregationArrayToObject(agg, usages),
                });
              }}
            />
          </Tabs.Tab>
          <Tabs.Tab tabId="filter" label={translator.t('label.filter')}>
            <Seperator />
            <DefaultFilterForm
              form={props.form?.overrideFilter}
              onChange={f => {
                setMapForm({
                  ...mapForm,
                  overrideFilter: f,
                });
              }}
              overrideAble={true}
            />
          </Tabs.Tab>
        </Tabs.Container>
      ) : null}
    </div>
  );
};

export default MapForm;
