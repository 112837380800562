import { BS, BSize, BStyle, Center, IconStyle, SmartForm as SF, translator } from '@smart/design';
import React, { useState } from 'react';
import { ComponentType } from '../../../statics';
import gaugeURL from '../../../../statics/img/gauge.svg';
import labelURL from '../../../../statics/img/label.svg';

const ComponentPickerForm = (
  props: PropsWithClassName<{
    componentType?: ComponentType;
    formId: string;
    setType: (form: FORM.IComponentFormBase) => void;
  }>
) => {
  const [pickMode, setpickMode] = useState<boolean>(false);

  if (!props.componentType && !pickMode) {
    return (
      <Center className={props.className} full={true}>
        <BS.IconButton
          icon="icon-plus"
          bSize={BSize.LARGE}
          bStyle={BStyle.PRIMARY}
          onClick={d => {
            setpickMode(true);
          }}
        />
      </Center>
    );
  }
  return (
    <div className={props.className}>
      <SF.Form<{
        type: ComponentType | 'none';
      }>
        form={[
          SF.createFormField({
            property: 'type',
            type: 'boxSelect',
            label: '',
            value: props.componentType,
            optionRender: option => {
              if (option.iconUrl) {
                return (
                  <Center full>
                    <img width={50} height={50} src={option.iconUrl}></img>
                  </Center>
                );
              }
              return (
                <Center
                  full
                  icon={option.icon}
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: 3,
                    ...option.style,
                  }}
                  iconStyle={option.iconStyle || IconStyle.PRIMARY}
                  textCenter={true}
                ></Center>
              );
            },
            disabled: f => (f.type ? true : false),
            options: [
              {
                label: translator.t('type.text'),
                value: ComponentType.TEXT,
                id: ComponentType.TEXT,

                icon: 'icon-font',
              },
              {
                label: translator.t('type.image'),
                value: ComponentType.IMAGE,
                id: ComponentType.IMAGE,
                icon: 'icon-image',
              },
              {
                label: translator.t('type.graph'),
                value: ComponentType.GRAPH,
                id: ComponentType.GRAPH,

                icon: 'icon-chart-line',
              },
              {
                label: translator.t('type.map'),
                value: ComponentType.MAP,
                id: ComponentType.MAP,

                icon: 'icon-map',
              },
              {
                label: translator.t('type.label'),
                value: ComponentType.LABEL,
                id: ComponentType.LABEL,

                iconUrl: labelURL,
              },
              {
                label: translator.t('type.label'),
                value: ComponentType.GAUGE,
                id: ComponentType.GAUGE,
                iconUrl: gaugeURL,
              },
              {
                label: translator.t('type.clock'),
                value: ComponentType.CLOCK,
                id: ComponentType.CLOCK,
                icon: 'icon-clock',
              },
              {
                label: translator.t('type.times'),
                value: 'none',
                iconStyle: IconStyle.DANGER,
                icon: 'icon-times',
              },
            ],
          }),
        ]}
        noSubmit={true}
        onChange={f => {
          if (f.type === 'none') {
            setpickMode(false);
          } else {
            props.setType({
              type: f.type,
              id: props.formId,
            });
          }
        }}
      >
        <SF.Field property="type" />
      </SF.Form>
    </div>
  );
};

export default ComponentPickerForm;
