import {
  Actions,
  BS,
  BSize,
  BStyle,
  Error,
  IconStyle,
  Router,
  Seperator,
  SmartForm,
  SplitView,
  styled,
  translator,
  Wrapper,
  Center,
} from '@smart/design';
import { IconButton } from '@smart/design/src/scopes/core/components/Button';
import { Observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { BuilderProvider, useBuilder } from './Builder/BuilderContext';
import Dashboard from './Builder/Dashboard';
import ComponentForm from './Builder/forms/ComponentForm';
import ReportDetailForm from './Builder/forms/ReportDetailForm';
import Store from './Builder/Store';
import { cancelCreate, createDashboard, updateDashboard } from './Builder/UIActions';

const StickyContainer = styled.div`
  position: sticky;
  height: 75px;
  bottom: 0px;
  display: flex;
  overflow: hidden;
  justify-content: center;
  background-color: #ffffff;
`;

const Builder = () => {
  const context = useBuilder();

  const setLayouts = useCallback(
    (layouts: Layout[]) => {
      layouts.forEach(l => {
        context.form.layouts[l.i] = l;
      });
      context.onFormChange({
        ...context.form,
      });
    },
    [context.form]
  );

  const onSave = useCallback(async () => {
    if (context.isNew) {
      return createDashboard(context);
    }
    return updateDashboard(context);
  }, [context]);
  const [activeLayout, setActiveLayout] = useState<Layout>(null);
  return (
    <Dashboard
      selectLayout={layout => {
        setActiveLayout(layout);
      }}
      changeLayout={setLayouts}
      setFormType={(layout, type) => {
        context.setFormComponent(layout.i, type);
        if (!type) {
          setActiveLayout(null);
        } else {
          setActiveLayout(layout);
        }
      }}
      activeLayout={activeLayout}
    />
  );
};

export const BuilderView = () => {
  const { companyId, reportId } = Router.useParams<ROUTER.IReportRouterParams>();
  const viewOnly = Router.useRouteMatch({
    path: '/companies/:companyId/dashboards/:reportId/view',
    exact: true,
    strict: true,
  })
    ? true
    : false;

  const store = useRef(new Store(companyId, reportId, viewOnly));
  useEffect(() => {
    store.current.setData(companyId, reportId, viewOnly);
    store.current.load();
  }, [companyId, reportId]);

  return (
    <Observer
      render={() => {
        if (!store.current.Loaded) return null;

        if (store.current.Error) {
          return <Error error={store.current.Error} />;
        }

        return (
          <BuilderProvider value={store.current.Value}>
            <Builder />
          </BuilderProvider>
        );
      }}
    ></Observer>
  );
};
export default BuilderView;
