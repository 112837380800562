import {
  Actions,
  BSize,
  BStyle,
  BType,
  Container,
  DataTable,
  HeadingSize,
  IDatatableRef,
  modalManager,
  Router,
  Seperator,
  ShowMoreText,
  Static,
  Tooltip,
  translator,
} from '@smart/design';
import React, { useRef } from 'react';
import ComplianceButton from '../components/ComplianceButton';
import ReportModel from '../models/ReportModel';
import { duplicateDashboard } from './Builder/UIActions';
import AccessModal from './Dashboards/AccessModal';
import {
  copyDashboardLink,
  editDashboard,
  goBuilder,
  removeDashboard,
  viewDashboard,
  openCreateDashboardModal,
} from './Dashboards/UIActions';

const Reports = () => {
  const { companyId, reportId } = Router.useParams<ROUTER.IReportRouterParams>();
  const ref = useRef<IDatatableRef<API.IDashBoard>>();

  const email = translator.t('dashboards.email')
  const mailhref = `mailto: ${email}`;

  return (
    <Container>
      <Seperator marginTop={40} />
      <Static size={HeadingSize.LARGE} label={translator.t('dashboards.title')}>
        <p>{translator.t('dashboards.description1')}<a href={mailhref}>{email}</a>{translator.t('dashboards.description2')}</p>
      </Static>
      <DataTable<API.IDashBoard>
        actions={[
          <ComplianceButton
            action="CreateDashboard"
            label={translator.t('btn.addNewDashboard')}
            bSize={BSize.MEDIUM}
            bStyle={BStyle.PRIMARY}
            onClick={async () => {
              openCreateDashboardModal(companyId);
            }}
          ></ComplianceButton>,
        ]}
        displaySearch={false}
        tableRef={ref}
        renderKey={'reportId'}
        columns={[
          {
            property: 'title',

            content: f => <span>{f.title}</span>,
            label: translator.t('label.name'),
          },
          {
            property: 'description',
            content: f => <span>{f.description}</span>,
            label: translator.t('label.description'),
          },
          {
            property: 'actions',
            alignHeader: 'left',
            style: {
              width: '50%',
            },
            content: f => (
              <Actions.Container noPadding={true}>
                <ComplianceButton
                  bStyle={BStyle.DANGER}
                  action="DeleteDashboard"
                  label={translator.t('btn.remove')}
                  onClick={async () => {
                    await removeDashboard(companyId, f.reportId);
                    ref.current.refetch(true);
                  }}
                />
                <ComplianceButton
                  bStyle={BStyle.PRIMARY}
                  action="DeleteDashboard"
                  label={translator.t('btn.edit')}
                  onClick={async () => {
                    await editDashboard(companyId, f.reportId);
                    ref.current.refetch(true);
                  }}
                />

                <ComplianceButton
                  bStyle={BStyle.PRIMARY}
                  action="ViewDashboard"
                  bType={BType.HOLLOW}
                  label={translator.t('dashboards.btn.view')}
                  onClick={async () => {
                    await viewDashboard(companyId, f.reportId);
                    ref.current.refetch(true);
                  }}
                />
                <ComplianceButton
                  bStyle={BStyle.WARNING}
                  action="EditAccess"
                  bType={BType.HOLLOW}
                  label={translator.t('dashboards.btn.access')}
                  onClick={async () => {
                    modalManager.show(AccessModal, {
                      reportId: f.reportId,
                      companyId: f.companyId,
                    });
                  }}
                />
                <ComplianceButton
                  bStyle={BStyle.WARNING}
                  action="EditAccess"
                  bType={BType.HOLLOW}
                  label={translator.t('dashboards.btn.duplicate')}
                  onClick={async () => {
                    await duplicateDashboard(f);
                    ref.current.refetch(true);
                  }}
                />
              </Actions.Container>
            ),
            label: translator.t('label.actions'),
          },
        ]}
        fetch={async q => {
          const m = await ReportModel.listReports(companyId, q.search);
          return m;
        }}
      ></DataTable>
    </Container>
  );
};
export default Reports;
