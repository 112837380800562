import React from 'react';

import {
  Modal,
  SmartForm,
  Error,
  Center,
  Button,
  translator,
  BStyle,
  ModalSize,
} from '@smart/design';
import ReportDetailForm from '../forms/ReportDetailForm';
import Store from '../Store';
import { Observer } from 'mobx-react-lite';
import { BuilderProvider } from '../BuilderContext';
import DefaultFilterForm from '../forms/DefaultFilterForm';

interface IProps {
  companyId: string;
}
class NewDashboardModal extends Modal<IProps, {}, string> {
  private store: Store;
  constructor(props) {
    super(props);
    this.state = {
      modalOptions: {
        size: ModalSize.MEDIUM,
        title: translator.t('modal.title.newDashboard'),
      },
    };
    this.store = new Store(props.companyId, null, false);
  }
  public componentDidMount() {
    this.store.load();
  }
  public componentWillUnmount() {
    this.store.removeCached();
  }

  public renderContent() {
    return (
      <Observer
        render={() => {
          if (!this.store.Loaded) return null;
          if (this.store.Error) {
            return <Error error={this.store.Error} />;
          }

          return (
            <BuilderProvider value={this.store.Value}>
              <ReportDetailForm autoChange={false} autoSync={false} />
              <DefaultFilterForm
                showFilterButton={false}
                form={this.store.Value.filters}
                onChange={f => {
                  this.store.updateFilter(f);
                }}
              />
              <Center>
                <Button
                  bStyle={BStyle.PRIMARY}
                  label={translator.t('btn.createDashboard')}
                  onClick={async () => {
                    const reportId = await this.store.syncRemote();
                    this.closeModal(reportId);
                  }}
                />
              </Center>
            </BuilderProvider>
          );
        }}
      ></Observer>
    );
  }
}
export default NewDashboardModal;
