import React, { useRef, useCallback, useState, useEffect, RefObject, useMemo } from 'react';
import { omit } from 'lodash';
import {
  useThemeContext,
  BStyle,
  BS,
  BType,
  translator,
  Static,
  Wrapper,
  styled,
  TooltipPlacement,
  Icon,
  Tooltip,
} from '@smart/design';
import { useBuilder } from '../BuilderContext';
import DefaultFilterForm from './DefaultFilterForm';
import ReportModel from '../../../models/ReportModel';
const F = styled.div`
  border-radius: 3px 3px 3px 3px;
`;

const W = 350;
const GlobalFilterForm = (props: { bounds: RefObject<HTMLElement>; offset?: string }) => {
  const { onFilterChange, form, filters, viewOnly } = useBuilder();
  const [bounds, setBounds] = useState<DOMRect>();
  const theme = useThemeContext();
  const updateReportForm = useCallback(
    (f: Partial<FORM.IReportForm>) => {
      onFilterChange(ReportModel.extractFilter(f));
    },
    [form]
  );
  const ref = useRef<HTMLDivElement>();
  const [show, setShow] = useState<boolean>(true);
  useEffect(() => {
    const t = () => {
      if (props.bounds.current) setBounds(props.bounds.current.getBoundingClientRect());
    };
    window.addEventListener('resize', t);
    return () => {
      window.removeEventListener('resize', t);
    };
  }, []);
  useEffect(() => {
    if (props.bounds.current) setBounds(props.bounds.current.getBoundingClientRect());
  }, [props.bounds.current]);

  return useMemo(() => {
    if (!bounds) {
      return null;
    }
    return (
      <div
        style={{
          position: 'fixed',
          top: bounds.top,
          right: `calc(100% - ${bounds.width}px + ${props.offset})`,
          zIndex: 2,
          bottom: 0,
          width: show ? W : 0,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 'calc(50% + -40px)',
            backgroundColor: 'rgba(0,0,0,.2)',
            right: show ? W : 0,
            height: 60,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            borderRadius: '4px 0px 0px 4px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setShow(!show);
          }}
        >
          <Tooltip
            content={translator.t('btn.openGlobalFilter')}
            placement={TooltipPlacement.LEFT}
            style={{
              position: 'absolute',
              left: 5,
            }}
          >
            <Icon icon={show ? 'icon-chevron-right' : 'icon-chevron-left'}></Icon>
          </Tooltip>
        </div>

        <div
          ref={ref}
          style={{
            position: 'absolute',
            right: 0,
            width: show ? '100%' : 0,
            transition: 'width 0.2s ease-in-out',
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: '100%',
          }}
        >
          <div
            style={{
              backgroundColor: theme.colors.grey,
            }}
          >
            <Static
              center={true}
              label={translator.t('label.globalFilter')}
              showDivider
              underline={true}
            >
              <Wrapper>
                <DefaultFilterForm
                  stickyBottom={5}
                  overrideAble={false}
                  form={filters}
                  onChange={f => {
                    updateReportForm(f);
                  }}
                />
              </Wrapper>
            </Static>
          </div>
        </div>
      </div>
    );
  }, [bounds, show, props.offset]);
};
export default GlobalFilterForm;
