import { Router } from '@smart/design';
import Dashboards from './views/Dashboards';
import Builder from './views/Builder';

const RouteConfig: Router.IPath[] = [
  {
    path: '/companies/:companyId/dashboards',
    component: Dashboards,
    exact: true,
  },
  {
    path: '/companies/:companyId/dashboards/:reportId/view',
    component: Builder,
    exact: true,
  },
  {
    path: '/companies/:companyId/dashboards/:reportId/builder',
    component: Builder,
    exact: true,
  },
];
export default RouteConfig;
