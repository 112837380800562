'use strict';
import { Chart, CategoryScale } from 'chart.js';
import { parentsOf } from '../utils';

const defaultConfig = Object.assign({}, CategoryScale.defaults, {
  /**
   * reduce the space between items at level X by this factor
   */
  levelPercentage: 0.75,
  /**
   * additional attributes to copy, e.g. backgroundColor
   * object where the key is the attribute and the value the default value if not explicity specified in the label tree
   */
  attributes: {},

  /**
   * top/left padding for showing the hierarchy marker
   */
  padding: 25,
  /**
   * position of the hierarchy label
   * possible values: 'below', 'above', null to disable
   */
  hierarchyLabelPosition: 'below',
  /**
   * size of the box to draw
   */
  hierarchyBoxSize: 14,
  /**
   * distance between two hierarhy indicators
   */
  hierarchyBoxLineHeight: 30,
  /**
   * color of the line indicator hierarchy children
   */
  hierarchySpanColor: 'gray',
  /**
   * storke width of the line
   */
  hierarchySpanWidth: 2,
  /**
   * color of the box to toggle collapse/expand
   */
  hierarchyBoxColor: 'gray',
  /**
   * stroke width of the toggle box
   */
  hierarchyBoxWidth: 1,
});

class HierarchicalScale extends CategoryScale {
  public nodes: any;
  public minIndex: number;
  public maxIndex: number;

  public determineDataLimits() {
    const data = this.chart.data;
    const labels = this.options.labels || data.labels;

    // labels are already prepared by the plugin just use them as ticks
    this.nodes = labels.slice();
    // not really used
    this.minIndex = 0;
    this.maxIndex = this.nodes.length;
    this.min = this.nodes[this.minIndex];
    this.max = this.nodes[this.maxIndex];

    // this.options.barThickness = 'flex';
  }

  public buildTicks() {
    const hor = this.isHorizontal();
    const total = hor ? this.width : this.height;
    const minMaxNodes = this.nodes.slice(this.minIndex, this.maxIndex);

    const flat = (this.chart.data as any).flatLabels;

    if (minMaxNodes.length === 0) {
      this.ticks = [];
      return this.ticks;
    }

    // optimize such that the distance between two points on the same level is same
    // creaiing a grouping effect of nodes
    const ratio = (this.options as any).levelPercentage;

    // max 5 levels for now
    const ratios = [
      1,
      Math.pow(ratio, 1),
      Math.pow(ratio, 2),
      Math.pow(ratio, 3),
      Math.pow(ratio, 4),
      Math.pow(ratio, 5),
      Math.pow(ratio, 6),
      Math.pow(ratio, 7),
      Math.pow(ratio, 8),
      Math.pow(ratio, 9),
      Math.pow(ratio, 10),
    ];
    const distances = [];
    let prev = minMaxNodes[0];
    let prevParents = parentsOf(prev, flat);
    distances.push(0.5); // half top level distance before and after

    for (let i = 1; i < minMaxNodes.length; ++i) {
      const n = minMaxNodes[i];
      const parents = parentsOf(n, flat);
      if (prev.parent === n.parent) {
        // same parent -> can use the level distance
        distances.push(ratios[n.level]);
      } else {
        // differnt level -> use the distance of the common parent
        // find level of common parent
        let common = 0;
        while (parents[common] === prevParents[common]) {
          common++;
        }
        distances.push(ratios[common]);
      }
      prev = n;
      prevParents = parents;
    }
    distances.push(0.5);

    const distance = distances.reduce((acc, s) => acc + s, 0);
    const factor = total / distance;
    let offset = distances[0] * factor;
    minMaxNodes.forEach((node, i) => {
      const previous = distances[i] * factor;
      const next = distances[i + 1] * factor;
      node.center = offset;
      node.width = Math.min(next, previous) / 2;
      offset += next;
    });

    this.ticks = minMaxNodes.map(d => Object.assign({}, d)); // copy since mutated during auto skip
    return this.ticks;
  }

  public _convertTicksToLabels(ticks) {
    return ticks.map(d => d.label);
  }

  public getLabelForIndex(index, datasetIndex) {
    const data = this.chart.data;
    const isHorizontal = this.isHorizontal();

    // if (data.yLabels && !isHorizontal) {
    //   return this.getRightValue(data.datasets[datasetIndex].data[index]);
    // }
    return this.nodes[index - this.minIndex].label;
  }

  // Used to get data value locations.  Value can either be an index or a numerical value
  public getPixelForValue(value, index) {
    // If value is a data object, then index is the index in the data array,
    // not the index of the scale. We need to change that.
    {
      let valueCategory;
      if (value !== undefined && value !== null) {
        valueCategory = this.isHorizontal() ? value.x : value.y;
      }
      if (valueCategory !== undefined || (value !== undefined && isNaN(index))) {
        value = valueCategory || value;
        const idx = this.nodes.findIndex(d => d.label === value);
        index = idx !== -1 ? idx : index;
      }
    }

    return this._centerBase(index);
  }

  public getPixelForTick(index) {
    if (index === 1 && this.nodes.length === 1) {
      // cornercase in chartjs to determine tick with, hard coded 1
      return this.nodes[0].width;
    }

    return this._centerBase(index + this.minIndex);
  }

  public _centerBase(index) {
    const centerTick = this.options.offset;
    const base = this.isHorizontal() ? this.left : this.top;
    const node = this.nodes[index];

    if (node == null) {
      return base;
    }

    const nodeCenter = node.center != null ? node.center : 0;
    const nodeWidth = node.width != null ? node.width : 0;
    return base + nodeCenter - (centerTick ? 0 : nodeWidth / 2);
  }

  public getValueForPixel(pixel) {
    return this.nodes.findIndex(
      d => pixel >= d.center - d.width / 2 && pixel <= d.center + d.width / 2
    );
  }

  public getBasePixel() {
    return this.bottom;
  }
}
HierarchicalScale.id = 'hierarchical';
HierarchicalScale.defaults = defaultConfig;

Chart.register(HierarchicalScale);

export default HierarchicalScale;
