import { unset } from 'lodash';
import { moment } from '@smart/design';
import { action, computed, observable, toJS, observe } from 'mobx';
import ReportModel from '../../models/ReportModel';
import { IBuilderContext } from './BuilderContext';
import tz from 'moment-timezone';

class Store {
  public static toPlainObject(form: FORM.IReportForm) {
    return toJS(form);
  }

  private companyId: string;
  private reportId: string;
  @observable
  private storeId: string;

  @observable
  private cached: boolean = false;

  @observable
  private pendingChanges: boolean = false;

  @observable
  private error: Error;
  @observable
  private model: MODEL.IReportModel;

  @observable.deep
  private form: FORM.IReportForm;

  private viewOnly: boolean;
  @observable
  private loaded: boolean;

  private refreshIntervalTimer: number;
  private refreshedTimeStamp: number;
  constructor(companyId: string, reportId: string = null, viewOnly: boolean = false) {
    this.viewOnly = viewOnly;
    this.companyId = companyId;
    this.reportId = reportId;
    this.storeId = `report-${this.reportId || 'new'}`;
    this.cached = this.getCached() ? true : false;
  }
  @computed
  public get Form() {
    return this.form;
  }
  @computed
  public get Error() {
    return this.error;
  }

  public get PendingChanges() {
    return this.pendingChanges;
  }
  public get IsNew() {
    return !this.reportId;
  }
  @computed
  public get HasCachedVersion() {
    return this.cached;
  }

  public get CompanyId() {
    return this.companyId;
  }
  public get ReportId() {
    return this.reportId;
  }

  public get LastTimeRefreshed() {
    return this.refreshedTimeStamp;
  }

  @computed
  public get Loaded() {
    return this.loaded;
  }
  @computed
  public get Value(): IBuilderContext {
    return {
      filters: ReportModel.extractFilter(this.form),
      companyId: this.companyId,
      reportId: this.reportId || ReportModel.getId(),
      form: this.form,
      isNew: this.IsNew,
      viewOnly: this.viewOnly,
      pendingChanges: this.pendingChanges,
      cached: this.HasCachedVersion,
      applyCurrentCached: cached => {
        if (!cached) {
          this.removeCached();
        } else {
          this.applyCache();
        }
      },
      setFormComponent: (id: string, form: FORM.IComponentFormType) => {
        this.setComponent(id, form);
      },
      getFormComponent(id: string) {
        return this.form.componentsForms[id];
      },
      setLayout: (id: string, layout: ReactGridLayout.Layout) => {
        this.setLayout(id, layout);
      },
      getLayout: (id: string) => {
        return this.form.layouts[id];
      },
      onFormChange: f => {
        this.update(f);
      },
      deleteFormComponent: (id: string) => {
        this.deleteComponent(id);
      },
      onFilterChange: f => {
        this.updateFilter(f);
      },
      syncRemote: () => {
        return this.syncRemote();
      },
    };
  }

  public setData(companyId: string, reportId: string = null, viewOnly: boolean = false) {
    this.viewOnly = viewOnly;
    this.companyId = companyId;
    this.reportId = reportId;
    this.storeId = `report-${this.reportId || 'new'}`;
    this.cached = this.getCached() ? true : false;
  }

  @action.bound
  public setComponent(id: string, form: FORM.IComponentFormType) {
    this.form.componentsForms[id] = form;

    this.update(this.form);
  }
  @action.bound
  public setLayout(id: string, layout: ReactGridLayout.Layout) {
    layout.moved = true;
    this.form.layouts[id] = layout;

    this.update(this.form);
  }
  @action.bound
  public deleteComponent(id: string) {
    unset(this.form.componentsForms, id);

    this.update(this.form);
  }
  @action.bound
  public load = async () => {
    if (this.companyId && this.reportId) {
      try {
        this.error = null;
        this.model = await ReportModel.load({
          companyId: this.companyId,
          reportId: this.reportId,
        });
        this.form = ReportModel.toForm(this.model);
      } catch (e) {
        this.error = e;
        throw e;
      } finally {
        this.loaded = true;
      }
    } else {
      this.form = ReportModel.toForm(null);
      this.form.companyId = this.companyId;

      this.loaded = true;
    }

    this.setInterval();
  };
  @action.bound
  public updateFilter(form: Partial<API.IDefaultFilter>) {
    this.form = Object.assign({}, this.form, this.applyDateRanges(form));
    this.pendingChanges = true;

    localStorage.setItem(`report-${this.reportId || 'new'}`, JSON.stringify(this.form));
  }
  @action.bound
  public update(form: Partial<FORM.IReportForm>) {
    this.form = Object.assign({}, this.form, this.applyDateRanges(form));
    this.pendingChanges = true;
    localStorage.setItem(`report-${this.reportId || 'new'}`, JSON.stringify(this.form));
    this.setInterval();
  }

  public getCached(): FORM.IReportForm {
    const item = localStorage.getItem(this.storeId);
    if (item) {
      return JSON.parse(item);
    }
    return null;
  }
  public applyCache() {
    this.form = this.getCached();
    this.pendingChanges = true;

    this.removeCached();
  }
  public removeCached() {
    localStorage.removeItem(this.storeId);
    this.cached = false;
  }

  public async syncRemote() {
    const method = this.IsNew ? ReportModel.create : ReportModel.update;
    const reportId = await method.call(ReportModel, this.form);
    this.removeCached();
    this.pendingChanges = false;

    return reportId;
  }
  private applyDateRanges(form: Partial<FORM.IReportForm>) {
    const range = ReportModel.fromDateRangeToStartAndEndDate(form.dateRange, {
      startDate: form.startDate,
      endDate: form.endDate,
      startTime: form.startTime,
      endTime: form.endTime,
    });
    Object.assign(form, range);

    return form;
  }

  private setInterval() {
    clearInterval(this.refreshIntervalTimer);

    if (!this.viewOnly || !this.form.refreshInterval || this.form.refreshInterval === 'none') {
      return;
    }
    const timer = () => {
      this.refreshedTimeStamp = moment().unix();
      this.updateFilter(this.applyDateRanges(this.form));
    };

    switch (this.form.refreshInterval) {
      case '1m':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60);
        break;
      case '5m':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 5);
        break;
      case '10m':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 10);
        break;
      case '20m':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 20);
        break;
      case '30m':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 30);
        break;
      case '1h':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 60);
        break;
      case '6h':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 60 * 6);
        break;
      case '12h':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 60 * 12);
        break;
      case '24h':
        this.refreshIntervalTimer = setInterval(timer, 1000 * 60 * 60 * 24);
        break;
      case 'none':
      default:
        clearInterval(this.refreshIntervalTimer);
        break;
    }
  }
}
export default Store;
