import { Model, IApi, Api } from '@smart/design';
import mapService from '../services/mapService'
interface IConfig {
  apiURL: string;
}


interface ICompanyMapModel{
  collection:MAP.LocationCollection
  bounds:mapboxgl.LngLatBounds
}
class CompanyMapModel extends Model<ICompanyMapModel, IConfig, string> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);   
  }
  public idMapper(id:string){
    return id
  }
  protected mapObject(obj: ICompanyMapModel['collection'] ): ICompanyMapModel {
    return {
      bounds:mapService.getMapBoundsFromFeatures(obj.features),
      collection:obj
    };
  }
 
  protected async single(id: string) {
   const { data } = await this.api.get(`client/companies/${id}/map`);
    return {
      data,
      identifier: id,
    };
  }
}
export default new CompanyMapModel('CompanyMapModel');
