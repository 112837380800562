import { IButtonProps } from '@smart/design';
import { IChartType } from 'chart.js';
import { IBuilderContext } from '../../../views/Builder/BuilderContext';
import DataModelBuilder from './AggregationModel';
export interface IProps {
  component: FORM.IGraphComponentForm;
  dataModel: MODEL.IAggregatedDataModel;
  type: IChartType;
}
abstract class BaseChart extends DataModelBuilder {
  protected readonly component: Partial<FORM.IGraphComponentForm>;
  protected type: IChartType;

  protected context: IBuilderContext;

  constructor(props: IProps) {
    super({
      dataModel: props.dataModel,
      aggregation: props.component.aggregation,
    });
    this.type = props.type;
    this.component = props.component;
  }
  public abstract getConfig(): CHART.ChartConfigurationType;
  public async initialize(config: { context: IBuilderContext }) {
    this.context = config.context;
  }

  public getActions(): IButtonProps[] {
    return [];
  }
}
export default BaseChart;
