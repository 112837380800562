import React from 'react';
interface IProps {
  component: FORM.IImageComponentForm;
}
const ImageDisplay = (props: IProps) => {
  return (
    <div style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '100%' }}>
      <img
        style={{
          objectFit: 'contain',
          maxHeight: '100%',
          maxWidth: '100%',
        }}
        src={props.component.url || props.component.base64}
        alt={props.component.alt}
      ></img>
    </div>
  );
};

export default ImageDisplay;
