import { gte, lte, findLast } from 'lodash';
export function ConvertDataToCSV(
  obj: any,
  opt: { scopechar: string; delimeter: string } = { scopechar: '/', delimeter: ',' }
) {
  if (typeof obj !== 'object') return null;
  const { scopechar, delimeter } = opt;

  if (Array.isArray(obj) === false) obj = [obj];
  let curs;
  let name;
  let rownum;
  let key;
  let queue;
  const values = [];
  const rows = [];
  const headers = {};
  const headersArr = [];
  for (rownum = 0; rownum < obj.length; rownum++) {
    queue = [obj[rownum], ''];
    rows[rownum] = {};
    while (queue.length > 0) {
      name = queue.pop();
      curs = queue.pop();
      if (curs !== null && typeof curs === 'object') {
        for (key in curs) {
          if (curs.hasOwnProperty(key)) {
            queue.push(curs[key]);
            queue.push(name + (name ? scopechar : '') + key);
          }
        }
      } else {
        if (headers[name] === undefined) headers[name] = true;
        rows[rownum][name] = curs;
      }
    }
    values[rownum] = [];
  }
  // create csv text
  for (key in headers) {
    if (headers.hasOwnProperty(key)) {
      headersArr.push(key);
      for (rownum = 0; rownum < obj.length; rownum++) {
        values[rownum].push(
          rows[rownum][key] === undefined ? '' : JSON.stringify(rows[rownum][key])
        );
      }
    }
  }
  for (rownum = 0; rownum < obj.length; rownum++) {
    values[rownum] = values[rownum].join(delimeter);
  }
  return '"' + headersArr.join('"' + delimeter + '"') + '"\n' + values.join('\n');
}

export function findRange(value: number, ranges: FORM.IRange[]) {
  return findLast(ranges, c => gte(value, c.min || -Infinity) && lte(value, c.max || Infinity));
}
