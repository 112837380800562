import React, { useRef, useEffect, Component, useState, createRef } from 'react';
import { Chart } from 'chart.js';
import { Error as ErrorDisplay } from '@smart/design';
import { isEqual, debounce } from 'lodash';
interface IProps {
  chartConfig: CHART.ChartConfigurationType;
}

class ChartModelDisplay extends Component<IProps> {
  private ref = createRef<HTMLCanvasElement>();
  private chart: Chart;
  private updateChart = debounce(() => {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.ref.current.getContext('2d'), this.props.chartConfig);
  }, 700);
  public componentDidMount() {
    this.chart = new Chart(this.ref.current.getContext('2d'), this.props.chartConfig);
  }
  public componentWillUnmount() {
    this.chart.destroy();
  }
  public componentDidUpdate(prevProps: IProps, prevState) {
    if (!isEqual(prevProps.chartConfig, this.props.chartConfig)) {
      this.updateChart();
    }
  }
  public componentDidCatch(error, errorInfo) {
    return <ErrorDisplay error={error}></ErrorDisplay>;
  }
  public render() {
    return <canvas ref={this.ref} />;
  }
}

export default ChartModelDisplay;
