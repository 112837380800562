import { Model, IApi, Api, IDataTableFetchResponse, helper } from '@smart/design';

import { get } from 'lodash';
interface IConfig {
  apiURL: string;
}
// Generated by https://quicktype.io

interface IProfile {
  sub: string;
  email: string;
  phone_number: string;
  isSudo: boolean;
  companies: Array<{
    _id: string;
    name: string;
    roles: Array<{ name: string }>;
    permissions: {
      [key: string]: {
        allowed: boolean;
      };
    };
  }>;
}

interface IModel {
  isSudo: boolean;
  view: boolean;
  create: boolean;
  delete: boolean;
}
class ProfileModel extends Model<IModel, IConfig, string> {
  private api: IApi;
  private cacheApi: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
    this.cacheApi = Api.newApi(config.apiURL, {
      useCache: true,
    });
  }

  public idMapper(id) {
    return `client`;
  }
  public async searchCompanies(
    query?: string
  ): Promise<
    IDataTableFetchResponse<{
      id: string;
      name: string;
    }>
  > {
    const { data } = await this.cacheApi.get<{
      offset: number;
      limit: number;
      count: number;
      companies: Array<{
        name: string;
        _id: string;
      }>;
    }>('client/companies');
    const ss = helper.searchArray(data.companies, 'name', query);
    return {
      rows: ss.map(x => {
        return {
          id: x._id,
          name: x.name,
        };
      }),
      meta: {
        total: data.count,
      },
    };
  }
  protected mapObject(obj: IProfile, id): IModel {
    const d = obj.companies.reduce((acc, c) => {
      acc[c._id] = {
        create: c.roles.find(x => x.name ==='sudo') !== undefined,
        view: c.roles.find(x => x.name ==='admin') !== undefined 
              || c.roles.find(x => x.name ==='support') !== undefined
              || c.roles.find(x => x.name ==='itAdmin') !== undefined
              || c.roles.find(x => x.name ==='sudo') !== undefined,
        delete: c.roles.find(x => x.name ==='sudo') !== undefined,
      };
      return acc;
    }, {});

    return {
      isSudo: obj.isSudo,
      ...d[id],
    };
  }

  protected async single(identifier) {
    const { data } = await this.api.get(`client/profile`);

    return {
      data,
      identifier,
    };
  }
}
export default new ProfileModel('ProfileModdel');
