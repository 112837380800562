import { BType, helper, IButtonProps, ITree, translator } from '@smart/design';
import { ITreeNode } from '@smart/design/src/scopes/common/helpers/treeHelper';
import { compact, debounce, get, isArray, isNull, merge } from 'lodash';
import { ILabelNode } from 'plugins/hierachy-scale/types';
import { IBuilderContext } from 'views/Builder/BuilderContext';
import LocationTreeModel from '../../../models/LocationTreeModel';
import LocationTypeTreeModel from '../../../models/LocationTypeTreeModel';
import BaseChart from './BaseChart';

enum ByType {
  locationType = 'locationType',
  entityId = 'entityId',
}
interface IValueNode {
  value?: number;
  children?: IValueNode[];
}
class HierarchyChart extends BaseChart {
  private tree: ITree = null;
  private by: keyof typeof ByType;
  private dataMap: Map<string, API.IAggregatePropertyData>;
  private visibleDataMap: Set<string> = new Set();
  private focusIds = new Set<string>();

  private flatTree: ITreeNode[];
  constructor(props: {
    component: FORM.IGraphComponentForm;
    dataModel: MODEL.IAggregatedDataModel;
  }) {
    super({
      component: props.component,
      dataModel: props.dataModel,
      type: 'bar',
    });
    this.focusIds = new Set(get(this.component.attributes, 'focusIds', []));
    console.log("constructor.focusIds",this.focusIds);
  }

  public setTree(tree: ITree, by: keyof typeof ByType) {
    this.tree = tree;
    this.by = by;
    this.dataMap = new Map(this.dataModel.data.map(i => [i[this.by] as string, i]));
  }

  public async initialize(config: { context: IBuilderContext }) {
    this.context = config.context;

    if (this.isGroupBy('entityId')) {
      this.by = 'entityId';
      this.tree = helper.treeHelper.asTreeNode(
        await LocationTreeModel.load({
          companyId: config.context.companyId,
          reportId: config.context.reportId,
        })
      );
    } 
    else if (this.isGroupBy('locationType')) {
      this.by = 'locationType';
      this.tree = helper.treeHelper.asTreeNode(
        await LocationTypeTreeModel.load({
          companyId: config.context.companyId,
          reportId: config.context.reportId,
        })
      );
    }

    this.flatTree = helper.treeHelper.flatten(this.tree);

    this.dataMap = new Map(this.dataModel.data.map(i => [i[this.by] as string, i]));

    if (this.dataModel.filter.locations.length) {
      const locations = this.flatTree.filter(x => this.dataModel.filter.locations.includes(x.id));
      locations.forEach(x => {
        if (!this.visibleDataMap.has(x.id)) {
          helper.treeHelper.flattenParent(x).forEach(s => {
            this.visibleDataMap.add(s.id);
          });
        }
      });
    } 
    else {
      this.flatTree.forEach(s => this.visibleDataMap.add(s.id));
    }
  }

  public getData(tree: ITree, valueKey: string, level: number) {
    let v = get(this.dataMap.get(tree.id), valueKey, null);

    let children = [];
    if (isArray(tree.children)) {
      children = tree.children.map(c => this.getData(c, valueKey, level + 1));
      const t: number[] = compact(children.map(x => x.value));

      v = v === null ? parseFloat((t.reduce((acc, x) => acc + x, 0) / t.length).toFixed(2)) : v;
    }

    // showNullValuedLabels actually means "Display only labels with value" i.e. the opposite
    const showOnlyDataWithValue = this.component.showNullValuedLabels;
    if (showOnlyDataWithValue && (isNull(v) || isNaN(v))) {
      this.visibleDataMap.delete(tree.id);
    }
    else if (!this.visibleDataMap.has(tree.id)) {
      console.log("try to re-add the data point to the visibleDataMap");
      // Re-add the data point to the visibleDataMap
      this.visibleDataMap.add(tree.id);
    }

    return {
      value: v,
      dataTitle: tree.title,
      dataId: tree.id,
      children,
    };
  }

  public filterData(values: IValueNode[]) {
    
    // showNullValuedLabels actually means "Display only labels with value" i.e. the opposite
    const showOnlyDataWithValue = this.component.showNullValuedLabels;

    return values
      .filter(v => !(showOnlyDataWithValue && (isNull(v.value) || isNaN(v.value))))
      .map(x => ({
        value: x.value,
        children: this.filterData(x.children),
      }));
  }

  public getLabels(tree: ITree, level: number) {
    
    if (!this.visibleDataMap.has(tree.id)) return;

    const children: any[] = compact(
      tree.children ? tree.children.map(t => this.getLabels(t, level + 1)) : []
    );

    const c: ILabelNode = {
      id: tree.id,
      label: tree.title,
      children,
    };

    if (this.focusIds.has(tree.id)) {
      c.expand = true;
      c.hidden = true;
    }

    return c;
  }

  public getActions(): IButtonProps[] {
    if (this.context.viewOnly) {
      return [];
    }

    return [
      {
        icon: 'icon-sitemap',
        tooltip: translator.t('label.saveCurrentStructure'),

        bType: BType.ICON,
        onClick: () => {
          this.component.attributes = merge(this.component.attributes, {
            focusIds: Array.from(this.focusIds.values()),
          });
          this.context.setFormComponent(this.component.id, this.component);
        },
      },
    ];
  }

  // Traverse all children to make sure all focusIds are removed (or added) when navigating
  public updateFocusIds(label) {
    if (label) {
      if (label.expand) {
        this.focusIds.add(label.id);
        console.log("updateFocusIds add label.id", label.id);
      }
      else {
        this.focusIds.delete(label.id);
        console.log("updateFocusIds delete label.id", label.id);
      }
    }
    if (label.children && label.children.length > 0) {
      label.children.forEach(child => this.updateFocusIds(child));
    }
  }

  public getConfig(): CHART.HierarhyCharConfiguration {
    let datasets = [];
    let labels = [];


    console.log("getConfig this.visibleDataMap",this.visibleDataMap);
    console.log("getConfig this.tree",this.tree);
    console.log("getConfig this.focusIds",this.focusIds);

    if (this.tree) {
      datasets = this.valueValues.map(x => {
        const treeMap = this.tree.children.map(t => this.getData(t, x, 0));
        console.log("getConfig.treeMap", treeMap);
        const filteredData = this.filterData(treeMap);
        console.log("getConfig.filteredData", filteredData);

        return {
          label: x,
          datalabels: {
            display: this.component.showDataLabels ? 'auto' : false,
            align: 'bottom',
            anchor: 'end',
            clamp: true,
            font: {
              size: 12,
            },
            color: "#222",
          },
          borderColor: this.getColor('value', x, 0),
          backgroundColor: this.getColor('value', x, 0),

          borderWidth: 1,
          data: filteredData,
        };
      });

      labels = compact(this.tree.children.map(t => this.getLabels(t, 0)));
      console.log("datasets", datasets);
      console.log("labels", labels);
    }

    return {
      data: {
        datasets,
        labels,
      },
      type: 'bar',

      options: {
        datalabels: {
          display: this.component.showDataLabels ? 'auto' : false,
          align: 'bottom',
          anchor: 'end',
          clamp: true,
          font: {
            size: 12,
          },
        },
        title: {
          display: this.component.graphTitle && true,
          text: this.component.graphTitle,
        },
        tooltips: {
          callbacks: {
            title: (l, o) => {
              return l
                .map(c => {
                  return get(c, ['dataset', 'currentLabels', c.dataIndex], '');
                })
                .join(' | ');
            },
          },
        },

        responsive: true,
        aspectRatio: 1,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 200,
            top: 50,
            left: 15,
            right: 15,
          },
        },

        scales: {
          x: {
            type: 'hierarchical',
            padding: 0,
            offset: true,
            hierarchyBoxSize: 20,
            hierarchySpanWidth: 2,
            hierarchyLabelPosition: 'below',
            autoSkip: false,
            drawOnChartArea: false,
            // grid line settings
            gridLines: {
              offsetGridLines: true,
            },
            ticks: {
              autoSkip: false,
            },
            onExpandAndCollapse: debounce((ticks: any = [], label) => {
              // console.log("onExpandAndCollapse label",label);
              // console.log("onExpandAndCollapse this.visibleDataMap",this.visibleDataMap);
              // console.log("onExpandAndCollapse this.focusIds before", this.focusIds);

              // Label equals the expanded location or the child of a collapsed location
              if (label) {
                // Traverse nodes from parent down
                if (label.parentNode) {
                  this.updateFocusIds(label.parentNode);
                }
                else {
                  this.updateFocusIds(label);
                }
              }

              console.log("onExpandAndCollapse after", this.focusIds);
            }, 1000),
            scaleLabel: {
              display: this.component.xLabel && true,
              labelString: this.component.xLabel,
            },
          },
          y: {
            type: 'linear',
            axis: 'y',
            scaleLabel: {
              display: this.component.yLabel && true,
              labelString: this.component.yLabel,
            },
          },
        },
      },
    };
  }
}

export default HierarchyChart;
