import React, { useRef } from 'react';
import { IFormField, SmartForm as SF, translator, Row, Col } from '@smart/design';
interface IProps {
  form: Partial<FORM.IClockComponentForm>;
  onChange: (component: FORM.IClockComponentForm) => void;
}
type IForm = Pick<
  FORM.IClockComponentForm,
  'hour' | 'refreshInterval' | 'backgroundColor' | 'textColor'
>;
const ClockForm = (props: IProps) => {
  const ref = useRef<Array<IFormField<IForm>>>([
    {
      label: translator.t('clock.hours'),
      type: 'select',
      options: [
        {
          label: '24h',
          value: '24',
        },
        {
          label: '12h',
          value: '12',
        },
      ],
      property: 'hour',
      value: '24',
    },
    {
      label: translator.t('clock.refreshInterval'),
      type: 'input',
      description: 'ms',
      value: 1000,
      number: true,
      property: 'refreshInterval',
    },
    {
      label: translator.t('label.backgroundColor'),
      type: 'color',
      property: 'backgroundColor',
      mode: 'default',
    },
    {
      label: translator.t('label.textColor'),
      type: 'color',
      property: 'textColor',
      mode: 'default',
    },
  ]);

  return (
    <SF.Form<IForm>
      form={ref.current}
      autoFocus={true}
      onSubmit={async f => {
        props.onChange({
          id: props.form.id,
          type: props.form.type,
          hour: f.hour,
          backgroundColor: f.backgroundColor,
          refreshInterval: f.refreshInterval,
          textColor: f.textColor,
        });
      }}
    >
      <Row>
        <Col xs={6}>
          <SF.Field property="hour" />
        </Col>
        <Col xs={6}>
          <SF.Field property="refreshInterval" />
        </Col>
      </Row>
      <SF.Field property="backgroundColor" />
      <SF.Field property="textColor" />
    </SF.Form>
  );
};

export default ClockForm;
