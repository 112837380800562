import { IDatasetChartOptions, ILinearScaleOptions } from 'chart.js';

import { IOptions } from '../../../plugins/data-labels/options';
import CartesianChart from './CartesianChart';
class LineChart extends CartesianChart {
  constructor(props) {
    super({
      component: props.component,
      dataModel: props.dataModel,
      type: 'line',
    });
  }
  protected getDatasetOptions() {
    const dataset: DeepPartial<IDatasetChartOptions<'line'>['line']['datasets']> & {
      datalabels: IOptions;
    } = {
      spanGaps: true,
      borderWidth: 1,
      datalabels: {
        display: this.component.showDataLabels ? 'auto' : false,
        align: 'top',
        clamp: true,
        anchor: 'end',
        formatter: x => {
          return x.y;
        },
      },
    };
    return dataset;
  }
}

export default LineChart;
