import { Deferred, helper, modals, notifications, translator } from '@smart/design';
import html2canvas from 'html2canvas';
import { ConvertDataToCSV } from './common';
export async function exportCSV(
  component: FORM.IGraphComponentForm,
  dataModel: MODEL.IDataModel
): Promise<void> {
  const def = new Deferred<void>();

  try {
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(ConvertDataToCSV(dataModel.data))
    );
    link.setAttribute('download', `${component.graphTitle}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    def.resolve();
  } catch (error) {
    modals.error(error);
    def.reject(error);
  }

  return def.promise;
}
export async function copyComponentToClipboard(htmlNode: HTMLElement) {
  const def = new Deferred<void>();
  let img = null;
  if (!navigator.clipboard) {
    def.reject();
  } else {
    try {
      const t = await html2canvas(htmlNode, {
        allowTaint: true,
        ignoreElements: (element: Element) => {
          return element.classList.contains('print-ignore');
        },
        imageTimeout: 2000,
        scrollY: -window.scrollY,
      });
      img = document.createElement('img');
      img.src = t.toDataURL();
      document.body.appendChild(img);
      const r = document.createRange();
      r.setStartBefore(img);
      r.setEndAfter(img);
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(r);
      document.execCommand('copy');
      def.resolve();
    } catch (e) {
      def.reject(e);
    } finally {
      img && document.body.removeChild(img);
    }
  }

  def.promise
    .then(() => {
      notifications.info(translator.t('notifications.componentIsCopiedToClipboard'));
    })
    .catch(e => {
      notifications.error(helper.locateErrorMessage(e));
    });

  return def.promise;
}
