import { ThemeProvider } from '@smart/design';
import { Chart, IChartOptions, UpdateMode, Element, IArcProps, DoughnutController } from 'chart.js';

class GaugeController extends DoughnutController {
  public static id: string = 'gauge';
  public static defaults = Object.assign({}, DoughnutController.defaults, {
    needle: {
      // Needle circle radius as the percentage of the chart area width
      radiusPercentage: 2,
      // Needle width as the percentage of the chart area width
      widthPercentage: 3.2,
      // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
      lengthPercentage: 80,
      // The color of the needle
      color: 'rgba(0, 0, 0, 1)',
    },
    valueLabel: {
      // fontSize: undefined
      display: true,
      formatter: null,
      color: 'rgba(255, 255, 255, 1)',
      backgroundColor: 'rgba(0, 0, 0, 1)',
      borderRadius: 5,
      fontSize: 25,
      padding: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      bottomMarginPercentage: 0,
    },
    layout: {
      padding: {
        bottom: 75,
      },
    },
    animation: {
      duration: 1000,
      animateRotate: true,
      animateScale: false,
    },

    // The percentage of the chart that we cut out of the middle.
    cutoutPercentage: 50,
    // The rotation of the chart, where the first data arc begins.
    rotation: -Math.PI,
    // The total circumference of the chart.
    circumference: Math.PI,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  });

  private previous = { value: 0, maxValue: 0 };
  private current = { value: 0, maxValue: 0 };

  public constructor(chart, datasetIndex) {
    super(chart, datasetIndex);
  }
  // // overrides
  public update(mode: UpdateMode) {
    const dataset = this.getDataset() as IChartOptions['gauge']['datasets'];

    const initialValue = {
      value: 0,
      maxValue: 1,
    };
    // animations on will call update(reset) before update()
    if (mode === 'reset') {
      this.previous = initialValue;
      this.current = initialValue;
    } else {
      this.previous = this.current || initialValue;
      this.current = {
        value: dataset.value,
        maxValue: this.getMaxValue(),
      };
    }
    super.update(mode);
  }

  public draw() {
    super.draw();

    this.drawNeedle();
    this.drawValueLabel();
  }
  protected getOptions(): typeof GaugeController.defaults {
    return this.chart.options;
  }

  protected getMaxValue() {
    return Math.max(...(this.getDataset().data as number[]));
  }
  protected getWidth() {
    return this.chart.chartArea.right - this.chart.chartArea.left;
  }
  protected getTranslation() {
    const { chartArea } = this.chart;
    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    const dx = centerX + this.offsetX;
    const dy = centerY + this.offsetY;
    return { dx, dy };
  }
  protected getAngle(value: number, maxValue: number) {
    const { rotation, circumference } = this.chart.options as IChartOptions<'doughnut'>;
    return rotation + circumference * (value / maxValue);
  }
  protected drawNeedle(ease = 1) {
    if (!this.chart.options.animation) {
      // triggered when hovering
      ease = 1;
    }
    const { innerRadius, outerRadius } = this;
    const { ctx } = this.chart;
    const dataset = this.getDataset() as IChartOptions['gauge']['datasets'];
    const { radiusPercentage, widthPercentage, lengthPercentage, color } = this.getOptions().needle;

    const width = this.getWidth();
    const needleRadius = (radiusPercentage / 100) * width;
    const needleWidth = (widthPercentage / 100) * width;
    const needleLength = (lengthPercentage / 100) * (outerRadius - innerRadius) + innerRadius;

    // center
    const { dx, dy } = this.getTranslation();

    // interpolate
    const origin = this.getAngle(this.previous.value, this.previous.maxValue);
    const target = this.getAngle(this.current.value, this.getMaxValue());
    const angle = origin + (target - origin) * ease;
    // draw
    ctx.save();
    ctx.translate(dx, dy);
    ctx.rotate(angle);
    ctx.fillStyle = color;

    // draw circle
    ctx.beginPath();
    ctx.ellipse(0, 0, needleRadius, needleRadius, 0, 0, 2 * Math.PI);
    ctx.fill();

    // draw needle
    ctx.beginPath();
    ctx.moveTo(0, needleWidth / 2);
    ctx.lineTo(needleLength, 0);
    ctx.lineTo(0, -needleWidth / 2);
    ctx.fill();

    ctx.restore();
  }

  protected updateElement(
    arc: Element & IArcProps,
    index: number,
    properties: any,
    mode: UpdateMode
  ) {
    const previousValue = (this.getDataset().data[index - 1] as number) || 0;
    const value = this.getDataset().data[index] as number;

    // TODO handle reset and options.animation
    const options = this.getOptions();

    // scale data
    const maxValue = this.getMaxValue();

    const startAngle = options.rotation + options.circumference * (previousValue / maxValue);
    const endAngle = startAngle + options.circumference * ((value - previousValue) / maxValue);

    const circumference = endAngle - startAngle;
    properties.startAngle = startAngle;
    properties.endAngle = endAngle;
    properties.circumference = circumference;
    super.updateElement(arc, index, properties, mode);
  }
  protected drawValueLabel() {
    const { valueLabel } = this.getOptions();

    if (!valueLabel.display) return;

    const { ctx, config } = this.chart;

    const {
      formatter,
      fontSize,
      color,
      backgroundColor,
      borderRadius,
      padding,
      bottomMarginPercentage,
    } = valueLabel;
    const width = this.getWidth();

    const bottomMargin = (bottomMarginPercentage / 100) * width;
    const fmt = formatter || (value => value);
    const formattedValue = fmt(this.current.value);
    if (!formattedValue) return;
    const valueText = formattedValue.toString();
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    if (fontSize) {
      ctx.font = `${fontSize}px ${this.chart.options.font.family}`;
    }

    const { width: textWidth } = ctx.measureText(valueText);
    // approximate height until browsers support advanced TextMetrics
    const textHeight = Math.max(ctx.measureText('m').width, ctx.measureText('\uFF37').width);

    const x = -(padding.left + textWidth / 2);
    const y = -(padding.top + textHeight / 2);
    const w = padding.left + textWidth + padding.right;

    const h = padding.top + textHeight + padding.bottom;

    // center
    let { dx, dy } = this.getTranslation();

    // add rotation
    const rotation = this.getOptions().rotation % (Math.PI * 2.0);
    dx += bottomMargin * Math.cos(rotation + Math.PI / 2);
    dy += bottomMargin * Math.sin(rotation + Math.PI / 2);
    // draw
    ctx.save();
    ctx.translate(dx, dy);
    //   // draw background
    ctx.beginPath();
    ctx.ellipse(0, 0, width * 0.15, width * 0.15, Math.PI, 0, Math.PI);

    ctx.fillStyle = backgroundColor;
    ctx.fill();

    // draw value text
    ctx.fillStyle = color;
    const magicNumber = -1; // manual testing
    ctx.fillText(valueText, 0, textHeight * magicNumber);
    ctx.restore();
  }
}
Chart.register(GaugeController);
export default GaugeController;
