import React, { useRef } from 'react';
import { IFormField, SmartForm as SF } from '@smart/design';
interface IProps {
  form: Partial<FORM.ITextComponentForm>;
  onChange: (component: FORM.ITextComponentForm) => void;
}
const TextForm = (props: IProps) => {
  const ref = useRef<Array<IFormField<{ text: string }>>>([
    {
      label: '',
      type: 'wysiwyg',
      contentType: 'html',
      property: 'text',
      value: props.form?.html || '',
    },
  ]);

  return (
    <SF.Form<{ text: string }>
      form={ref.current}
      autoFocus={true}
      noSubmit={true}
      onChange={f => {
        console.log('change', f);

        props.onChange({
          html: f.text,
          text: null,
          id: props.form.id,
          type: props.form.type,
        });
      }}
    >
      <SF.Field property="text" />
    </SF.Form>
  );
};

export default TextForm;
