import { Model, IApi, Api } from '@smart/design';
import mapService from '../services/mapService';
interface IConfig {
  apiURL: string;
}

class LocationMapModel extends Model<MODEL.ILocationMapModel, IConfig, MODEL.ILocationIdentifier> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
    // this.api.registerMock(new RegExp('/*/'),'GET').reply(200,locationmap);
  }
  public idMapper(id: MAP.ILocationIdentifier) {
    return `${id.companyId}:${id.locationId}`;
  }
  protected mapObject(
    obj: GeoJSON.FeatureCollection,
    identifier: MAP.ILocationIdentifier
  ): MODEL.ILocationMapModel {
    return {
      bounds: mapService.getMapBoundsFromFeatures(obj.features),
      collection: obj,
      identifier: {
        companyId: identifier.companyId,
        locationId: identifier.locationId,
      },
    };
  }

  protected async single(identifier: MAP.ILocationIdentifier) {
    const { data } = await this.api.get<GeoJSON.FeatureCollection>(
      `/client/companies/${identifier.companyId}/locations/${identifier.locationId}/map`
    );
    return {
      data,
      identifier,
    };
  }
}
export default new LocationMapModel('LocationMapModel');
