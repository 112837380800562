import {
  BS,
  Error as ErrorDisplay,
  ModelSubscription,
  TooltipPlacement,
  translator,
  useAsyncEffect,
  Actions,
  Button,
  OptionTrigger,
  BType,
  IconStyle,
  BStyle,
} from '@smart/design';
import React, { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import ChartModelDisplay from '../../../components/ChartModelDisplay';
import Loader from '../../../components/ContentLoader';
import PropertyDataModel from '../../../models/PropertyDataModel';
import { useBuilder } from '../BuilderContext';
import NoData from './NoData';
import { exportCSV } from './UiActions';
import chartService from '../../../services/chartService';
import { useComponentContext } from './ComponentContext';
import DefaultFilterForm from '../forms/DefaultFilterForm';
interface IProps {
  component: FORM.IGraphComponentForm;
}

const GraphDisplay = ({
  data,
  component,
}: {
  data: MODEL.IDataModel;
  component: FORM.IGraphComponentForm;
}) => {
  const context = useBuilder();
  const componentContext = useComponentContext();
  const [chart, setChart] = useState<MODEL.IChartModel>(null);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!chart) return;
    const actions = (chart.actions || []).map((b, i) => {
      return <Button key={i} {...b} />;
    });

    componentContext.renderActions(
      <Actions.Container noPadding={true}>
        {actions.length ? (
          <>
            {actions}
            <span> | </span>
          </>
        ) : null}

        <OptionTrigger
          actionButton={{
            icon: 'icon-filter',
            bType: BType.ICON,
            bStyle: component.overrideFilter ? BStyle.WARNING : BStyle.MUTED,
          }}
          onOpen={() => {
            componentContext.blockBlur(true);
          }}
          onClose={() => {
            componentContext.blockBlur(false);
          }}
          render={() => {
            return (
              <DefaultFilterForm
                form={component.overrideFilter}
                overrideAble={true}
                onChange={filter => {
                  component.overrideFilter = filter;
                  context.setFormComponent(component.id, component);
                }}
              />
            );
          }}
        />

        <BS.IconButton
          tooltip={translator.t('bth.exportCSV')}
          tooltipConf={{
            placement: TooltipPlacement.BOTTOM,
          }}
          onClick={() => exportCSV(component, data)}
          icon="icon-file-csv"
        />
      </Actions.Container>
    );
  }, [chart, component.overrideFilter, componentContext.renderActions]);
  useAsyncEffect(
    function*(onCancel, c) {
      setError(null);
      const chartData = yield* c(chartService.generateCharts(data, component, context));
      setChart(chartData);
    },
    [component, data]
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return useMemo(() => {
    if (!chart) return null;

    return <ChartModelDisplay chartConfig={chart.config} />;
  }, [chart]);
};
const GraphComponent = (props: IProps) => {
  const context = useBuilder();

  const { filters, companyId, reportId, form } = context;

  const { aggregation, property, overrideFilter } = props.component;

  const c = useCallback(
    data => {
      const {
        modelData: { $data },
      } = data;

      if (data.modelData.$data.type === 'aggregated') {
        return (
          <GraphDisplay
            key={props.component.id}
            data={data.modelData.$data}
            component={props.component}
          />
        );
      }
      return null;
    },
    [props.component]
  );

  return (
    <ModelSubscription
      model={PropertyDataModel}
      id={{
        property,
        companyId,
        reportId,
        aggregation,
        filter: PropertyDataModel.combineFilter(filters, overrideFilter),
      }}
      options={{
        loader: Loader,
        noSubscription: false,
      }}
      render={c}
    />
  );
};

export default GraphComponent;
