import { translator } from '@smart/design';
export const LocationTypes = [
  { id: 'region', icon: 'icon-industry', name: 'Region' },
  { id: 'building', icon: 'icon-building', name: 'Building' },
  { id: 'floor', icon: 'icon-layer-group', name: 'Floor' },
  { id: 'corridor', name: 'Corridor' },
  { id: 'stairs', name: 'Stairs' },
  { id: 'elevator', name: 'Elevator' },
  { id: 'zone', name: 'General zone', groupName: 'Zones' },
  { id: 'zone.collaboration', name: 'Collaboration zone', groupName: 'Zones' },
  { id: 'zone.social', name: 'Social zone', groupName: 'Zones' },
  { id: 'zone.exhibition', name: 'Exhibition', groupName: 'Zones' },
  { id: 'zone.reception', name: 'Reception', groupName: 'Zones' },
  { id: 'zone.canteen', name: 'Canteen', groupName: 'Zones' },
  { id: 'zone.standing', name: 'Standing zone', groupName: 'Zones' },
  { id: 'zone.hotspot', name: 'Hotspot zone', groupName: 'Zones' },
  { id: 'zone.hotdesk', name: 'Hotdesk zone', groupName: 'Zones' },
  { id: 'room', icon: 'icon-square', name: 'General room', groupName: 'Rooms' },
  { id: 'room.meeting', icon: 'icon-square', name: 'Meeting room', groupName: 'Rooms' },
  { id: 'room.quiet', icon: 'icon-square', name: 'Quiet room', groupName: 'Rooms' },
  { id: 'room.private-office', icon: 'icon-square', name: 'Private office', groupName: 'Rooms' },
  { id: 'room.locker', icon: 'icon-square', name: 'Locker room', groupName: 'Rooms' },
  { id: 'room.facility', icon: 'icon-square', name: 'Facility room', groupName: 'Rooms' },
  { id: 'room.toilet', icon: 'icon-toilet', name: 'Toilet', groupName: 'Rooms' },

  { id: 'room.auditorium', icon: 'icon-square', name: 'Auditorium', groupName: 'Rooms' },
  { id: 'room.class', icon: 'icon-square', name: 'Classroom', groupName: 'Rooms' },
  { id: 'room.toilet.unisex', icon: 'icon-toilet', name: 'Unisex toilet', groupName: 'Toilets' },
  { id: 'room.toilet.female', icon: 'icon-toilet', name: 'Female toilet', groupName: 'Toilets' },
  { id: 'room.toilet.male', icon: 'icon-toilet', name: 'Male toilet', groupName: 'Toilets' },
  { id: 'room.toilet.handicap', name: 'Handicap toilet', groupName: 'Toilets' },
  { id: 'asset.social.seat', name: 'Social seat', groupName: 'Assets' },
  { id: 'asset.collaboration.seat', name: 'Collaboration seat', groupName: 'Assets' },
  { id: 'asset.meeting.seat', name: 'Meeting room seat', groupName: 'Assets' },
  { id: 'asset.quiet.seat', name: 'Quiet room seat', groupName: 'Assets' },
  { id: 'asset.education.seat', name: 'Education seat', groupName: 'Assets' },
  { id: 'asset.canteen.seat', name: 'Canteen seat', groupName: 'Assets' },
  { id: 'asset.facility.seat', name: 'Facility seat', groupName: 'Assets' },
  { id: 'asset.hotdesk.seat', name: 'Hotdesk seat', groupName: 'Assets' },
  { id: 'asset.workstation', name: 'Workstation', groupName: 'Assets' },
  { id: 'asset.pod', name: 'Pod', groupName: 'Assets' },
];

export const ShowInMapOptions = [
  { id: 'inherit', name: 'Inherited', value: null },
  { id: 'show', name: 'Show', value: true },
  { id: 'hide', name: 'Hide', value: false },
];

export const DurationOptions = [
  { id: 60, name: '1 min' },
  { id: 120, name: '2 min' },
  { id: 180, name: '3 min' },
  { id: 240, name: '4 min' },
  { id: 300, name: '5 min' },
  { id: 360, name: '6 min' },
  { id: 420, name: '7 min' },
  { id: 480, name: '8 min' },
  { id: 540, name: '9 min' },
  { id: 600, name: '10 min' },
  { id: 660, name: '11 min' },
  { id: 720, name: '12 min' },
  { id: 780, name: '13 min' },
  { id: 840, name: '14 min' },
  { id: 900, name: '15 min' },
  { id: 1200, name: '20 min' },
  { id: 1800, name: '30 min' },
  { id: 3600, name: '60 min' },
  { id: 10800, name: '3 hours' },
  { id: 21600, name: '6 hours' },
  { id: 43200, name: '12 hours' },
  { id: 86400, name: '24 hours' },
  { id: 604800, name: '1 week' },
  { id: 2419200, name: '4 weeks' },
];

export const SupportHierarchy = [
  {
    id: 'root',
    children: ['region', 'building'],
  },
  {
    id: 'region',
    children: ['building'],
  },
  {
    id: 'building',
    children: ['floor', 'stairs', 'elevator'],
  },
  {
    id: 'floor',
    children: [
      'zone',
      'zone.collaboration',
      'zone.social',
      'zone.exhibition',
      'zone.reception',
      'zone.canteen',
      'zone.hotspot',
      'zone.standing',
      'zone.hotdesk',
      'corridor',
      'stairs',
      'elevator',
      'room',
      'room.meeting',
      'room.quiet',
      'room.private-office',
      'room.locker',
      'room.facility',
      'room.auditorium',
      'room.class',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.hotdesk.seat',
      'asset.workstation',
      'asset.pod',
    ],
  },
  {
    id: 'zone',
    children: [
      'zone',
      'zone.collaboration',
      'zone.social',
      'zone.exhibition',
      'zone.reception',
      'zone.canteen',
      'zone.hotspot',
      'zone.standing',
      'zone.hotdesk',
      'room',
      'room.meeting',
      'room.quiet',
      'room.private-office',
      'room.locker',
      'room.facility',
      'room.auditorium',
      'room.class',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.hotdesk.seat',
      'asset.workstation',
      'asset.pod',
    ],
  },
  {
    id: 'zone.collaboration',
    children: [
      'zone',
      'zone.hotspot',
      'zone.standing',
      'zone.hotdesk',
      'room',
      'room.meeting',
      'room.quiet',
      'room.private-office',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.hotdesk.seat',
      'asset.workstation',
      'asset.pod',
    ],
  },
  {
    id: 'zone.social',
    children: [
      'zone',
      'zone.hotspot',
      'zone.standing',
      'zone.hotdesk',
      'room',
      'room.meeting',
      'room.quiet',
      'room.private-office',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.hotdesk.seat',
      'asset.workstation',
      'asset.pod',
    ],
  },
  {
    id: 'zone.exhibition',
    children: [
      'zone',
      'room',
      'room.auditorium',
      'room.class',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.education.seat',
    ],
  },
  {
    id: 'zone.reception',
    children: [
      'zone',
      'room',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
    ],
  },
  {
    id: 'zone.canteen',
    children: [
      'zone',
      'room',
      'room.toilet.unisex',
      'room.toilet.female',
      'room.toilet.male',
      'room.toilet.handicap',
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.canteen.seat',
    ],
  },
  {
    id: 'zone.hotspot',
    children: ['asset.collaboration.seat'],
  },
  {
    id: 'zone.standing',
    children: [],
  },
  {
    id: 'zone.hotdesk',
    children: ['asset.hotdesk.seat'],
  },
  {
    id: 'corridor',
    children: ['asset.social.seat', 'asset.collaboration.seat', 'asset.pod'],
  },
  {
    id: 'stairs',
    children: [],
  },
  {
    id: 'elevator',
    children: [],
  },
  {
    id: 'room',
    children: [
      'asset.social.seat',
      'asset.collaboration.seat',
      'asset.hotdesk.seat',
      'asset.workstation',
      'asset.pod',
    ],
  },
  {
    id: 'room.meeting',
    children: ['asset.meeting.seat'],
  },
  {
    id: 'room.quiet',
    children: ['asset.quiet.seat'],
  },
  {
    id: 'room.private-office',
    children: [],
  },
  {
    id: 'room.locker',
    children: [],
  },
  {
    id: 'room.facility',
    children: ['asset.facility.seat'],
  },
  {
    id: 'room.auditorium',
    children: ['asset.education.seat'],
  },
  {
    id: 'room.class',
    children: ['asset.education.seat'],
  },
  {
    id: 'room.toilet.unisex',
    children: [],
  },
  {
    id: 'room.toilet.female',
    children: [],
  },
  {
    id: 'room.toilet.male',
    children: [],
  },
  {
    id: 'room.toilet.handicap',
    children: [],
  },
  {
    id: 'asset.social.seat',
    children: [],
  },
  {
    id: 'asset.collaboration.seat',
    children: [],
  },
  {
    id: 'asset.meeting.seat',
    children: [],
  },
  {
    id: 'asset.quiet.seat',
    children: [],
  },
  {
    id: 'asset.education.seat',
    children: [],
  },
  {
    id: 'asset.canteen.seat',
    children: [],
  },
  {
    id: 'asset.facility.seat',
    children: [],
  },
  {
    id: 'asset.hotdesk.seat',
    children: [],
  },
  {
    id: 'asset.workstation',
    children: [],
  },
  {
    id: 'asset.pod',
    children: [],
  },
];

export const SENSOR_PROPERTY = [
  'totalEnergy',
  'powerConsumption',
  'onOffSwitch',
  'temperature',
  'motionCount',
  'carbonDioxideLevel',
  'vocLevel',
  'humidity',
  'atmosphericPressure',
  'soundPressureLevel',
  'brightness',
  'colorTemperature',
  'footfallCountIn',
  'footfallCountOut',
  'batteryLevel',
  'peopleCount',
];

export const REPORT_ID = '5f60a4b25f15299c05b42724';
export enum ComponentType {
  TEXT = 'text',
  VIDEO = 'video',
  IMAGE = 'image',
  GRAPH = 'graph',
  MAP = 'map',
  LABEL = 'label',
  GAUGE = 'gauge',
  CLOCK = 'clock',
}

export const RefreshIntervals = () => [
  {
    label: translator.t('label.refreshInterval.none'),
    value: 'none',
  },
  {
    label: translator.t('label.refreshInterval.1min'),
    value: '1m',
  },
  {
    label: translator.t('label.refreshInterval.5min'),
    value: '5m',
  },
  {
    label: translator.t('label.refreshInterval.10min'),
    value: '10m',
  },
  {
    label: translator.t('label.refreshInterval.20min'),
    value: '20m',
  },
  {
    label: translator.t('label.refreshInterval.30min'),
    value: '30m',
  },
  {
    label: translator.t('label.refreshInterval.1h'),
    value: '1h',
  },
  {
    label: translator.t('label.refreshInterval.6h'),
    value: '6h',
  },
  {
    label: translator.t('label.refreshInterval.12h'),
    value: '12h',
  },
  {
    label: translator.t('label.refreshInterval.24h'),
    value: '24h',
  },
  {
    label: translator.t('label.refreshInterval.2d'),
    value: '2d',
  },
  {
    label: translator.t('label.refreshInterval.3d'),
    value: '3d',
  },
  {
    label: translator.t('label.refreshInterval.4d'),
    value: '4d',
  },
  {
    label: translator.t('label.refreshInterval.1w'),
    value: '1w',
  },
];
