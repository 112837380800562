import React from 'react';

import {
  Modal,
  SmartForm,
  Error,
  Center,
  Button,
  translator,
  BStyle,
  ModalSize,
} from '@smart/design';
import ReportDetailForm from '../forms/ReportDetailForm';
import Store from '../Store';
import { Observer } from 'mobx-react-lite';
import { BuilderProvider } from '../BuilderContext';
import DefaultFilterForm from '../forms/DefaultFilterForm';

interface IProps {
  companyId: string;
  reportId: string;
  title: string;
}
class DuplicateDashboard extends Modal<IProps, {}, FORM.IReportForm> {
  private store: Store;
  constructor(props) {
    super(props);
    this.state = {
      modalOptions: {
        size: ModalSize.MEDIUM,

        title: translator.t('modal.title.duplicateDashboard', {
          name: props.title,
        }),
      },
    };
    this.store = new Store(props.companyId, props.reportId);
  }
  public componentDidMount() {
    this.store.load();
  }

  public renderContent() {
    return (
      <Observer
        render={() => {
          if (!this.store.Loaded) return null;
          if (this.store.Error) {
            return <Error error={this.store.Error} />;
          }

          return (
            <BuilderProvider value={this.store.Value}>
              <ReportDetailForm autoChange={true} autoSync={false} />
              <DefaultFilterForm
                showFilterButton={false}
                form={this.store.Value.filters}
                onChange={f => {
                  this.store.updateFilter(f);
                }}
              />
              <Center>
                <Button
                  bStyle={BStyle.PRIMARY}
                  label={translator.t('btn.createDuplicate')}
                  onClick={() => this.closeModal(this.store.Form)}
                />
              </Center>
            </BuilderProvider>
          );
        }}
      ></Observer>
    );
  }
}
export default DuplicateDashboard;
