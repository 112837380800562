import { IDatasetChartOptions, ILinearScaleOptions } from 'chart.js';

import CartesianChart from './CartesianChart';
class BarChart extends CartesianChart {
  constructor(props) {
    super({
      component: props.component,
      dataModel: props.dataModel,
      type: 'bar',
    });
  }

  protected getDatasetOptions() {
    const set: DeepPartial<IDatasetChartOptions<'bar'>['bar']['datasets']> = {
      datalabels: {
        display: this.component.showDataLabels ? 'auto' : false,
        anchor: 'end',
        align: 'top',
        clamp: true,
      },
    };

    return set;
  }
  protected getYScaleOptions(): DeepPartial<ILinearScaleOptions> {
    return {
      beginAtZero: false,
      stacked: false,
      offset: true,
    };
  }
}

export default BarChart;
