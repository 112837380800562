import { IListItem, Service } from '@smart/design';
import {
  Arc,
  BarController,
  CategoryScale,
  Chart,
  Legend,
  Line,
  LinearScale,
  LineController,
  PieController,
  Point,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  Rectangle,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { IBuilderContext } from 'views/Builder/BuilderContext';
import LabelPlugin from '../plugins/data-labels/labels-plugins';
import { HierarchialPlugin, HierarchialScale } from '../plugins/hierachy-scale';
import BarChart from './charts/builders/BarChart';
import HierarchyChart from './charts/builders/HierarchyChart';
import LineChart from './charts/builders/LineChart';
import PieChart from './charts/builders/PieChart';
import GaugeController from './charts/plugins/Gauge';

Chart.register(
  TimeScale,
  PolarAreaController,
  LinearScale,
  RadarController,
  CategoryScale,
  RadialLinearScale,
  Line,
  LineController,
  Point,
  Title,
  BarController,
  Rectangle,
  Tooltip,
  Legend,
  TimeSeriesScale,
  PieController,
  Arc,
  PolarAreaController,
  GaugeController,
  LabelPlugin,
  HierarchialScale,
  HierarchialPlugin
);
class ChartService extends Service {
  public async initialize() {
    //
  }

  public async generateCharts(
    dataModel: MODEL.IDataModel,
    component: FORM.IGraphComponentForm,
    context: IBuilderContext
  ): Promise<MODEL.IChartModel> {
    let builder: CHART.IChartInterface = null;

    if (dataModel.type === 'aggregated') {
      switch (component.graphType) {
        case 'bar':
          builder = new BarChart({
            component,
            dataModel,
          });
          break;
        case 'line':
          builder = new LineChart({
            component,
            dataModel,
          });
          break;
        case 'pie':
          builder = new PieChart({
            component,
            dataModel,
          });
          break;
        case 'hierarchy':
          builder = new HierarchyChart({
            component,
            dataModel,
          });

          break;
      }
    }
    try {
      await builder.initialize({ context });
    } catch (e) {
      console.error(e);
    }

    if (builder) {
      return {
        config: builder.getConfig(),
        dataModel,
        actions: builder.getActions(),
      };
    }
  }

  public hasTimeGroup(prop) {
    return ['hour', 'quarter', 'day', 'week', 'month', 'year'].includes(prop);
  }
  public filterGroupByList(list: Array<IListItem<string>>, type: FORM.AggregationUseType) {
    return list;
  }

  public getChartTypes(): Array<IListItem<CHART.ChartType>> {
    return [
      {
        label: 'bar',
        icon: 'icon-chart-bar',
        value: 'bar',
      },
      {
        label: 'line',
        value: 'line',
        icon: 'icon-chart-line',
      },
      {
        label: 'pie',
        value: 'pie',
        icon: 'icon-chart-pie',
      },
      {
        label: 'hierarchical',
        value: 'hierarchy',
        icon: 'icon-sitemap',
      },
    ];
  }

  public getStackedChartTypes() {
    return [
      {
        label: 'bar',
        icon: 'icon-chart-bar',
        value: 'bar',
      },
      {
        label: 'line',
        value: 'line',
        icon: 'icon-chart-line',
      },
    ];
  }
  public isAggregatedByTime(model: MODEL.IDataModel) {
    if (model.type === 'aggregated') {
      return model.data.some(x => x.datetime);
    }
    return false;
  }

  public getSortOrder() {
    return [
      {
        label: 'desc',
        value: 'desc',
        icon: 'icon-arrow-down',
      },
      {
        label: 'asc',
        icon: 'icon-arrow-up',
        value: 'asc',
      },
    ];
  }

  public getColors(): Array<IListItem<string>> {
    return [
      {
        label: 'GRAY',
        value: '#c9cbcf',
      },
      {
        label: 'RED',
        value: '#ff6384',
      },
      {
        label: 'ORANGE',
        value: '#ff9f40',
      },
      {
        label: 'YELLOW',
        value: '#ffcd56',
      },
      {
        label: 'GREEN',
        value: '#4bc0c0',
      },
      {
        label: 'BLUE',
        value: '#36a2eb',
      },
      {
        label: 'PURPLE',
        value: '#9966ff',
      },
    ];
  }
  public getUsage(type: string, property: string): Array<IListItem<string>> {
    const options: Array<IListItem<'series' | 'xAxis' | 'value' | 'ignore' | string> & {
      notInTypeAndGroup: Array<{
        group: string;
        type: string;
      }>;
      notInGroup: string[];
      notInType: string[];
      types?: string[];
    }> = [
      {
        label: 'S',
        value: 'series',

        description: 'SERIES',
        types: ['bar', 'line', 'pie'],
        notInGroup: ['day', 'week', 'month', 'year', 'weekDayNumber'],
        notInType: ['label'],
        notInTypeAndGroup: [],
        style: {},
      },
      {
        label: 'X',
        value: 'xAxis',
        description: 'X-AXSIS',
        types: ['line', 'bar'],
        notInGroup: [],
        notInType: ['label'],
        notInTypeAndGroup: [],
      },
      {
        label: 'Y',
        value: 'value',
        description: 'Y-VALUE',
        types: ['line', 'bar', 'pie', 'label', 'map', 'hierarchy'],
        notInGroup: ['day', 'week', 'month', 'year', 'weekDayNumber'],
        notInType: [],
        notInTypeAndGroup: [
          {
            group: 'entityId',
            type: 'hierarchy',
          },
          {
            group: 'locationType',
            type: 'hierarchy',
          },
          {
            group: 'merge',
            type: 'label',
          },
        ],
      },
      {
        label: 'N',
        value: 'ignore',
        description: 'IGNORE',
        notInGroup: [],
        notInType: [],
        notInTypeAndGroup: [],
      },
    ]
      .filter(x => (type ? (x.types ? x.types.includes(type) : true) : true))
      .filter(x => (property ? !x.notInGroup.includes(property) : true))
      .filter(x => !x.notInTypeAndGroup.find(l => l.group === property && l.type === type));

    if (options.length === 1 && options[0].value === 'ignore') return [];

    return options;
  }
}

export default new ChartService();
