import React from 'react';
import { Static, Center } from '@smart/design/core';
import { translator } from '@smart/design';

const NotFound = () => (
  <Center full={true}>
    <Static label={translator.t('notfound.title')}>
      <p>{translator.t('notfound.description')}</p>
    </Static>
  </Center>
);

export default NotFound;
