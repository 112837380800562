import ReportModel from '../../models/ReportModel';
import { modalManager, modals, notifications, Router, translator } from '@smart/design';

import NewDashboardModal from '../Builder/modals/NewDashboardModal';
export async function openCreateDashboardModal(companyId: string) {
  const reportId = await modalManager.show<string>(NewDashboardModal, {
    companyId,
  });
  if (reportId) {
    editDashboard(companyId, reportId);
  }
}

export async function removeDashboard(companyId: string, dashboardId: string) {
  const confirm = await modals.confirm();
  if (confirm) {
    try {
      await ReportModel.delete(companyId, dashboardId);
      notifications.success();
    } catch (e) {
      modals.error(e);
    }
  }
}
export function goBuilder(companyId: string) {
  Router.BrowserHistory.push(`/companies/${companyId}/dashboards/builder`);
}
export function editDashboard(companyId: string, dashboardId: string) {
  if (parent !== self) {
    parent.postMessage(
      {
        action: 'editDashboard',
        value: dashboardId,
      },
      '*'
    );
  } else {
    Router.BrowserHistory.push(`/companies/${companyId}/dashboards/${dashboardId}/builder`);
  }
}
export function viewDashboard(companyId: string, dashboardId: string) {
  if (parent !== self) {
    parent.postMessage(
      {
        action: 'viewDashboard',
        value: dashboardId,
      },
      '*'
    );
  } else {
    Router.BrowserHistory.push(`/companies/${companyId}/dashboards/${dashboardId}/view`);
  }
}
export async function setAccess(
  companyId: string,
  dashboardId: string,
  currentList: string[],
  id: string
) {
  let list = currentList;
  const cool = currentList.includes(id);

  if (cool) {
    list = list.filter(y => y !== id);
  } else {
    list.push(id);
  }
  try {
    await ReportModel.setAccess(companyId, dashboardId, list);
    notifications.success();
  } catch (e) {
    modals.error(e);
    throw e;
  }
}
export async function setAccessAll(companyId: string, dashboardId: string, value: boolean) {
  try {
    await ReportModel.setAccessAll(companyId, dashboardId, value);
    notifications.success();
  } catch (e) {
    modals.error(e);
    throw e;
  }
}
export async function copyDashboardLink(companyId: string, dashboardId: string) {
  const element = document.createElement('textarea');
  element.style.position = 'fixed';
  element.style.left = '-500px';
  document.body.appendChild(element);
  element.value = `${location.origin}/companies/${companyId}/dashboards/${dashboardId}/view`;
  element.select();
  document.execCommand('copy');
  notifications.info(translator.t('dashboard.copy.viewLink'));
  document.body.removeChild(element);
}
