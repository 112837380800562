import {
  SmartForm,
  translator,
  Tabs,
  Seperator,
  helper,
  useForceUpdate,
  Static,
  Row,
  Col,
} from '@smart/design';
import React, { useCallback, useMemo, useState, useEffect, useRef, cloneElement } from 'react';
import chartService from '../../../services/chartService';
import DefaultFilterForm from './DefaultFilterForm';
import { useBuilder } from '../BuilderContext';
import PropertyTypeModel from '../../../models/PropertyTypeModel';

import AggregationForm from './aggregation/AggregationForm';
import { aggregationArrayToObject, aggregationObjectToArray, isValidAggregation } from './common';

interface IProps {
  form: Partial<FORM.IGraphComponentForm>;
  onChange: (component: FORM.IGraphComponentForm) => void;
}

const GraphForm = (props: IProps) => {
  const { form, onChange } = props;
  const { companyId, setFormComponent } = useBuilder();
  const [a1, a2] = aggregationObjectToArray(form.aggregation, {
    agg1: {
      aggregate: [],
      by: [],
      sort: null,
    },
  });

  const aggregateForm = useRef<FORM.IIPipelineAggregation[]>(a1);
  const aggregateUsages = useRef<FORM.IAggregationUsage[]>(a2);

  const generalForm = useRef<Partial<FORM.IGraphComponentForm>>(props.form);
  const filterForm = useRef<API.IDefaultFilter>(form.overrideFilter);
  const update = useForceUpdate();
  const applyChanges = useCallback(() => {
    update();
    if (!generalForm.current?.property) return;
    const changes: FORM.IGraphComponentForm = {
      property: generalForm.current.property,
      type: form.type,
      id: form.id,
      graphTitle: generalForm.current.graphTitle,
      yLabel: generalForm.current.yLabel,
      xLabel: generalForm.current.xLabel,
      graphType: generalForm.current.graphType,
      legendLabel: generalForm.current.legendLabel,
      overrideFilter: filterForm.current,
      showDataLabels: generalForm.current.showDataLabels,
      showNullValuedLabels: generalForm.current.showNullValuedLabels,
      attributes: form.attributes,
    };

    changes.aggregation = aggregationArrayToObject(aggregateForm.current, aggregateUsages.current);
    if (isValidAggregation(changes.aggregation)) {
      setFormComponent(form.id, changes);
    }
  }, [aggregateForm.current, filterForm.current, generalForm.current, form, setFormComponent]);

  const details = useMemo(() => {
    if (!generalForm.current.property) {
      return null;
    }

    return (
      <Tabs.Container noPadding={true}>
        <Tabs.Tab tabId="aggregates" label={translator.t('label.aggregates')} noPadding={true}>
          <AggregationForm
            aggregation={aggregateForm.current}
            usages={aggregateUsages.current}
            type={generalForm.current.graphType}
            property={generalForm.current.property}
            onApply={(agg, usages) => {
              aggregateForm.current = agg;
              aggregateUsages.current = usages;
              applyChanges();
            }}
          />
        </Tabs.Tab>
        <Tabs.Tab tabId="filter" label={translator.t('label.filter')} noPadding={true}>
          <div>
            <Seperator />
            <DefaultFilterForm
              overrideAble={true}
              form={filterForm.current}
              onChange={f => {
                filterForm.current = f;
                applyChanges();
              }}
            />
          </div>
        </Tabs.Tab>
      </Tabs.Container>
    );
  }, [generalForm.current]);

  return (
    <>
      <SmartForm.Form<FORM.IGraphComponentForm>
        onChange={async f => {
          generalForm.current = {
            ...generalForm.current,
            ...f,
          };
          applyChanges();
        }}
        noSubmit={true}
        form={[
          {
            property: 'graphTitle',
            type: 'input',
            label: translator.t('label.title'),
            value: generalForm.current.graphTitle,
          },
          {
            property: 'property',
            type: 'searchSelect',
            value: generalForm.current?.property,
            required: () => true,
            fetch: async q => {
              const d = await PropertyTypeModel.load(companyId);
              return helper.searchArray(d, 'value', q);
            },
            preload: async () => {
              return PropertyTypeModel.load(companyId);
            },
            component: (value, item) => {
              return <div>{item?.label || value}</div>;
            },
            label: translator.t('label.property'),
          },
          {
            property: 'graphType',
            type: 'boxSelect',
            label: translator.t('label.graphType'),
            required: () => true,
            value: generalForm.current.graphType,
            visible: f => (f.property ? true : false),
            options: chartService.getChartTypes(),
          },
          {
            property: 'xLabel',
            label: translator.t('label.xLabel'),
            visible: f => ['bar', 'line', 'hierarchy'].includes(f.graphType),
            type: 'input',
            value: generalForm.current.xLabel,
          },
          {
            property: 'yLabel',
            label: translator.t('label.yLabel'),
            visible: f => ['bar', 'line', 'hierarchy'].includes(f.graphType),
            value: generalForm.current.yLabel,

            type: 'input',
          },
          {
            property: 'legendLabel',
            label: translator.t('label.legendLabel'),
            value: generalForm.current.legendLabel,

            type: 'input',
          },
          {
            property: 'showDataLabels',
            value: generalForm.current.showDataLabels,
            label: translator.t('label.showDataLabels'),

            type: 'checkbox',
          },
          {
            property: 'showDataLabels',
            value: generalForm.current.showDataLabels,
            label: translator.t('label.showDataLabels'),

            type: 'checkbox',
          },
          {
            property: 'showNullValuedLabels',
            value: generalForm.current.showNullValuedLabels,
            label: translator.t('label.showNullValuedLabels'),
            visible: f => f.graphType === 'hierarchy',
            type: 'checkbox',
          },
        ]}
      >
        <SmartForm.Field property="graphTitle" />
        <SmartForm.Field property="property" />
        <SmartForm.Field property="graphType" />
        <SmartForm.Field property="xLabel" />
        <SmartForm.Field property="yLabel" />
        <SmartForm.Field property="legendLabel" />
        <Row>
          <Col xs={6}>
            <SmartForm.Field property="showDataLabels" inlineLabel={true} />
          </Col>
          <Col xs={6}>
            <SmartForm.Field property="showNullValuedLabels" inlineLabel={true} />
          </Col>
        </Row>

        <Seperator underline={true} />
      </SmartForm.Form>
      <div>{details}</div>
    </>
  );
};

export default GraphForm;
