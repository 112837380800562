import React, { useState, useEffect, useCallback } from 'react';
import {
  SmartForm,
  Seperator,
  Actions,
  BS,
  translator,
  IListItem,
  helper,
  formManager,
  styled,
  Button,
  useThemeContext,
  ModelSubscription,
  BStyle,
} from '@smart/design';
import chartService from '../../../../services/chartService';
import AggregationPropertyModel from '../../../../models/AggregationPropertyModel';
import { useBuilder } from '../../BuilderContext';
import { useDidUpdate } from '@smart/design/src/scopes/common/hooks';
interface IChangedType {
  from: string;
  to: string;
  index: number;
  type: 'modify' | 'new';
}
interface IProps {
  onRemove: () => void;
  type: FORM.AggregationUseType;

  aggregation: FORM.IIPipelineAggregation;
  aggregations: FORM.IIPipelineAggregation[];
  previousAggregation: FORM.IIPipelineAggregation;
  nextAggregation: FORM.IIPipelineAggregation;
  onChange: (f: FORM.IIPipelineAggregation, operationChanges: IChangedType[]) => void;
  index: number;
  useSort?: boolean;
  newAggregate: () => void;
}
const Property = styled.div`
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.07);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-left-color: ${({ theme }) => theme.colors.primary};
  border-left-style: solid;
  border-left-width: 5px;
  position: relative;
`;
const AggregateForm = (props: IProps) => {
  const { companyId, reportId } = useBuilder();
  const [aggregation, setAggregation] = useState<FORM.IIPipelineAggregation>(props.aggregation);
  const formRef = SmartForm.useFormRef<FORM.IIPipelineAggregation>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const theme = useThemeContext();

  const loadGroups = useCallback(async () => {
    const model = await AggregationPropertyModel.load({ companyId, reportId });

    const prevBy = props.previousAggregation ? props.previousAggregation.by : [];

    const currentBy = aggregation.by;
    if (prevBy.length) {
      const prevAggregates = props.previousAggregation ? props.previousAggregation.aggregate : [];

      return AggregationPropertyModel.toPropertyList([
        ...AggregationPropertyModel.getReplaceableBy(model, prevBy, currentBy),
        ...prevAggregates.map(x => x.as),
      ]);
    }

    return AggregationPropertyModel.toGroupList(
      AggregationPropertyModel.getCombinableBy(model, aggregation.by)
    );
  }, [props.previousAggregation, props.aggregation, aggregation, props.type]);

  const loadProperties = useCallback(async () => {
    const model = await AggregationPropertyModel.load({ companyId, reportId });
    const prevAggregates = props.previousAggregation ? props.previousAggregation.aggregate : [];
    if (prevAggregates.length) {
      return AggregationPropertyModel.toPropertyList(prevAggregates.map(x => x.as));
    }

    return AggregationPropertyModel.toPropertyList(model.initialProperties.map(x => x.name));
  }, [props.previousAggregation, props.aggregation, aggregation]);
  const loadOperations = useCallback(async () => {
    const model = await AggregationPropertyModel.load({ companyId, reportId });

    return AggregationPropertyModel.toOperationList(model.operations.map(x => x.name));
  }, []);
  useDidUpdate(() => {
    formRef.current.refetch('by');
    formRef.current.refetch('aggregate');
    formRef.current.refetch('sorts');
  }, [aggregation, formRef.current, props.type]);

  return (
    <Property>
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 25px)',
          left: -18,
          width: 18,
          height: 50,
          color: '#fff',
          backgroundColor: theme.colors.primary,
          borderRadius: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {props.index + 1}
      </div>
      <SmartForm.Form<FORM.IIPipelineAggregation>
        formRef={formRef}
        onChange={(f, state, wascChanged) => {
          setAggregation({ ...f });

          if (state.isValid) {
            props.onChange(f, null);
          }
        }}
        isValid={b => {
          setIsValid(b);
        }}
        externalSubmit={true}
        noSubmit={true}
        form={[
          SmartForm.createFormField({
            property: 'by',
            type: 'searchSelect',
            label: translator.t('label.by'),
            required: () => true,
            value: props.aggregation.by,
            multiple: true,
            preload: loadGroups,
            removeAble: v => {
              if (props.nextAggregation && props.nextAggregation.by.includes(v)) {
                return false;
              }

              return true;
            },
            fetch: async s => {
              const t = await loadGroups();
              return helper.searchArray(t, 'label', s);
            },
            component: d => {
              return <span>{d}</span>;
            },
          }),
          SmartForm.createFormField({
            property: 'aggregate',
            label: translator.t('label.aggregate'),
            value: props.aggregation.aggregate,

            type: 'spreadsheet',
            validations: {
              sheet: val => {
                if (!val.length || val.some(x => !x.as || !x.operation || !x.property)) {
                  return translator.t('error.notValidAggregate');
                }
                return null;
              },
            },
            required: () => true,
            onNewRow: (rowIndex: number) => {
              return {
                operation: null,
                property: null,
                as: null,
              };
            },
            validateRow: p => {
              return p.property && p.as && p.operation && true;
            },

            columns: [
              {
                label: translator.t('label.operation'),
                form: {
                  property: 'operation',
                  type: 'searchSelect',
                  width: 250,
                  required: () => true,
                  fetch: async q => helper.searchArray(await loadOperations(), 'label', q),
                  preload: () => loadOperations(),

                  component: v => <span>{v}</span>,
                },
              },
              {
                label: translator.t('label.property'),

                form: {
                  property: 'property',
                  width: 250,

                  type: 'searchSelect',
                  required: () => true,
                  fetch: async q => {
                    const t = await loadProperties();

                    return helper.searchArray(t, 'label', q);
                  },
                  preload: loadProperties,
                  component: v => <span>{v}</span>,
                },
              },
              {
                label: translator.t('label.label'),

                form: {
                  required: () => true,
                  property: 'as',
                  type: 'input',
                },
              },
            ],
          }),
          SmartForm.createFormField({
            property: 'sorts',
            label: translator.t('label.sort'),
            type: 'spreadsheet',
            visible: () => props.useSort,
            value: props.aggregation.sorts,
            required: () => false,
            disabled: x => (props.nextAggregation ? true : false),
            validateRow: row => row.property && row.type && true,
            canAddRow: () => (props.nextAggregation ? false : true),
            beforeRowChange: (changeRow, currentRow) => {
              if (!changeRow?.property) {
                changeRow.type = null;
              }

              return changeRow;
            },
            columns: [
              {
                label: translator.t('label.property'),

                form: {
                  property: 'property',
                  type: 'searchSelect',
                  allowNull: true,
                  disabled: x => (props.nextAggregation ? true : false),

                  fetch: async q => {
                    return helper.searchArray(
                      [...aggregation.by, ...aggregation.aggregate.map(x => x.as)].map(x => ({
                        label: x,
                        value: x,
                      })),
                      'label',
                      q
                    );
                  },
                  preload: async () => {
                    return [...aggregation.by, ...aggregation.aggregate.map(x => x.as)].map(x => ({
                      label: x,
                      value: x,
                    }));
                  },
                  component: f => <span>{f}</span>,
                },
              },
              {
                label: translator.t('label.order'),
                form: {
                  disabled: x => (props.nextAggregation ? true : false),

                  property: 'type',
                  allowNull: true,

                  type: 'searchSelect',
                  preload: async () => chartService.getSortOrder(),
                  fetch: async q => {
                    return helper.searchArray(chartService.getSortOrder(), 'label', q);
                  },
                  component: f => <span>{f}</span>,
                },
              },
            ],
          }),
        ]}
      >
        <SmartForm.Field property="by" />
        <SmartForm.Field property="aggregate" />
        <SmartForm.Field property="sorts" />

        <Actions.Container>
          <Actions.Left>
            <BS.RemoveButton
              disabled={!props.previousAggregation || props.nextAggregation ? true : false}
              onClick={() => {
                props.onRemove();
              }}
            />
          </Actions.Left>
          <Actions.Center>
            <Button
              disabled={!props.newAggregate ? !isValid : !isValid}
              onClick={() => {
                props.newAggregate();
              }}
              icon={'icon-arrow-down'}
              bStyle={BStyle.PRIMARY}
              label={translator.t('btn.addAggregation')}
            />
          </Actions.Center>
        </Actions.Container>
      </SmartForm.Form>
    </Property>
  );
};

export default AggregateForm;
