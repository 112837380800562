import React from 'react';
import { Button, ModelSubscription, IButtonProps, Router } from '@smart/design';
import ProfileModel from '../models/ProfileModel';
export enum Actions {
  CreateDashboard = 'CreateDashboard',
  DeleteDashboard = 'DeleteDashboard',
  ViewDashboard = 'ViewDashboard',
  EditDashboard = 'EditDashboard',

  EditAccess = 'EditAccess',
}
const ComplianceButton = (
  props: IButtonProps & {
    action: keyof typeof Actions;
  }
) => {
  const { companyId } = Router.useParams<ROUTER.IReportRouterParams>();

  return (
    <ModelSubscription
      model={ProfileModel}
      id={companyId}
      render={m => {
        let show = false;
        switch (props.action) {
          case Actions.EditAccess:
            show = m.modelData.$data.isSudo;
            break;
          case Actions.CreateDashboard:
            show = m.modelData.$data.create;
            break;
          case Actions.DeleteDashboard:
            show = m.modelData.$data.delete;
            break;
          case Actions.ViewDashboard:
            show = m.modelData.$data.view;
            break;
        }
        if (show) return <Button {...props} />;
        return null;
      }}
    />
  );
};

export default ComplianceButton;
