
import { Model, IApi, Api } from '@smart/design';

import mapService from '../services/mapService';


interface IConfig {
  apiURL: string;
}


class FloorMapModel extends Model<MODEL.ILocationMapModel, IConfig, MODEL.ILocationIdentifier> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }
 
  public idMapper(id:MODEL.ILocationIdentifier){
    return `floormap|${id.companyId}:${id.locationId}`
  }
  

  protected mapObject(obj:GeoJSON.FeatureCollection,identifier:MODEL.ILocationIdentifier):  MODEL.ILocationMapModel {
    return {
      bounds:mapService.getMapBoundsFromFeatures(obj.features),
      collection:obj,
      identifier
    }
  }

  

  protected async single(identifier:MODEL.ILocationIdentifier) {
    const {companyId,locationId} = identifier
    const {data} = await this.api.get(`client/companies/${companyId}/locations/${locationId}/map`)

    return {  
      data,
      identifier,
    };
  }

  
  
}
export default new FloorMapModel('FloorMapModel');
