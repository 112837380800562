import React, { Ref, RefObject } from 'react';
import {
  Modal,
  ModalSize,
  translator,
  SmartForm,
  Button,
  BStyle,
  DataTable,
  IFormRef,
  Row,
  Col,
} from '@smart/design';
import ReportAccessModel from '../../models/ReportAccessModel';
import ProfileModel from '../../models/ProfileModel';
import { setAccess, setAccessAll } from './UIActions';
class AccessModal extends Modal<
  { companyId: string; reportId: string },
  {
    access: API.IAccess;
  }
> {
  private formRef = React.createRef<IFormRef<{ accessAllCompany: boolean }>>();
  constructor(props) {
    super(props);
    this.state = {
      modalOptions: {
        size: ModalSize.MEDIUM,
        title: translator.t('modal.access'),
      },
      access: null,
    };
  }

  public async load() {
    const access = await ReportAccessModel.load(
      {
        companyId: this.props.companyId,
        reportId: this.props.reportId,
      },
      true
    );

    this.setState(
      {
        access,
      },
      () => {
        this.formRef.current &&
          this.formRef.current.getValues().accessAllCompany !== access.allCompanies &&
          this.formRef.current.setValue('accessAllCompany', access.allCompanies);
      }
    );
  }

  public async componentDidMount() {
    this.load();
  }

  public renderContent() {
    return (
      <div>
        <SmartForm.Form<{ accessAllCompany: boolean }>
          formRef={this.formRef}
          noSubmit={true}
          onChange={async f => {
            if (this.state.access.allCompanies !== f.accessAllCompany) {
              await setAccessAll(this.props.companyId, this.props.reportId, f.accessAllCompany);
              this.load();
            }
          }}
          form={[
            {
              property: 'accessAllCompany',
              type: 'switch',
              label: null,

              options: [
                {
                  label: translator.t('label.accessToSelectedCompanies'),
                  value: false,
                },
                {
                  label: translator.t('label.accessToAllCompanies'),
                  value: true,
                },
              ],
              value: this.state.access?.allCompanies,
            },
          ]}
        >
          <Row>
            <Col xs={6}>
              <div>{translator.t('label.availableForAll')}</div>
            </Col>

            <Col xs={6}>
              <SmartForm.Field property="accessAllCompany" />
            </Col>
          </Row>
        </SmartForm.Form>
        <DataTable<{
          id: string;
          name: string;
        }>
          renderKey="id"
          fetch={async q => {
            const d = await ProfileModel.searchCompanies(q.search);
            return d;
          }}
          useLocation={false}
          columns={[
            {
              property: 'name',
              content: x => x.name,
              label: translator.t('label.name'),
            },
            {
              property: 'actions',
              style: {
                width: 150,
              },
              label: translator.t('label.access'),

              content: x => {
                if (this.state.access) {
                  const cool = this.state.access?.companyIds?.includes(x.id);
                  const label = cool
                    ? translator.t('btn.removeAccess')
                    : translator.t('btn.addAccess');
                  const icon = cool ? 'icon-times' : 'icon-key';
                  return (
                    <Button
                      icon={icon}
                      label={label}
                      onClick={async () => {
                        await setAccess(
                          this.props.companyId,
                          this.props.reportId,
                          this.state.access.companyIds,
                          x.id
                        );

                        this.load();
                      }}
                      bStyle={cool ? BStyle.DANGER : BStyle.SUCCESS}
                    ></Button>
                  );
                }

                return null;
              },
            },
          ]}
        ></DataTable>
      </div>
    );
  }
}
export default AccessModal;
