import React from 'react';
import { SmartForm as SF, helper, translator } from '@smart/design';
import { isString } from 'lodash';
interface IProps {
  form: Partial<FORM.IImageComponentForm>;
  onChange: (component: FORM.IImageComponentForm) => void;
}
const ImageForm = (props: IProps) => {
  const t = props.form.url || props.form.base64;
  return (
    <SF.Form<{
      image: {
        url: string;
        width: number;
        height: number;
        name: string;
      };
    }>
      form={[
        {
          label: translator.t('label.image'),
          type: 'upload',
          property: 'image',
          modes: ['file', 'url'],
          multiple: false,
          value: props.form?.url && {
            url: t,
            width: props.form.width,
            height: props.form.height,
            alt: props.form.alt,
          },
          component: o => {
            if (isString(o)) {
              return <img src={o}></img>;
            }
            return <img src={o.url}></img>;
          },
          onUpload: async files => {
            const file = await helper.getImageSizeFromFile(files[0]);
            const base64 = await helper.getBase64FromFile(files[0]);
            return {
              url: base64,
              width: file?.width,
              height: file?.height,
              name: file?.name,
            };
          },
        },
      ]}
      noSubmit={true}
      onChange={f => {
        if (isString(f.image)) {
          props.onChange({
            id: props.form.id,
            type: props.form.type,
            url: f.image,
            width: null,
            height: null,
            alt: null,
          });
        } else {
          props.onChange({
            id: props.form.id,
            type: props.form.type,
            base64: f.image.url,
            height: f.image.height,
            width: f.image.width,
            alt: f.image.name,
          });
        }
      }}
    >
      <SF.Field property="image" />
    </SF.Form>
  );
};

export default ImageForm;
