import React, { useState, useMemo, useEffect } from 'react';
import { styled, Center } from '@smart/design';
import { useBuilder } from '../BuilderContext';
import tz, { Moment } from 'moment-timezone';
interface IProps {
  component: FORM.IClockComponentForm;
}

const Clock = styled.div`
  font-size: 36px;
  color: inherit;
`;
const Date = styled.div`
  font-size: 12px;
  color: inherit;
`;

const ClockDisplay = (props: IProps) => {
  const { form } = useBuilder();
  const [clock, setClock] = useState<Moment>(tz.tz(form.timezone));
  useEffect(() => {
    const it = setInterval(() => {
      setClock(tz.tz(form.timezone));
    }, form.refreshInterval || 1000);
    return () => {
      clearInterval(it);
    };
  }, []);

  return useMemo(
    () => (
      <Center
        full
        style={{
          backgroundColor: props.component.backgroundColor,
          color: props.component.textColor,
        }}
      >
        <Clock>
          {props.component.hour === '24' ? clock.format('HH:mm:ss') : clock.format('hh:mm:ss a')}
          <em style={{ fontSize: 10 }}>{clock.zoneName()}</em>
        </Clock>
        <Date>{clock.format('dddd DD.MMMM.YYYY')}</Date>
      </Center>
    ),
    [clock, props.component]
  );
};

export default ClockDisplay;
