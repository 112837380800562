import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
  SmartForm as SF,
  translator,
  Row,
  Col,
  IFormRef,
  Center,
  moment,
  styled,
  helper,
  useThemeContext,
  BType,
  OptionTrigger,
  BStyle,
  IconStyle,
  BSize,
  IListItem,
  List as ListDisplay,
  Static,
  Alert,
  Icon,
  Seperator,
} from '@smart/design';
import { useBuilder } from '../BuilderContext';
import useLocationAndTypes from '../../../hooks/useLocationAndTypes';
import useLocationTag from '../../../hooks/useLocationTag';
import useSensorTag from '../../../hooks/useSensorTag';
import useCustomTag from '../../../hooks/useCustomTag';
import { omitBy, isUndefined, isString, difference } from 'lodash';
import ReportModel from '../../../models/ReportModel';
type IForm = Partial<API.IDefaultFilter> & {
  override?: boolean;
  overrideDateTime?: boolean;
  overrideLocations?: boolean;
  overrideTypes?: boolean;
  overrideSensorTags?: boolean;
  overrideCustomTags?: boolean;
  overrideLocationTags?: boolean;
  overrideWeekends?: boolean;

  useTime?: boolean;
  useInterval?: boolean;
  actions?: boolean;
};
interface IProps {
  form: API.IDefaultFilter;
  overrideAble?: boolean;
  formId?: string;
  stickyBottom?: number;
  showFilterButton?: boolean;
  onChange: (form: API.IDefaultFilter) => void;
}

const StaticT = styled(Static)`
  padding: 5px;
`;
const Overrides = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > .form-field {
    padding: 2px;
  }
`;

export default function DefaultFilterForm(props: IProps) {
  const { showFilterButton = true } = props;
  const { companyId, filters, viewOnly } = useBuilder();
  const [locations, types] = useLocationAndTypes(companyId);
  const theme = useThemeContext();
  const sensorTags = useSensorTag(companyId);
  const customTags = useCustomTag(companyId);
  const locationTags = useLocationTag(companyId);

  const [currentForm, setCurrentForm] = useState<IForm>(props.form || {});

  const formRef = useRef<IFormRef<IForm>>();

  const selectedColor = props.overrideAble && {
    backgroundColor: theme.colors.warning,
    color: '#fff',
  };
  const convertToApiFilter = useCallback((f: IForm): API.IDefaultFilter => {
    if (!f.useTime) {
      f.endTime = undefined;
      f.startTime = undefined;
    }
    if (f.dateRange) {
      f.startDate = undefined;
      f.endDate = undefined;
    }
    if (f.startDate || f.endDate) {
      f.dateRange = undefined;
    }
    if (props.overrideAble) {
      if (!f.overrideDateTime) {
        f.endDate = undefined;
        f.startDate = undefined;
        f.endTime = undefined;
        f.startTime = undefined;
        f.dateRange = undefined;
      }
      if (!f.overrideWeekends) {
        f.includeHolidays = undefined;
        f.includeWeekends = undefined;
      }
      if (!f.overrideLocations) {
        f.locations = undefined;
      }
      if (!f.overrideTypes) {
        f.types = undefined;
      }
      if (!f.overrideLocationTags) {
        f.locationtags = undefined;
      }
      if (!f.overrideSensorTags) {
        f.sensortags = undefined;
      }
      if (!f.overrideCustomTags) {
        f.customTags = undefined;
      }
    }

    return omitBy(ReportModel.extractFilter(f), isUndefined) as API.IDefaultFilter;
  }, []);

  return (
    <SF.Form<IForm>
      form={[
        {
          property: 'overrideDateTime',
          type: 'switch',

          value:
            (props.form?.startDate && props.form?.endDate) || props.form?.dateRange ? true : false,

          visible: f => props.overrideAble,
          label: null,
          options: [
            {
              label: translator.t('report.override.on.overrideDateTime'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideDateTime'),
              value: false,
            },
          ],
        },

        {
          property: 'overrideTypes',
          type: 'switch',

          value: props.form?.types && props.form.types.length ? true : false,
          visible: f => props.overrideAble,
          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideTypes'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideTypes'),
              value: false,
            },
          ],
        },
        {
          property: 'overrideWeekends',
          type: 'switch',

          value: props.form?.includeHolidays || props.form?.includeHolidays ? true : false,
          visible: f => props.overrideAble,
          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideWeekends'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideWeekends'),
              value: false,
            },
          ],
        },
        {
          property: 'overrideLocations',
          type: 'switch',

          value: props.form?.locations && props.form.locations.length ? true : false,
          visible: f => props.overrideAble,

          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideLocations'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideLocations'),
              value: false,
            },
          ],
        },
        {
          property: 'overrideLocationTags',
          type: 'switch',

          value: props.form?.locationtags && props.form.locationtags.length ? true : false,

          visible: f => props.overrideAble,

          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideLocationTags'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideLocationTags'),
              value: false,
            },
          ],
        },
        {
          property: 'overrideSensorTags',
          type: 'switch',

          value: props.form?.sensortags && props.form.sensortags.length ? true : false,

          visible: f => props.overrideAble,
          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideSensorTags'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideSensorTags'),
              value: false,
            },
          ],
        },
        {
          property: 'overrideCustomTags',
          type: 'switch',

          value: props.form?.customTags && props.form.customTags.length ? true : false,

          visible: f => props.overrideAble,
          label: null,

          options: [
            {
              label: translator.t('report.override.on.overrideCustomTags'),
              value: true,
            },
            {
              label: translator.t('report.override.off.overrideCustomTags'),
              value: false,
            },
          ],
        },

        {
          property: 'useTime',
          type: 'checkbox',
          visible: f =>
            (props.overrideAble ? f.overrideDateTime : true) &&
            (f.dateRange ? !['min', 'Hour'].some(c => f.dateRange.includes(c)) : true),

          value: props.form && !props.form.endTime ? false : true,
          label: translator.t('report.useTime'),
        },
        SF.createFormField({
          property: 'dateRange',
          options: ReportModel.getDateRanges(),
          label: translator.t('label.chooseDateRange'),
          type: 'select',
          value: helper.get(props.form, 'dateRange'),
          visible: f =>
            props.overrideAble ? (f.overrideDateTime && f.dateRange ? true : false) : !!f.dateRange,
          actions: (f, h) => {
            return [
              {
                label: translator.t('btn.selectDate'),
                bType: BType.LINK,
                bStyle: BStyle.PRIMARY,
                onClick: () => h.setValue('dateRange', undefined),
              },
            ];
          },
        }),

        SF.createFormField({
          property: 'endDate',
          type: 'date',
          value: helper.get(props.form, 'endDate'),
          useTime: false,
          visible: f => !f.dateRange && (props.overrideAble ? f.overrideDateTime : true),

          width: 180,
          actions: (f, h) => {
            return [
              {
                label: translator.t('btn.selectDateRange'),
                bType: BType.LINK,
                bStyle: BStyle.PRIMARY,

                onClick: () => h.setValue('dateRange', 'thisMonth'),
              },
            ];
          },
          validations: {
            v: (s, f) => {
              if (moment(s).isBefore(moment(f.startDate))) {
                return translator.t('error.endDateIsBeforeStartDate');
              }
            },
          },
          label: <span></span>,
        }),

        SF.createFormField({
          property: 'startDate',
          type: 'date',
          width: 180,
          value: helper.get(props.form, 'startDate'),
          useTime: false,
          visible: f => !f.dateRange && (props.overrideAble ? f.overrideDateTime : true),

          label: translator.t('label.date'),
          validations: {
            v: (s, f) => {
              if (moment(s).isAfter(moment(f.endDate))) {
                return translator.t('error.startDateIsAfterEndDate');
              }
            },
          },
        }),
        SF.createFormField({
          property: 'startTime',
          type: 'time',
          visible: f =>
            (props.overrideAble ? f.overrideDateTime : true) &&
            (f.dateRange ? !['min', 'Hour'].some(c => f.dateRange.includes(c)) : true),

          value: helper.get(props.form, 'startTime', '08:00:00'),
          disabled: v => !v.useTime,
          fixedMinute: '00',
          validations: {
            v: (s, f) => {
              if (moment(s, 'HH:mm:ss').isAfter(moment(f.endTime, 'HH:mm:ss'))) {
                return translator.t('error.startTimeIsAfterEndTime');
              }
              return null;
            },
          },

          label: translator.t('label.time'),
        }),
        SF.createFormField({
          property: 'endTime',
          type: 'time',
          visible: f =>
            (props.overrideAble ? f.overrideDateTime : true) &&
            (f.dateRange ? !['min', 'Hour'].some(c => f.dateRange.includes(c)) : true),

          fixedMinute: '00',
          value: helper.get(props.form, 'endTime', '16:00:00'),
          disabled: v => !v.useTime,
          validations: {
            v: (s, f) => {
              if (moment(s, 'HH:mm:ss').isAfter(moment(f.endTime, 'HH:mm:ss'))) {
                return translator.t('error.endTimeIsBeforeStartTime');
              }
              return null;
            },
          },

          actions: (f, h) => {
            if (!f.useTime) {
              return [
                {
                  label: translator.t('report.label.useTime'),
                  onClick: () => h.setValue('useTime', true),
                  bStyle: BStyle.PRIMARY,
                  bType: BType.LINK,
                },
              ];
            } else {
              return [
                {
                  label: translator.t('report.label.dontUseTime'),
                  onClick: () => h.setValue('useTime', false),
                  bStyle: BStyle.PRIMARY,
                  bType: BType.LINK,
                },
              ];
            }
          },
          label: '',
        }),
        {
          property: 'includeHolidays',
          type: 'checkbox',
          value: helper.get(props.form, 'includeHolidays'),
          visible: f => (props.overrideAble ? f.overrideWeekends : true),
          label: translator.t('report.includeHolidays'),
        },
        {
          property: 'includeWeekends',
          type: 'checkbox',
          visible: f => (props.overrideAble ? f.overrideWeekends : true),
          value: helper.get(props.form, 'includeWeekends'),
          label: translator.t('report.includeWeekends'),
        },
        {
          property: 'locations',
          type: 'tree',
          value: helper.get(props.form, 'locations', helper.get(filters, 'locations', [])),
          tree: props.overrideAble
            ? helper.treeHelper.selectLockAndExpand(locations, helper.get(filters, 'locations', []))
            : locations,
          selectedStyle: selectedColor,
          expandAll: true,
          label: translator.t('report.locations'),
          visible: f => (props.overrideAble ? f.overrideLocations : true),
          // beforeChange: (f: string[]) => {
          //   return difference(f, helper.get(filters, 'locations', []));
          // },
        },
        {
          property: 'types',
          type: 'tree',
          tree: props.overrideAble
            ? helper.treeHelper.selectLockAndExpand(types, helper.get(filters, 'types', []))
            : types,
          selectedStyle: selectedColor,
          value: helper.get(props.form, 'types', helper.get(filters, 'types', [])),
          // value: [...new Set([...helper.get(props.form, 'types', []), ...helper.get(filters, 'types', [])])], // merge lists
          // value: helper.get(props.form, 'types', []).concat(helper.get(filters, 'types', [])),
          label: translator.t('report.types'),
          visible: f => (props.overrideAble ? f.overrideTypes : true),
          // beforeChange: (f: string[]) => {
          //   return difference(f, helper.get(filters, 'types', []));
          // },
        },
        {
          property: 'locationtags',
          type: 'tree',
          tree: props.overrideAble
            ? helper.treeHelper.selectLockAndExpand(
                locationTags,
                helper.get(filters, 'locationtags', [])
              )
            : locationTags,
          selectedStyle: selectedColor,
          expandAll: false,
          value: helper.get(props.form, 'locationtags', helper.get(filters, 'locationtags', [])),
          // value: [...new Set([...helper.get(props.form, 'locationtags', []), ...helper.get(filters, 'locationtags', [])])], // merge lists
          // value: helper
          //   .get(props.form, 'locationtags', [])
          //   .concat(helper.get(filters, 'locationtags', [])),
          label: translator.t('report.locationTags'),
          visible: f => (props.overrideAble ? f.overrideLocationTags : true),
          // beforeChange: (f: string[]) => {
          //   return difference(f, helper.get(filters, 'locationtags', []));
          // },
        },
        {
          property: 'sensortags',
          type: 'tree',
          tree: props.overrideAble
            ? helper.treeHelper.selectLockAndExpand(
                sensorTags,
                helper.get(filters, 'sensortags', [])
              )
            : sensorTags,

          selectedStyle: selectedColor,
          expandAll: false,
          value: helper.get(props.form, 'sensortags', helper.get(filters, 'sensortags', [])),
          // value: [...new Set([...helper.get(props.form, 'sensortags', []), ...helper.get(filters, 'sensortags', [])])], // merge lists
          // value: helper
          //   .get(props.form, 'sensortags', [])
          //   .concat(helper.get(filters, 'sensortags', [])),
          label: translator.t('report.sensorTags'),
          visible: f => (props.overrideAble ? f.overrideSensorTags : true),
          // beforeChange: (f: string[]) => {
          //   return difference(f, helper.get(filters, 'sensortags', []));
          // },
        },
        {
          property: 'customTags',
          type: 'tree',
          tree: props.overrideAble
            ? helper.treeHelper.selectLockAndExpand(
                customTags,
                helper.get(filters, 'customTags', [])
              )
            : customTags,

          selectedStyle: selectedColor,
          expandAll: false,
          value: helper.get(props.form, 'customTags', helper.get(filters, 'customTags', [])),
          // value: [...new Set([...helper.get(props.form, 'customTags', []), ...helper.get(filters, 'customTags', [])])], // merge lists
          label: translator.t('report.customTags'),
          // beforeChange: (f: string[]) => {
          //   return difference(f, helper.get(filters, 'customTags', []));
          // },
          visible: f => (props.overrideAble ? f.overrideCustomTags : true),
        },
      ]}
      formRef={formRef}
      externalSubmit={true}
      formId={props.formId}
      onChange={(f, h, changed, prevValue) => {
        if (changed === 'dateRange') {
          if (!f.dateRange) {
            // when use fixed date
            h.setValues({
              startDate: moment()
                .startOf('month')
                .format(),
              endDate: moment().format(),
            });
          }
        }

        setCurrentForm(f);
        if (!showFilterButton) {
          props.onChange(convertToApiFilter(f));
        }
      }}
      onSubmit={async f => {
        props.onChange(convertToApiFilter(f));
      }}
    >
      {props.overrideAble && (
        <Static underline={true} label={translator.t('report.override')}>
          <Seperator />
          <Overrides>
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideDateTime"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideWeekends"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideTypes"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideLocations"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideCustomTags"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideSensorTags"
            />
            <SF.Field
              style={{ width: 150 }}
              inlineLabel={true}
              noPadding={true}
              property="overrideLocationTags"
            />
          </Overrides>
          <Seperator showDivider={true} />
        </Static>
      )}

      <Row>
        <Col xs={12}>
          <SF.Field property="dateRange" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SF.Field property="startDate" />

          {currentForm.startDate || currentForm.endDate || currentForm.overrideDateTime ? (
            !currentForm.dateRange ? (
              <Icon style={{ margin: 2 }} iStyle={IconStyle.MUTED} icon="icon-arrow-right"></Icon>
            ) : null
          ) : null}

          <SF.Field property="endDate" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <SF.Field property="startTime" />
          {currentForm.startDate || currentForm.endDate || currentForm.overrideDateTime ? (
            currentForm.dateRange &&
            ['min', 'Hour'].some(c => currentForm.dateRange.includes(c)) ? null : (
              <Icon style={{ margin: 2 }} iStyle={IconStyle.MUTED} icon="icon-arrow-right"></Icon>
            )
          ) : null}
          <SF.Field property="endTime" />
        </Col>
      </Row>

      <SF.Field inlineLabel={true} property="includeHolidays" />
      <SF.Field inlineLabel={true} property="includeWeekends" />
      <SF.Field property="types" />
      <SF.Field property="locations" />
      <SF.Field property="customTags" />
      <SF.Field property="sensortags" />
      <SF.Field property="locationtags" />

      {showFilterButton && (
        <div
          style={{
            position: 'sticky',
            bottom: props.stickyBottom
              ? props.stickyBottom
              : viewOnly
              ? 0
              : props.overrideAble
              ? 0
              : 75,
            height: 45,
            backgroundColor: 'inherit',
          }}
        >
          <Center full>
            <SF.SubmitButton
              bType={BType.HOLLOW}
              icon="icon-filter"
              label={
                props.overrideAble
                  ? translator.t('report.btn.applyOverridedFilter')
                  : translator.t('report.btn.applyFilter')
              }
            />
          </Center>
        </div>
      )}
    </SF.Form>
  );
}
