import BaseChart from './BaseChart';
import {
  toPairs,
  groupBy,
  get,
  first,
  isArray,
  isObject,
  isObjectLike,
  isPlainObject,
} from 'lodash';
import { flattenObject, groupMultiple, nestedGroupBy, deepKeys } from './common';

import { IChartDataset, IChartOptions } from 'chart.js';
class PieChart extends BaseChart {
  private labels: string[];

  constructor(props) {
    super({
      component: props.component,
      dataModel: props.dataModel,
      type: 'pie',
    });
  }
  public getDatasets(): CHART.PieChartConfiguration['data']['datasets'] {
    const datasets: CHART.PieChartConfiguration['data']['datasets'] = [];
    const n = nestedGroupBy(this.dataModel.data, this.serieValues);

    this.serieValues.forEach((s, index) => {
      this.valueValues.forEach(v => {
        const set = {
          data: [],
          backgroundColor: this.getColor('series', s, index),
          borderWidth: 5,
        } as IChartDataset<'pie'>;
        datasets.push(set);
        this.getLabels().map(z => {
          const vs = get(n, z.key);
          set.data.push(vs.reduce((acc, x) => acc + x[v] / vs.length, 0));
        });
      });
    });

    return datasets;
  }

  public getConfig(): CHART.PieChartConfiguration {
    return {
      type: 'pie',
      data: {
        datasets: this.getDatasets(),
        labels: this.getLabels().map(x => x.label),
      },
      options: this.getOptions(),
    };
  }

  private getLabels(seperator: string = '|') {
    const labels: Array<{ label: string; key: string[] }> = [];
    const d = groupMultiple(this.dataModel.data, this.serieValues);

    d.forEach((x, index) => {
      labels.push({
        label: this.serieValues.map(cs => this.getLabelName(cs, get(x[0], cs))).join(seperator),
        key: this.serieValues.map(cs => get(x[0], cs)),
      });
    });
    return labels;
  }

  private getOptions(): IChartOptions<'pie'> {
    return {
      legend: {
        display: true,
      },
      responsive: true,
      circumference: 2 * Math.PI,
      layout: {
        padding: {
          top: 15,
          right: 15,
          bottom: 15,
          left: 15,
        },
      },
      plugins: {
        datalabels: {
          display: this.component.showDataLabels ? 'auto' : false,
          anchor: 'end',
          align: 'top',
          clamp: true,
          backgroundColor: '#fff',
          color: this.serieValues.map(cs => this.getColor('series', cs)),
        },
      },
      maintainAspectRatio: false,
    } as IChartOptions<'pie'>;
  }
}
export default PieChart;
