import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import {
  SmartForm as SF,
  translator,
  Row,
  Col,
  Static,
  Seperator,
  Api,
  helper,
} from '@smart/design';
import { RefreshIntervals } from '../../../statics';
import { useBuilder } from '../BuilderContext';
interface IProps {
  autoChange?: boolean;
  autoSync?: boolean;

  submitLabel?: string;
}
const ReportDetailForm = (props: IProps) => {
  const { form, onFormChange, syncRemote } = useBuilder();

  return (
    <div>
      <Static strong={true} underline={true} label={translator.t('label.detail')} />
      <Seperator />
      <SF.Form<FORM.IReportForm>
        form={[
          SF.createFormField({
            property: 'title',
            type: 'input',
            required: () => true,
            value: form.title,
            label: translator.t('label.title'),
          }),
          SF.createFormField({
            property: 'description',
            type: 'textarea',
            value: form.description,
            label: translator.t('label.description'),
          }),
          SF.createFormField({
            property: 'layoutWidth',
            type: 'input',
            number: true,
            value: form.layoutWidth,
            label: translator.t('label.layoutWith'),
          }),
          SF.createFormField({
            property: 'layoutColumns',
            type: 'input',
            number: true,
            value: form.layoutColumns,
            label: translator.t('label.layoutColumns'),
          }),
          SF.createFormField({
            property: 'hideGlobalFilter',
            type: 'checkbox',
            value: form.hideGlobalFilter,
            description: translator.t('info.hideGlobalFilter'),
            label: translator.t('label.hideGlobalFilter'),
          }),
          SF.createFormField({
            property: 'refreshInterval',
            dropUp: true,
            type: 'select',
            value: form.refreshInterval,
            options: RefreshIntervals(),
            label: translator.t('label.refreshInterval'),
          }),
          SF.createFormField({
            property: 'timezone',
            type: 'searchSelect',
            dropUp: true,
            value: form.timezone || 'Europe/Oslo',

            fetch: async q => {
              const { zones } = await import('../../../../statics/timezone.json');

              return helper
                .searchArray(zones, 'tz', q)
                .map(x => ({
                  label: `${x.tz} ${x.dst_offset || ''}`,
                  value: x.tz,
                }))
                .splice(0, 20);
            },
            component: t => <div>{t}</div>,
            label: translator.t('label.timezone'),
          }),
        ]}
        submitLabel={props.submitLabel || translator.t('label.ok')}
        autoFocus={true}
        formId="builder-general-form"
        noSubmit={!props.autoChange}
        onChange={(f, ff) => {
          if (ff.isValid && !props.autoChange) {
            onFormChange(f);
            props.autoSync && syncRemote();
          }
        }}
        onSubmit={
          props.autoChange
            ? async f => {
                onFormChange(f);
                props.autoSync && syncRemote();
              }
            : undefined
        }
      >
        <SF.Field property="title" />
        <SF.Field property="description" />
        <Row>
          <Col xs={6}>
            <SF.Field property="layoutWidth" />
          </Col>
          <Col xs={6}>
            <SF.Field property="layoutColumns" />
          </Col>
        </Row>

        <SF.Field property="hideGlobalFilter" inlineLabel={true} />

        <SF.Field property="timezone" />
        <SF.Field property="refreshInterval" />
      </SF.Form>
    </div>
  );
};

export default ReportDetailForm;
