import { compact, has, isEmpty, isUndefined, omitBy, orderBy, get, isNull } from 'lodash';

export function isValidAggregation(aggregation: API.IAggregationObject) {
  let isValid = true;
  if (!aggregation || isEmpty(aggregation)) {
    isValid = false;
  } else {
    ['agg1', 'agg2', 'agg3'].forEach((x: keyof API.IAggregationObject) => {
      if (has(aggregation, x)) {
        const agg = aggregation[x] as API.IAggregationPipeline;
        isValid =
          agg && agg.by && agg.by.length && agg.aggregate && agg.aggregate.length ? true : false;
      }
    });
  }
  return isValid;
}
export function aggregationArrayToObject(
  array: FORM.IIPipelineAggregation[],
  usage: FORM.IAggregationUsage[]
): API.IAggregationObject {
  const meta = usage.reduce((acc, c, index) => {
    acc[c.property] = {
      ...c,
      order: index,
    };
    return acc;
  }, {}) as Record<string, API.IAggregationMetaObject>;
  const postprocess = usage.reduce((acc, c, index) => {
    if (
      c.valuePostProcess &&
      c.valuePostProcess.value &&
      !isEmpty(c.valuePostProcess.value.trim())
    ) {
      acc[c.property] = c.valuePostProcess.value;
    }
    return acc;
  }, {});
  const aggregation: API.IAggregationObject = array.reduce((acc, x, index) => {
    const k = index === 0 ? 'agg1' : index === 1 ? 'agg2' : index === 2 ? 'agg3' : null;
    acc[k] = {
      by: x.by.slice().sort((a, b) => {
        return get(meta, [a, 'order'], 0) - get(meta, [b, 'order'], 0);
      }),
      aggregate: x.aggregate.map(a => {
        return {
          as: a.as,
          operation: a.operation,
          property: a.property,
        };
      }),
      postprocess,
      sort:
        x.sorts && index === array.length - 1
          ? x.sorts.reduce((racc, s) => {
              racc[s.property] = s.type;
              return racc;
            }, {})
          : undefined,
    };
    acc[k] = omitBy(acc[k], s => isEmpty(s) || isUndefined(s) || isNull(s));

    return acc;
  }, {});

  const obj: API.IAggregationObject = {
    agg1: aggregation.agg1,
    agg2: aggregation.agg2,
    agg3: aggregation.agg3,

    meta,
  };
  return omitBy(obj, isUndefined);
}
export function aggregationObjectToArray(
  obj: API.IAggregationObject,
  defaultValue?: API.IAggregationObject
): [FORM.IIPipelineAggregation[], API.IAggregationMetaObject[]] {
  const v = obj || defaultValue;
  if (!v) {
    return [[], []];
  }
  const t: FORM.IIPipelineAggregation[] = compact([v.agg1, v.agg2, v.agg3]).map(l => {
    return {
      ...l,
      sorts: l.sort
        ? Object.keys(l.sort).map(s => ({
            property: s,
            type: l.sort[s],
          }))
        : [],
    };
  });

  let m = [];
  if (v.meta) {
    Object.keys(v.meta).forEach(k => {
      m.push({
        property: k,
        ...v.meta[k],
      });
    });
    m = orderBy(m, 'order');
  }

  return [t, m];
}
