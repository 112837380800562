import { Alert, translator } from '@smart/design';
import React, { RefObject, useCallback } from 'react';
import { ComponentType } from '../../../statics';
import { useBuilder } from '../BuilderContext';
import LabelForm from './/LabelForm';
import ClockForm from './ClockForm';
import GraphForm from './GraphForm';
import ImageForm from './ImageForm';
import MapForm from './MapForm';
import TextForm from './TextForm';

interface IProps {
  formId: string;
}

const ComponentForm = (props: IProps) => {
  const {
    form: { componentsForms },
    setFormComponent,
  } = useBuilder();

  const form = componentsForms[props.formId];
  const setForm = useCallback(
    (f: FORM.IComponentFormType) => {
      setFormComponent(f.id, f);
    },
    [props.formId]
  );

  if (!form)
    return (
      <Alert
        data={[
          {
            type: 'error',
            description: translator.t('error.thisComponentHasbeenRemoved'),
          },
        ]}
      />
    );

  let Component = null;
  switch (form.type) {
    case ComponentType.TEXT:
      Component = <TextForm form={form} onChange={setForm} />;
      break;
    case ComponentType.IMAGE:
      Component = <ImageForm form={form} onChange={setForm} />;
      break;
    case ComponentType.GRAPH:
      Component = <GraphForm form={form} onChange={setForm} />;
      break;
    case ComponentType.LABEL:
      Component = <LabelForm form={form} onChange={setForm} />;
      break;
    case ComponentType.GAUGE:
      Component = <LabelForm form={form} onChange={setForm} />;
      break;
    case ComponentType.MAP:
      Component = <MapForm form={form} onChange={setForm} />;
      break;
    case ComponentType.CLOCK:
      Component = <ClockForm form={form} onChange={setForm} />;
      break;
  }
  return <div>{Component}</div>;
};
export default ComponentForm;
