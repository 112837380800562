import { Api, Deferred, IApi, Model, moment } from '@smart/design';
import {
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isUndefined,
  mapValues,
  mergeWith,
  omit,
  omitBy,
  toNumber,
  template,
  templateSettings,
  toString,
  get,
  unset,
} from 'lodash';
import AggregationPropertyModel from './AggregationPropertyModel';
import timezone from 'moment-timezone';
import hash from 'object-hash';
import ReportModel from './ReportModel';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

function parseEval(str) {
  return Function(`'use strict'; return (${str})`)();
}
interface IConfig {
  apiURL: string;
}

interface IIdentifier {
  filter: API.IDefaultFilter;
  companyId: string;
  reportId: string;
  property: string;
  aggregation: API.IAggregationObject;
}

class PropertyDataModel extends Model<MODEL.IDataModel, IConfig, IIdentifier> {
  private api: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  public combineFilter(current: API.IDefaultFilter, override: API.IDefaultFilter) {
    if (!override) {
      return current as API.IDefaultFilter;
    }

    return mergeWith({}, current, override, (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
      if (isBoolean(srcValue) && srcValue !== true) {
        return objValue;
      }
      if (isEqual(objValue, srcValue)) {
        return objValue;
      }
    });
  }
  public idMapper(id: IIdentifier): string {
    const params = this.buildParams(id);
    const m =
      id.aggregation && id.aggregation.meta
        ? mapValues(id.aggregation.meta, x => x.valuePostProcess)
        : null;
    const obj = hash({
      ...params,
      ...m,
      property: id.property,
      companyId: id.companyId,
    });

    return obj;
  }

  protected mapObject(
    obj: { data: API.IPropertyData[]; report: MODEL.IReportModel },
    id: IIdentifier
  ): MODEL.IDataModel {
    if (obj.report) {
      if (obj.report.timezone) {
        obj.data.forEach(x => {
          if (x.datetime) {
            x.datetime = timezone.tz(x.datetime, obj.report.timezone).format();
          }
        });
      }
    }
    if (id.aggregation && (id.aggregation.agg1 || id.aggregation.agg2 || id.aggregation.agg3)) {
      const agg = id.aggregation.agg1 || id.aggregation.agg2 || id.aggregation.agg3;

      Object.keys(id.aggregation.meta)
        .filter(x => id.aggregation.meta[x] && !get(agg, ['postprocess', x], null))
        .forEach(l => {
          const meta = id.aggregation.meta[l];
          if (meta.valuePostProcess && !isEmpty(meta.valuePostProcess.value)) {
            switch (meta.valuePostProcess.type) {
              case 'date':
                obj.data.forEach(o => {
                  if (moment(o[l]).isValid()) {
                    o[l] = moment(o[l]).format(meta.valuePostProcess.value);
                  }
                });

                break;
              case 'string':
                obj.data.forEach(o => {
                  o[l] = toString(
                    template(meta.valuePostProcess.value)({
                      value: o[l],
                    })
                  );
                });
                break;
              case 'number':
                obj.data.forEach(o => {
                  o[l] = toNumber(
                    // tslint:disable-next-line: no-eval
                    parseEval(
                      template(meta.valuePostProcess.value)({
                        value: o[l],
                      })
                    )
                  );
                });

                break;
            }
          }
        });
      return {
        type: 'aggregated',
        filter: id.filter,
        aggregation: id.aggregation,
        data: obj.data as API.IAggregatePropertyData[],
      };
    } else {
      return {
        type: 'noneAggregated',
        filter: id.filter,
        data: obj.data as API.INoneAggregatePropertyData[],
      };
    }
  }

  protected async single(id: IIdentifier) {
    const { companyId, reportId, property } = id;
    if (property) {
      try {
        const filteredparams = await this.filterPostProcessing(id, this.buildParams(id));

        const [{ data }, report] = await Promise.all([
          this.api.post(
            `dashboard/companies/${companyId}/reports/${reportId}/data/default/${property}`,
            filteredparams
          ),
          ReportModel.load({
            companyId: id.companyId,
            reportId: id.reportId,
          }),
        ]);

        return {
          data: {
            data,
            report,
          },
          identifier: id,
        };
      } catch (e) {
        console.log(e);
        throw e;
      }
    } else {
      return {
        data: {
          data: [],
          report: null,
        },
        identifier: id,
      };
    }
  }
  private async filterPostProcessing(
    id: IIdentifier,
    params: Partial<
      API.IDefaultFilter & {
        aggregation: Omit<API.IAggregationObject, 'meta'>;
      }
    >
  ) {
    return Promise.all(
      Object.keys(params.aggregation)
        .sort()
        .map(async (agg: 'agg1' | 'agg2' | 'agg3', index, array) => {
          if (index !== array.length - 1) {
            unset(params.aggregation[agg], 'sort');
            unset(params.aggregation[agg], 'postprocess');
            return;
          }
          const postprocessing = params.aggregation[agg].postprocess;

          return Promise.all(
            Object.keys(postprocessing).map(async key => {
              const value = postprocessing[key];

              const hasPostProcessing = await AggregationPropertyModel.hasPostProcessing(id, value);
              if (!hasPostProcessing || isEmpty(value)) {
                unset(postprocessing, key);
              }
              if (isEmpty(value)) {
                unset(postprocessing, key);
              }
              if (isEmpty(postprocessing)) {
                unset(params.aggregation[agg], 'postprocess');
              }
            })
          );
        })
    )
      .then(() => params)
      .catch(() => params);
  }
  private buildParams(
    id: IIdentifier
  ): Partial<
    API.IDefaultFilter & {
      aggregation: API.IAggregationObject;
    }
  > {
    const { filter, aggregation } = id;

    const params = Object.assign(
      {
        includeHolidays: filter.includeHolidays,
        includeWeekends: filter.includeWeekends,
        locations: filter.locations,
        types: filter.types,
        locationtags: filter.locationtags,
        sensortags: filter.sensortags,
        customTags: filter.customTags,
        aggregation: this.buildAggregationParams(aggregation),
      },
      ReportModel.fromDateRangeToStartAndEndDate(filter.dateRange, {
        startDate: filter.startDate && moment(filter.startDate).format('YYYY-MM-DD'),
        endDate: filter.endDate && moment(filter.endDate).format('YYYY-MM-DD'),
        startTime: filter.startTime,
        endTime: filter.endTime,
      })
    );

    return omitBy(params, v => isUndefined(v) || (isArray(v) && isEmpty(v)));
  }

  private buildAggregationParams(aggregation: API.IAggregationObject): object {
    const m = omit(aggregation, 'meta');
    if (!m || isEmpty(m)) return undefined;
    const t = omitBy(
      {
        agg1: omitBy(m.agg1, v => isUndefined(v) || isEmpty(v)),
        agg2: omitBy(m.agg2, v => isUndefined(v) || isEmpty(v)),
        agg3: omitBy(m.agg3, v => isUndefined(v) || isEmpty(v)),
      },
      v => isUndefined(v) || isEmpty(v)
    );
    return t;
  }
}

export default new PropertyDataModel('PropertyDataModel');
