import {
  Actions,
  Alert,
  BS,
  BSize,
  BStyle,
  BType,
  Center,
  css,
  helper,
  IconStyle,
  ModelSubscription,
  notifications,
  OptionTrigger,
  Seperator,
  styled,
  TooltipPlacement,
  translator,
  useDebounceCallback,
  useStateWithCallback,
  useThemeContext,
  Wrapper,
  Router,
  modalManager,
  SmartForm,
  Row,
  Col,
  DataTable,
} from '@smart/design';
import { toPairs, values } from 'lodash';
import ReportModel from '../../models/ReportModel';

import React, { useRef, useState } from 'react';
import GridLayout, { contextType, Layout, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ComponentType, RefreshIntervals } from '../../statics';
import { useBuilder } from './BuilderContext';
import ComponentDisplay from './displays/ComponentDisplay';
import ComponentPickerForm from './forms/ComponentPicker';
import GlobalFilterForm from './forms/GlobalFilterForm';
import { generatePDF, goBoards, duplicateDashboard, saveDashboard } from './UIActions';
import { removeDashboard } from '../Dashboards/UIActions';

import ReportDetailForm from './forms/ReportDetailForm';
import ComponentForm from './forms/ComponentForm';
import AccessModal from '../Dashboards/AccessModal';
import ComplianceButton from '../../components/ComplianceButton';
import { editDashboard } from '../Dashboards/UIActions';
const Component = styled.div<{ selected: boolean; hasData: boolean; viewOnly: boolean }>`
  .react-resizable-handle {
    opacity: 0.8;
  }
  ${({ hasData, selected, theme }) =>
    selected &&
    css`
      box-shadow: 0 0px 4px 4px rgba(0, 0, 0, 0.1);
    `}
  &:hover {
    box-shadow: 0 0px 4px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  cursor: ${({ viewOnly }) => (viewOnly ? 'default' : 'pointer')};
  overflow: hidden;
`;

interface IProps {
  activeLayout: Layout;
  changeLayout?: (layouts: Layout[]) => void;
  selectLayout: (layout: Layout) => void;
  setFormType: (layout: Layout, type: FORM.IComponentFormBase) => void;
}

const Dashboard = (props: IProps) => {
  const context = useBuilder();
  const { setFormComponent, isNew, companyId, deleteFormComponent, ...form } = context;
  const theme = useThemeContext();
  const [viewOnly, setViewOnly] = useState(form.viewOnly);
  const { selectLayout, activeLayout } = props;
  const formRef = useRef<HTMLDivElement>();

  const layoutPairs = toPairs(form.form.layouts);
  const [editable, setEditable] = useState<boolean>(false);
  const [clipboard, setClipboard] = useState<FORM.IComponentFormType>(null);
  const changeLayout = useDebounceCallback(layout => {
    props.changeLayout(layout);
  }, 300);
  const dashboardRef = useRef<HTMLDivElement>();
  const printRef = useRef<HTMLDivElement>();

  return (
    <>
      <div
        className="no-print"
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: theme.colors.background,
          zIndex: 1,
          width: '100%',
          borderBottom: `1px solid ${theme.colors.light}`,
        }}
      >
        <Wrapper>
          <Actions.Container buttonSpace={5} noPadding={true}>
            <Actions.Left></Actions.Left>

            <Actions.Center>
              <Center>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!viewOnly ? (
                    <>
                      <BS.IconButton
                        bSize={BSize.MEDIUM}
                        bStyle={editable ? BStyle.PRIMARY : BStyle.DANGER}
                        onClick={() => setEditable(!editable)}
                        icon={editable ? 'icon-lock-open' : 'icon-lock'}
                        tooltipConf={{
                          placement: TooltipPlacement.LEFT,
                        }}
                        tooltip={
                          editable
                            ? translator.t('dashboard.editable.on')
                            : translator.t('dashboard.editable.off')
                        }
                      />
                    </>
                  ) : null}
                  {!form.viewOnly ? (
                    <BS.IconButton
                      bSize={BSize.MEDIUM}
                      bStyle={viewOnly ? BStyle.PRIMARY : BStyle.MUTED}
                      onClick={() => setViewOnly(!viewOnly)}
                      icon={viewOnly ? 'icon-eye' : 'icon-eye-slash'}
                      tooltipConf={{
                        placement: TooltipPlacement.LEFT,
                      }}
                      tooltip={
                        viewOnly
                          ? translator.t('dashboard.viewable.on')
                          : translator.t('dashboard.viewable.off')
                      }
                    />
                  ) : null}
                  {!isNew && !viewOnly && (
                    <BS.IconButton
                      bStyle={BStyle.MUTED}
                      bSize={BSize.MEDIUM}
                      onClick={() => duplicateDashboard(form.form)}
                      icon="icon-clone"
                      tooltipConf={{
                        placement: TooltipPlacement.LEFT,
                      }}
                      tooltip={translator.t('dashboard.btn.duplicate')}
                    />
                  )}
                  {!viewOnly && (
                    <>
                      <ComplianceButton
                        icon="icon-key"
                        bSize={BSize.MEDIUM}
                        bType={BType.ICON}
                        action="EditAccess"
                        bStyle={BStyle.WARNING}
                        tooltip={translator.t('dashboards.btn.access')}
                        tooltipConf={{
                          placement: TooltipPlacement.LEFT,
                        }}
                        onClick={() => {
                          return modalManager.show(AccessModal, {
                            companyId: context.companyId,
                            reportId: context.reportId,
                          });
                        }}
                      />
                      <OptionTrigger
                        actionButton={{
                          bType: BType.ICON,
                          bStyle: BStyle.PRIMARY,
                          bSize: BSize.MEDIUM,

                          icon: 'icon-cog',
                          tooltip: translator.t('dashboard.btn.setting'),
                          tooltipConf: {
                            placement: TooltipPlacement.LEFT,
                          },
                        }}
                        render={() => {
                          return (
                            <>
                              <ReportDetailForm autoChange={true} autoSync={true} />
                              {!context.isNew && (
                                <Center>
                                  <BS.RemoveButton
                                    tooltip={translator.t('btn.remove')}
                                    bSize={BSize.MEDIUM}
                                    tooltipConf={{
                                      placement: TooltipPlacement.RIGHT,
                                    }}
                                    onClick={() =>
                                      removeDashboard(context.companyId, context.reportId)
                                    }
                                  ></BS.RemoveButton>
                                </Center>
                              )}
                            </>
                          );
                        }}
                      />
                    </>
                  )}

                  <div style={{ marginLeft: 25, marginRight: 25 }}>
                    {viewOnly ? (
                      <strong style={{ whiteSpace: 'nowrap' }}>{form.form.title}</strong>
                    ) : (
                      <OptionTrigger
                        actionButton={
                          <strong style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                            {form.form.title}
                          </strong>
                        }
                        render={close => {
                          return (
                            <DataTable<API.IDashBoard>
                              displaySearch={false}
                              renderKey={'reportId'}
                              displayPagenation={false}
                              displayPagenationN={false}
                              displayHeader={false}
                              onRowClick={c => {
                                editDashboard(c.companyId, c.reportId);
                                close();
                              }}
                              columns={[
                                {
                                  property: 'title',
                                  content: f => <span>{f.title}</span>,
                                  label: translator.t('label.dashboards'),
                                },
                              ]}
                              fetch={async q => {
                                const m = await ReportModel.listReports(companyId, q.search);
                                return m;
                              }}
                            ></DataTable>
                          );
                        }}
                      />
                    )}

                    {/*  <div
                      style={{
                        fontSize: 12,
                        fontStyle: 'italic',
                      }}
                    >
                      <ModelSubscription
                        options={{
                          noSubscription: true,
                          once: true,
                        }}
                        model={CompanyModel}
                        id={form.form.companyId}
                        render={d => {
                          return <div>{d.modelData.$data.name}</div>;
                        }}
                      />
                    </div> */}
                  </div>
                  {!viewOnly && (
                    <BS.IconButton
                      icon="icon-save"
                      tooltip={translator.t('btn.save')}
                      bStyle={context.pendingChanges ? BStyle.WARNING : BStyle.PRIMARY}
                      bSize={BSize.MEDIUM}
                      tooltipConf={{
                        placement: TooltipPlacement.RIGHT,
                      }}
                      className={context.pendingChanges && 'pulse'}
                      onClick={() => saveDashboard(context)}
                    ></BS.IconButton>
                  )}
                </div>
              </Center>
            </Actions.Center>
            <Actions.Right></Actions.Right>
            {/* <Actions.Right>
              <BS.IconButton
                icon="icon-file-pdf"
                bStyle={BStyle.MUTED}
                bSize={BSize.MEDIUM}
                onClick={() => generatePDF(form.form, printRef.current, 'download')}
              />
              <BS.IconButton
                icon="icon-print"
                bStyle={BStyle.MUTED}
                bSize={BSize.MEDIUM}
                onClick={() => generatePDF(form.form, printRef.current, 'print')}
              />
            </Actions.Right> */}
          </Actions.Container>
        </Wrapper>
      </div>

      <div ref={dashboardRef} className="dashboard-scroller">
        <div ref={printRef} style={{ width: form.form.layoutWidth, height: 'auto' }}>
          <GridLayout
            className="layout"
            cols={form.form.layoutColumns}
            containerPadding={[15, 15]}
            rowHeight={50}
            onResizeStop={f => {
              changeLayout.callback(f);
            }}
            onDragStop={f => {
              changeLayout.callback(f);
            }}
            autoSize={true}
            useCSSTransforms={false}
            isBounded={true}
            isDraggable={editable}
            isResizable={editable}
            layout={values(form.form.layouts)}
            width={form.form.layoutWidth}
            style={{ width: form.form.layoutWidth }}
            resizeHandles={['sw', 'se', 'nw', 'ne']}
          >
            {layoutPairs
              .filter(([id]) => (viewOnly ? form.form.componentsForms[id] : true))
              .map(([id, l]) => {
                const component = form.form.componentsForms[id];
                if (component && component.type === ComponentType.MAP) {
                  l.isDraggable = false;
                }
                return (
                  <Component
                    viewOnly={viewOnly}
                    selected={activeLayout && activeLayout.i === l.i}
                    key={id}
                    className={component ? 'print-able' : 'print-ignore'}
                    hasData={form.form.componentsForms[l.i] ? true : false}
                    data-grid={{ ...l }}
                  >
                    {component ? (
                      <ComponentDisplay
                        viewOnly={viewOnly}
                        layoutLocks={editable}
                        remove={() => {
                          deleteFormComponent(l.i);
                        }}
                        expand={(e, el) => {
                          if (e) {
                            el.parentElement.classList.add('expanded');
                          } else {
                            el.parentElement.classList.remove('expanded');
                          }
                        }}
                        onClick={() => {
                          if (component && !viewOnly) {
                            if (activeLayout && activeLayout.i !== component.id) {
                              selectLayout(l);
                            } else if (!activeLayout) {
                              selectLayout(l);
                            }
                          }
                        }}
                        clone={() => {
                          notifications.info('clone');

                          setClipboard(Object.assign({}, component));
                        }}
                        component={component}
                      />
                    ) : clipboard ? (
                      <Center full={true}>
                        <BS.IconButton
                          icon="icon-paste"
                          bSize={BSize.LARGE}
                          bStyle={BStyle.PRIMARY}
                          onClick={d => {
                            clipboard.id = l.i;
                            setFormComponent(l.i, clipboard);
                            setClipboard(null);
                          }}
                        />
                      </Center>
                    ) : (
                      <ComponentPickerForm
                        formId={l.i}
                        setType={type => {
                          props.setFormType(l, type);
                        }}
                      />
                    )}
                  </Component>
                );
              })}
          </GridLayout>
        </div>
      </div>
      {form.form.hideGlobalFilter && viewOnly ? null : (
        <GlobalFilterForm bounds={dashboardRef} offset={activeLayout ? '30vw' : '0px'} />
      )}
      <div
        ref={formRef}
        style={{
          position: 'fixed',
          width: '30vw',
          right: `${activeLayout ? '0px' : '-30vw'}`,
          transition: 'all 0.3s ease-in-out',
          overflow: 'auto',
          height: '100%',
          top: 66,
          backgroundColor: theme.colors.background,
        }}
      >
        {activeLayout && form.form.componentsForms[activeLayout.i] && (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: theme.colors.background,
                zIndex: 2,
                padding: 5,
              }}
            >
              <Actions.Container>
                <Actions.Left>
                  <BS.IconButton bStyle={BStyle.INVERTED} icon="icon-bars" />
                </Actions.Left>
                {translator.t('label.editor')}
                <Actions.Right>
                  <BS.IconButton
                    icon="icon-times"
                    onClick={() => {
                      selectLayout(null);
                    }}
                    iconStyle={IconStyle.PRIMARY}
                  />
                </Actions.Right>
              </Actions.Container>
              <Seperator showDivider={true} noMargin={true} />
            </div>
            <Wrapper padding={25}>
              {form.form.componentsForms[activeLayout.i] && (
                <ComponentForm key={activeLayout.i} formId={activeLayout.i} />
              )}
            </Wrapper>
            <Seperator showDivider={false} marginBottom={50} />
          </div>
        )}
      </div>

      {clipboard && (
        <div style={{ position: 'fixed', bottom: 0, width: context.form.layoutWidth }}>
          <Center>
            <Alert
              data={[
                {
                  type: 'info',
                  boxType: 'hollow',
                  description: translator.t('info.somethingIsInClipboard'),
                  actions: [
                    {
                      icon: 'icon-times',
                      bType: BType.ICON,
                      onClick: () => {
                        setClipboard(null);
                      },
                    },
                  ],
                },
              ]}
            />
          </Center>
        </div>
      )}

      {context.cached && !viewOnly && (
        <div style={{ position: 'fixed', bottom: 0, width: context.form.layoutWidth }}>
          <Center>
            <Alert
              data={[
                {
                  type: 'info',
                  boxType: 'hollow',
                  description: translator.t('info.cached'),
                  actions: [
                    {
                      icon: 'icon-check',
                      bType: BType.ICON,
                      onClick: () => {
                        context.applyCurrentCached(true);
                      },
                    },
                    {
                      icon: 'icon-times',
                      bType: BType.ICON,
                      onClick: () => {
                        context.applyCurrentCached(false);
                      },
                    },
                  ],
                },
              ]}
            />
          </Center>
        </div>
      )}
    </>
  );
};

export default Dashboard;
