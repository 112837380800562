import { Icon, IconStyle, styled, Wrapper } from '@smart/design';
import { useDidUpdate } from '@smart/design/src/scopes/common/hooks';
import { forEach, get, groupBy, gte, lte, orderBy, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import CompanyMapModel from '../../../models/CompanyMapModel';
import FloorMapModel from '../../../models/FloorMapModel';
import LocationTreeModel from '../../../models/LocationTreeModel';
import PropertyDataModel from '../../../models/PropertyDataModel';
import AggregationModel from '../../../services/charts/builders/AggregationModel';
import { useBuilder } from '../BuilderContext';
import { findRange } from './common';
import './map.less';

interface IProps {
  component: FORM.IMapComponentForm;
}

const LocationItem = styled.div`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.light};
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
  }
`;

interface IFloorType {
  level: number;
  id: string;
  source: string;
  outlineLayer: string;
  locationIds: string[];
  fillLayer: string;
  floorFillLayer: string;
}

class CholoroPlethController implements mapboxgl.IControl {
  private map: mapboxgl.Map;
  private container: HTMLDivElement;
  private child: HTMLDivElement = null;
  public onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    if (this.child) {
      this.container.appendChild(this.child);
    }
    return this.container;
  }
  public onRemove() {
    this.container.parentNode.removeChild(this.container);

    this.map = undefined;
  }
  public addComponent(component: API.IComponent['mapComponent']) {
    if (this.child && this.container) {
      this.container.removeChild(this.child);
    }
    this.child = document.createElement('div');
    if (this.container) {
      this.container.appendChild(this.child);
    }

    render(
      <div
        style={{ backgroundColor: '#fff', border: '1px solid #fff', borderRadius: 2, padding: 5 }}
      >
        <div>{component.property}</div>
        {component.ranges?.map((r, index) => {
          return (
            <div key={index}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    border: '1px solid #fff',
                    borderRadius: 2,
                    backgroundColor: r.color,
                    marginRight: 5,
                  }}
                ></div>
                <div>
                  {r.name} {r.min || '∞'} - {r.max || '∞'}
                </div>
              </div>
            </div>
          );
        })}
      </div>,
      this.child
    );
  }
}
class FloorLevelControl implements mapboxgl.IControl {
  private map: mapboxgl.Map;
  private container: HTMLDivElement;
  private floors: IFloorType[] = [];
  private floorsElements: HTMLButtonElement[] = [];
  private selectedFloor: IFloorType;
  private locations: string[] = [];
  private colorFilter: string[] = [];
  private colorMap: Record<string, { color: string; value: number }> = {};
  private currentFillLayerId: string;
  private currentFloorFillLayerId: string;

  public onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    return this.container;
  }

  public setLocationColors(colors: Record<string, { color: string; value: number }>) {
    this.colorMap = colors;
    this.selectedFloor &&
      this.drawFillLayer(
        this.selectedFloor.fillLayer,
        this.selectedFloor.source,
        this.selectedFloor.locationIds
      );
  }

  public setFloors(floors: IFloorType[]) {
    this.floors = orderBy(floors, 'level', 'asc');
    this.floorsElements.forEach(b => {
      this.container.removeChild(b);
    });
    this.floorsElements = [];
    this.floors.forEach(floor => {
      const b = document.createElement('button');
      b.innerText = String(floor.level);
      b.onclick = () => {
        this.floorsElements.forEach(c => c.classList.remove('active'));
        b.classList.add('active');
        this.setActiveFloor(floor);
      };
      this.container.appendChild(b);
      this.floorsElements.push(b);
    });
    this.floorsElements[0]?.click();
  }
  public setActiveFloor(floor: IFloorType) {
    if (!floor) return;
    if (this.selectedFloor) {
      this.map.getLayer(this.selectedFloor.outlineLayer) &&
        this.map.removeLayer(this.selectedFloor.outlineLayer);
      this.map.getLayer(this.selectedFloor.fillLayer) &&
        this.map.removeLayer(this.selectedFloor.fillLayer);
    }

    this.selectedFloor = floor;
    this.drawOutlineLayer(this.selectedFloor.outlineLayer, this.selectedFloor.source);

    this.drawFillLayer(
      this.selectedFloor.fillLayer,
      this.selectedFloor.source,
      this.selectedFloor.locationIds
    );
  }

  public drawOutlineLayer(layer: string, source: string) {
    if (!this.map.getSource(source)) return;
    if (this.map.getLayer(layer)) return;
    this.map.addLayer({
      id: layer,
      type: 'line',
      source,
      paint: {
        'line-color': '#777',
      },
    });
  }
  public drawFillLayer(layer: string, source: string, locationIds: string[]) {
    if (!this.map.getSource(source)) return;
    if (this.map.getLayer(layer)) {
      this.map.removeLayer(layer);
    }

    const colorFilters = [];
    forEach(this.colorMap, (v, k) => {
      colorFilters.push(k);
      colorFilters.push(v.color);
    });
    colorFilters.push('transparent');
    this.currentFillLayerId = layer;
    if (colorFilters.length > 1) {
      this.map.addLayer({
        id: layer,
        type: 'fill',
        source,
        paint: {
          'fill-color': ['match', ['get', 'locationId'], ...colorFilters],
        },
      });
    }
  }

  public onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}
const MapDisplay = (props: IProps) => {
  const { companyId, reportId, filters: defaultFilter, form } = useBuilder();

  const mapInstanceRef = useRef<mapboxgl.Map>();
  const mapContainerRef = useRef<HTMLDivElement>();

  const wrapperRef = useRef<HTMLDivElement>();
  const floorSources = useRef<Set<string>>(new Set<string>());
  const locations = PropertyDataModel.combineFilter(defaultFilter, props.component.overrideFilter)
    .locations;
  const floorControl = useRef<FloorLevelControl>(new FloorLevelControl());
  const cloroplathControl = useRef<CholoroPlethController>(new CholoroPlethController());

  const [showLocations, setShowLocations] = useState<boolean>(false);
  const [loader, showLoader] = useState<boolean>(false);

  const [properyData, setPropertyData] = useState<MODEL.IDataModel>(null);
  useEffect(() => {
    mapInstanceRef.current.resize();
  });

  useEffect(() => {
    if (!properyData) return;
    switch (properyData.type) {
      case 'noneAggregated':
        {
          const g = groupBy(properyData.data, 'entityId');
          const colors = new Map<string, { color: string; value: number }>();
          forEach(g, (v, k) => {
            const avg = v.reduce((x, c, i, arr) => x + c.avg / arr.length, 0);
            props.component?.ranges?.forEach(c => {
              if (gte(avg, c.min) && lte(avg, c.max)) {
                colors.set(k, {
                  color: c.color,
                  value: avg,
                });
              }
            });
          });
          floorControl.current.setLocationColors(Object.fromEntries(colors.entries()));
        }

        break;
      case 'aggregated':
        {
          const g = groupBy(properyData.data, 'entityId');
          const colors = new Map<string, { color: string; value: number }>();
          const m = new AggregationModel({
            dataModel: properyData,
            aggregation: props.component.aggregation,
          });
          if (m.valueValues)
            forEach(g, (v, k) => {
              const avg = v.reduce(
                (x, c, i, arr) => x + (get(c, m.valueValues[0]) as number) / arr.length,
                0
              );
              if (props.component.ranges) {
                const range = findRange(avg, props.component.ranges);
                if (range) {
                  colors.set(k, {
                    color: range.color,
                    value: avg,
                  });
                }
              }
            });
          floorControl.current.setLocationColors(Object.fromEntries(colors.entries()));
        }
        break;
    }
  }, [properyData, props.component.ranges]);

  useEffect(() => {
    cloroplathControl.current.addComponent({
      aggregation: props.component.aggregation,
      property: props.component.property,
      ranges: props.component.ranges,
      mapType: props.component.mapType,
      overrideFilter: props.component.overrideFilter,
    });
  }, [props.component.ranges]);

  const loadLocationsMap = useCallback(() => {
    if (!locations) return;
    const load = async () => {
      showLoader(true);
      try {
        const floors = locations.map(loc => LocationTreeModel.getFloor(loc)).filter(x => x);
        if (!floors.length) return;
        const ids = floors.map(x => ({
          companyId,
          locationId: x.floorId,
        }));
        const locationModels = await FloorMapModel.loadAll(ids);

        const floorLevels: IFloorType[] = uniqBy(floors, 'floorId').map(x => {
          return {
            id: x.floorId,
            level: x.floorLevel,
            source: `floor_source_${x.floorId}`,
            outlineLayer: `floor_source_line_${x.floorId}`,
            fillLayer: `floor_source_fill_${x.floorId}`,
            floorFillLayer: `floor_source_floor_fill_${x.floorId}`,

            locationIds: locations.filter(loc => !floors.find(f => f.floorId === loc)),
          };
        });
        locationModels.forEach(loc => {
          const source = `floor_source_${loc.identifier.locationId}`;
          if (floorSources.current.has(source)) {
            mapInstanceRef.current.fitBounds(loc.bounds, {
              animate: true,
            });
            return;
          }
          mapInstanceRef.current.addSource(source, {
            type: 'geojson',
            data: loc.collection,
            generateId: true,
          });

          floorSources.current.add(source);
          mapInstanceRef.current.fitBounds(loc.bounds, {
            animate: false,
          });
        });

        floorControl.current.setFloors(floorLevels);
      } finally {
        showLoader(false);
      }
    };
    load();
  }, [locations, floorControl.current, mapInstanceRef.current]);

  const loadMapData = useCallback(() => {
    const load = async () => {
      showLoader(true);
      try {
        const data = await PropertyDataModel.load({
          filter: PropertyDataModel.combineFilter(defaultFilter, props.component.overrideFilter),
          aggregation: props.component.aggregation,
          companyId,
          reportId,
          property: props.component.property,
        });
        setPropertyData(data);
      } finally {
        showLoader(false);
      }
    };

    if (props.component?.property) load();
  }, [props.component.aggregation, props.component.overrideFilter, props.component.property]);
  useLayoutEffect(() => {
    mapInstanceRef.current = new mapboxgl.Map({
      accessToken: APP_CONFIG.MAPBOX_ACCESSTOKEN,
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
    });

    mapInstanceRef.current.once('load', () => {
      mapInstanceRef.current.addControl(floorControl.current, 'bottom-left');
      mapInstanceRef.current.addControl(cloroplathControl.current, 'top-left');

      if (locations.length) {
        loadLocationsMap();
      } else {
        const loadCompanyMap = async () => {
          const companyMap = await CompanyMapModel.load(companyId);
          mapInstanceRef.current.addSource('company_map_source', {
            type: 'geojson',
            data: companyMap.collection,
            generateId: true,
          });

          mapInstanceRef.current.addLayer({
            id: 'company_map_line_layer',
            type: 'line',
            source: 'company_map_source',
            layout: {
              visibility: 'none',
            },
            paint: {
              'line-color': '#777',
            },
          });
          mapInstanceRef.current.fitBounds(companyMap.bounds, {
            animate: false,
          });
        };
        loadCompanyMap();
      }
      loadMapData();
    });
    return () => {
      mapInstanceRef.current.remove();
    };
  }, []);

  useDidUpdate(() => {
    loadLocationsMap();
  }, [locations]);

  useDidUpdate(() => {
    loadMapData();
  }, [props.component.aggregation, props.component.overrideFilter, props.component.property]);

  return (
    <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
      {loader && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <Icon spin={true} icon="icon-spinner" iStyle={IconStyle.DANGER}></Icon>
        </div>
      )}
      <div
        ref={mapContainerRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      ></div>
      {showLocations && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: 200,
            background: '#fff',
          }}
        >
          <Wrapper>
            {locations?.map(l => {
              return <LocationItem key={l}>{LocationTreeModel.getLocationName(l)}</LocationItem>;
            })}
          </Wrapper>
        </div>
      )}
    </div>
  );
};

export default MapDisplay;
