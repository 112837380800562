import { mapValues, groupBy, isArray, isObject, last } from 'lodash';
export function nestedGroupBy<T>(seq: T[], keys: string[]) {
  if (!keys.length) {
    return seq;
  }
  const [first, ...rest] = keys;
  return mapValues(groupBy(seq, first), value => nestedGroupBy(value, rest));
}

export function deepKeys(
  obj: object,
  stack: string[] = [],
  parent?: string,
  intermediate?: boolean
) {
  Object.keys(obj).forEach(el => {
    // Escape . in the element name
    const escaped = el.replace(/\./g, '\\.');
    // If it's a nested object
    if (isObject(obj[el]) && !isArray(obj[el])) {
      // Concatenate the new parent if exist
      const p = parent ? parent + '.' + escaped : parent;
      // Push intermediate parent key if flag is true
      if (intermediate) stack.push(parent ? p : escaped);
      deepKeys(obj[el], stack, p || escaped, intermediate);
    } else {
      // Create and save the key
      const key = parent ? parent + '.' + escaped : escaped;
      stack.push(key);
    }
  });
  return stack;
}

export function groupMultiple(array: any[], bys: string[]) {
  const groups = {};
  const f = x =>
    bys.reduce((acc, c) => {
      acc.push(x[c]);
      return acc;
    }, []);
  array.forEach(o => {
    const keys = f(o);
    const group = JSON.stringify(keys);
    groups[group] = groups[group] || [];
    groups[group].push({
      ...o,
      by: last(bys),
      group: keys.join('|'),
    });
  });
  return Object.keys(groups).map(group => {
    return groups[group];
  });
}

export function flattenObject(ob, prefix: string = null, result: any = null, flattenArray = false) {
  result = result || {};

  // Preserve empty objects and arrays, they are lost otherwise
  if (typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }

  prefix = prefix ? prefix + '|' : '';

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      if (typeof ob[i] === 'object' && ob[i] !== null) {
        // Recursion on deeper objects
        flattenObject(ob[i], prefix + i, result);
      } else {
        result[prefix + i] = ob[i];
      }
    }
  }
  return result;
}

export function sortObject<TValue>(
  obj: {
    [key: string]: TValue;
  },
  valSelector: (val: TValue) => number | string
) {
  const sortedEntries = Object.entries(obj).sort((a, b) =>
    valSelector(a[1]) > valSelector(b[1]) ? 1 : valSelector(a[1]) < valSelector(b[1]) ? -1 : 0
  );
  return sortedEntries;
}
// export function unflattenObject(ob) {
//   const result = {};
//   for (const i in ob) {
//     if (Object.prototype.hasOwnProperty.call(ob, i)) {
//       const keys = i.split(/(?<!\.|^)\.(?!\.+|$)/); // Just a complicated regex to only match a single dot in the middle of the string
//       keys.reduce((r, e, j) => {
//         return (
//           r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? ob[i] : {}) : [])
//         );
//       }, result);
//     }
//   }
//   return result;
// }
