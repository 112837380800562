import { Model, IApi, Api, ITree, translator } from '@smart/design';

import { LocationTypes, REPORT_ID } from '../statics';

interface IConfig {
  apiURL: string;
}
interface IIdentifier {
  companyId: string;
  reportId: string;
}

class LocationTypeTreeModel extends Model<ITree, IConfig, IIdentifier> {
  private api: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }
  public idMapper({ companyId, reportId }: IIdentifier) {
    return `${companyId}`;
  }
  public getLocationName(id: string) {
    const d = LocationTypes.find(x => x.id === id);
    if (d) return d.name;
    return id;
  }
  public fromLocationToTree(
    data: API.ILocationTypeHierarchy,
    level: number
  ): ITree<API.ILocationTypeHierarchy> {
    return {
      id: data.id,
      title: data.name,
      children: data.children ? data.children.map(t => this.fromLocationToTree(t, level + 1)) : [],
      iconColor: '#000',
      isHidden: false,
      selected: false,
      level,
      originalData: data,
    };
  }

  public loadCompanyTypes(companyId: string) {
    return this.load({
      companyId,
      reportId: REPORT_ID,
    });
  }
  protected mapObject(obj: API.ILocationTypeHierarchy[]): ITree {
    return {
      id: null,
      isHidden: true,
      title: 'locationType',
      isRoot: true,
      children: obj.map(x => ({
        id: x,
        iconColor: '#000',
        ...this.fromLocationToTree(x, 0),
      })),
    };
  }

  protected async single(id: IIdentifier) {
    const { companyId, reportId } = id;
    const { data } = await this.api.get(
      `dashboard/companies/${companyId}/reports/${reportId}/location-type-hierarchy`
    );

    return {
      data,
      identifier: id,
    };
  }
}
export default new LocationTypeTreeModel('LocationTypeModel');
