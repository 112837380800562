import { Model, IApi, Api, moment, helper, IListItem, ITree } from '@smart/design';
import { REPORT_ID } from '../statics';

interface IConfig {
  apiURL: string;
}

interface IIdentifier {
  companyId: string;
}

class LocationTagModel extends Model<ITree, IConfig, string> {
  private api: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  protected mapObject(obj: Array<{ name: string; id: string }>): ITree {
    return {
      id: 'root',
      isHidden: true,
      title: 'root',
      isRoot: true,
      children: obj.map(x => ({
        id: x.id,
        iconColor: '#000',
        title: x.name,
      })),
    };
  }

  protected async single(id: string) {
    const { data } = await this.api.get(
      `dashboard/companies/${id}/reports/${REPORT_ID}/location-tags`
    );

    return {
      data,
      identifier: id,
    };
  }
}

export default new LocationTagModel('LocationTag');
