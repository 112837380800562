import {
  Api,
  Boot,
  Deferred,
  Error as ErrorDisplay,
  formManager,
  helper,
  Router,
  sessionService,
  SmartAppProvider,
} from '@smart/design';
import {
  BoxSelect,
  Checkbox,
  Chip,
  ColorPicker,
  DatePicker,
  InputField,
  ListField,
  PropertyRule,
  Radio,
  SearchSelect,
  Select,
  SpreadSheet,
  Switch,
  TextArea,
  TimePicker,
  Tree,
  UploadField,
  WysiwygField,
} from '@smart/design/registry';
import { parse } from 'qs';
import React from 'react';
import { render } from 'react-dom';
import ContentLoader from './components/ContentLoader';
import AggregationPropertyModel from './models/AggregationPropertyModel';
import CompanyMapModel from './models/CompanyMapModel';
import CompanyModel from './models/CompanyModel';
import FloorMapModel from './models/FloorMapModel';
import LocationMapModel from './models/LocationMapModel';
import LocationModel from './models/LocationModel';
import LocationTagModel from './models/LocationTagModel';
import LocationTreeModel from './models/LocationTreeModel';
import LocationTypeTreeModel from './models/LocationTypeTreeModel';
import ProfileModel from './models/ProfileModel';
import PropertyDataModel from './models/PropertyDataModel';
import PropertyTypeModel from './models/PropertyTypeModel';
import ReportAccessModel from './models/ReportAccessModel';
import ReportModel from './models/ReportModel';
import SensorTagModel from './models/SensorTagModel';
import CustomTagModel from './models/CustomTagModel';
import RouteConfig from './RouteConfig';
import './styles/base.less';
import NotFound from './views/NotFound';
import Cookies from "universal-cookie";

formManager.registerComponents(
  WysiwygField,
  InputField,
  BoxSelect,
  Checkbox,
  Chip,
  ColorPicker,
  DatePicker,
  ListField,
  PropertyRule,
  Radio,
  SearchSelect,
  Select,
  SpreadSheet,
  Switch,
  TimePicker,
  Tree,
  UploadField,
  TextArea
);

if (module && (module as any).hot) {
  (module as any).hot.accept();
}

function getData(d) {
  if (typeof d === 'string') {
    try {
      d = JSON.parse(d);
    } catch (e) {
      // Got junk in the message, ignore.
      d = {};
    }
  }
  return d;
}

Boot({
  setup: async () => {
    const cookies = new Cookies();
    const { bldng_ai_access_token: at_from_query   } = parse(window.location.search.substring(1));
    let bldng_ai_access_token = cookies.get(`${APP_CONFIG.COOKIE_PREFIX}_access_token`)
    if(!bldng_ai_access_token || bldng_ai_access_token.length === 0){
      bldng_ai_access_token = at_from_query;
    }

    function postMessage(targetWindow, mesg, targetOrigin) {
      targetWindow.postMessage(JSON.stringify(mesg), targetOrigin);
    }

    const initPromise = new Deferred();
    if (window.self === window.top) {
      const t = document.getElementById('admin-client-frame');
      if (t) {
        t.remove();
      }

      const i = document.createElement('iframe');
      i.id = 'admin-client-frame';
      i.src = APP_CONFIG.PORTAL_IFRAME;
      i.style.display = 'none';
      i.style.width = '0px';
      i.style.height = '0px';
      i.style.border = 'none';
      document.body.appendChild(i);
    } else {
      if (bldng_ai_access_token) {
        sessionService.initialize({
          accessToken: bldng_ai_access_token as string//&& atob(bldng_ai_access_token as string)
        });
      } else {
        await handleConnect();
      }
      initPromise.resolve();
    }

    function handleConnect() {
      const def = new Deferred();
      if (window.top === window.self) {
        window.location.href =
          APP_CONFIG.LOGIN_REQUEST_REDIRECT_URL + window.location.href.split('?')[0];
        def.reject();
      } else {
        postMessage(
          window.parent,
          {
            action: 'reconnect',
          },
          '*'
        );
        const onMessage = e => {
          const data = getData(e.data) || {};
          const action = data.action;

          switch (action) {
            case 'connected':
              if (data?.data?.access_token) {
                sessionService.initialize({
                  accessToken: data?.data?.access_token
                });

                def.resolve();
              } else {
                def.reject();
              }

              break;
          }
        };
        setTimeout(() => {
          window.removeEventListener('message', onMessage);

          def.reject();
        }, 1000 * 10);

        window.addEventListener('message', onMessage);
      }

      return def.promise;
    }

    Api.addGlobalResponseInterceptor(async (status, data, error) => {
      if (status === 401) {
        try {
          await handleConnect();
          error.config.headers.Authorization = `Bearer ${sessionService.getAccessToken()}`;
          return error;
        } catch {
          return null;
        }
      }
      return null;
    });

    window.addEventListener('message', e => {
      // tslint:disable-next-line: one-variable-per-declaration
      const source = e.source,
        origin = e.origin,
        data = getData(e.data) || {},
        action = data.action,
        key = data.key,
        value = data.value;
      switch (action) {
        case 'connected':
          if (data?.data?.access_token) {
            sessionService.initialize({
              accessToken: data.data.access_token
            });
            initPromise.resolve();
          }

          break;
        case 'set':
          localStorage.setItem(key, value);
          break;

        case 'ready':
          postMessage(
            source,
            {
              action: 'connect',
            },
            origin
          );

          break;
      }
    });

    ReportModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    CompanyMapModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    LocationMapModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    LocationTreeModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    LocationModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    PropertyDataModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    PropertyTypeModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    LocationTypeTreeModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    FloorMapModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    LocationTagModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    SensorTagModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    CustomTagModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    AggregationPropertyModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    ProfileModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    ReportAccessModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });
    CompanyModel.initialize({
      apiURL: APP_CONFIG.API_URL,
    });

    await initPromise.promise;
  },
  error: e => {
    render(
      <SmartAppProvider>
        <ErrorDisplay error={helper.locateErrorMessage(e)} />
      </SmartAppProvider>,
      document.getElementById('root')
    );
  },
  loading: () => {
    render(
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <ContentLoader width={480} height={100} />
      </div>,
      document.getElementById('root')
    );
  },
  ready: () => {
    render(
      <SmartAppProvider
        theme={{
          container: {
            breakpoints: [375, 576, 768, 992, 1200, 1600],
            screenSizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
            widths: [540, 540, 540, 750, 960, 1140],
            gutterWidth: 20,
            columns: 12,
            defaultScreenSize: 'xl',
          },
          form: {
            fontSize: 12,
          },
          buttons: {
            borderRadius: 3,
          },
          switch: {
            borderRadius: 3,
          },
        }}
      >
        <Router.Switch>
          {RouteConfig.map((r, key) => {
            return (
              <Router.Route
                exact={r.exact}
                strict={r.strict}
                key={key}
                from={r.path}
                to={r.to}
                path={r.path}
                component={r.component}
              />
            );
          })}
          <Router.Route component={NotFound} />
        </Router.Switch>
      </SmartAppProvider>,
      document.getElementById('root')
    );
  },
});
