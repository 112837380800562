import {
  Model,
  IApi,
  Api,
  ITree,
  IPagenationApiResponse,
  moment,
  helper,
  IDataTableFetchResponse,
  translator,
} from '@smart/design';
import { ComponentType } from '../statics';
import { values, isEmpty } from 'lodash';
interface IConfig {
  apiURL: string;
}
interface IIdentifier {
  companyId: string;
  reportId: string;
}
class ReportModel extends Model<MODEL.IReportModel, IConfig, IIdentifier> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  public getId() {
    return '5f60a4b25f15299c05b42724';
  }

  public async listReports(
    companyId: string,
    search?: string
  ): Promise<IDataTableFetchResponse<API.IDashBoard>> {
    const { data } = await this.api.get<IPagenationApiResponse<API.IDashBoard>>(
      `dashboard/companies/${companyId}/reports`
    );

    const rows = data.data.map(x => ({
      ...x,
      companyId,
    }));

    return {
      rows,
      meta: {
        total: data.count,
        totalPage: data.pageCount,
      },
    };
  }
  public idMapper(id: IIdentifier) {
    return `report:${id.companyId}|${id.reportId}`;
  }

  public async setAccess(companyId: string, reportId: string, accessList: string[]) {
    return this.api.put(`dashboard/companies/${companyId}/reports/${reportId}/access`, {
      companyIds: accessList,
      allCompanies: false,
    });
  }
  public async setAccessAll(companyId: string, reportId: string, all: boolean) {
    return this.api.put(`dashboard/companies/${companyId}/reports/${reportId}/access`, {
      allCompanies: all,
      companyIds: [],
    });
  }
  public async create(report: FORM.IReportForm): Promise<string> {
    const postForm: API.IPostReport = this.preparePost(report);
    const result = await this.api.post<API.IReport>(
      `dashboard/companies/${report.companyId}/reports`,
      postForm
    );

    return result.data.reportId;
  }
  public async update(report: FORM.IReportForm): Promise<string> {
    const form = this.preparePost(report);
    const result = await this.api.put(
      `dashboard/companies/${report.companyId}/reports/${report.reportId}`,
      form
    );
    this.removeObject({
      companyId: report.companyId,
      reportId: report.reportId,
    });
    return report.reportId;
  }
  public async delete(companyId: string, reportId: string) {
    const result = await this.api.delete(`dashboard/companies/${companyId}/reports/${reportId}`);
  }
  public extractFilter<F extends Partial<API.IDefaultFilter>>(form: F): API.IDefaultFilter {
    const extracted = {
      endDate: form.endDate,
      endTime: form.endTime,
      includeHolidays: form.includeHolidays,
      includeWeekends: form.includeWeekends,
      locations: form.locations,
      types: form.types,
      startDate: form.startDate,
      startTime: form.startTime,
      locationtags: form.locationtags,
      sensortags: form.sensortags,
      customTags: form.customTags,
      dateRange: form.dateRange,
    };
    return extracted;
  }
  public toForm(report: MODEL.IReportModel): FORM.IReportForm {
    const columns = report?.layout?.columns || 12;
    const rowHeight = 50;
    const width = report?.layout?.width || 1080;

    const componentsForms: FORM.ComponentForms =
      report?.components?.reduce((acc, x, index) => {
        const type = x.graphComponent
          ? ComponentType.GRAPH
          : x.textComponent
          ? ComponentType.TEXT
          : x.imageComponent
          ? ComponentType.IMAGE
          : x.mapComponent
          ? ComponentType.MAP
          : x.clockComponent
          ? ComponentType.CLOCK
          : x.labelComponent
          ? ComponentType.LABEL
          : null;

        const id = x.id;
        switch (type) {
          case ComponentType.GRAPH:
            acc[id] = {
              graphID: x.graphComponent.graphID,
              id,
              property: x.graphComponent.property,
              type: ComponentType.GRAPH,
              graphType: x.graphComponent.graphType,
              aggregation: x.graphComponent.aggregation,
              overrideFilter: x.graphComponent.overrideFilter,
              graphTitle: x.graphComponent.graphTitle,
              xLabel: x.graphComponent.xLabel,
              yLabel: x.graphComponent.yLabel,
              legendLabel: x.graphComponent.legendLabel,
              showDataLabels: x.graphComponent.showDataLabels,
              showNullValuedLabels: x.graphComponent.showNullValuedLabels,
              attributes: x.graphComponent.attributes,
              ...x.graphComponent,
            } as FORM.IGraphComponentForm;
            break;
          case ComponentType.TEXT:
            acc[id] = {
              html: x.textComponent.html,
              text: x.textComponent.text,
              attributes: x.textComponent.attributes,

              id,
              type: ComponentType.TEXT,
            } as FORM.ITextComponentForm;
            break;
          case ComponentType.IMAGE:
            acc[id] = {
              url: x.imageComponent.src,
              base64: x.imageComponent.base64,
              width: x.imageComponent.width,
              height: x.imageComponent.height,
              alt: x.imageComponent.alt,
              attributes: x.imageComponent.attributes,

              id,
              type: ComponentType.IMAGE,
            } as FORM.IImageComponentForm;
            break;
          case ComponentType.MAP:
            acc[id] = {
              property: x.mapComponent.property,
              mapType: x.mapComponent.mapType,
              attributes: x.mapComponent.attributes,

              ranges: x.mapComponent.ranges
                ? x.mapComponent.ranges.map(c => ({
                    color: c.color,
                    max: parseFloat(String(c.max)),
                    min: parseFloat(String(c.min)),
                    name: c.name,
                  }))
                : [],
              aggregation: x.mapComponent.aggregation,
              overrideFilter: x.mapComponent.overrideFilter,
              id,
              type: ComponentType.MAP,
            } as FORM.IMapComponentForm;
            break;
          case ComponentType.LABEL:
            acc[id] = {
              attributes: x.labelComponent.attributes,

              label: x.labelComponent.label,
              property: x.labelComponent.property,
              description: x.labelComponent.description,
              icon: x.labelComponent.icon,
              ranges: x.labelComponent.ranges
                ? x.labelComponent.ranges.map(c => ({
                    color: c.color,
                    max: parseFloat(String(c.max)),
                    min: parseFloat(String(c.min)),
                    name: c.name,
                  }))
                : [],
              aggregation: x.labelComponent.aggregation,
              overrideFilter: x.labelComponent.overrideFilter,
              displayMode: x.labelComponent.displayMode,
              id,
              type: ComponentType.LABEL,
            } as FORM.ILabelComponentForm;
            break;
          case ComponentType.CLOCK:
            acc[id] = {
              id,
              type: ComponentType.CLOCK,
              ...x.clockComponent,
            } as FORM.IClockComponentForm;
            break;
        }

        return acc;
      }, {}) || {};

    const layouts: FORM.Layouts = {};
    let maxY = 0;
    report?.layout?.grid.forEach(x => {
      layouts[x.i] = {
        h: x.h,
        i: x.i,
        w: x.w,
        x: x.x,
        y: x.y,
      };
      const yh = x.y + x.h;
      maxY = maxY === 0 ? 1 : yh > maxY ? yh : maxY;
    });
    const w = columns / 2;
    const h = columns / 2;
    new Array(columns).fill(null).forEach((n, index) => {
      const id = helper.uuid.v4();

      const mod = index % 2;

      layouts[id] = {
        i: id,
        h,
        w,
        x: mod * w,
        y: maxY,
      };
      maxY = maxY + mod * h;
    });
    const { startDate, endDate } = this.fromDateRangeToStartAndEndDate(
      report?.defaultFilter.dateRange,
      {
        startDate:
          report?.defaultFilter.startDate ||
          moment()
            .subtract(30, 'days')
            .toISOString(),
        endDate: report?.defaultFilter.endDate || moment().toISOString(),
      }
    );
    return {
      title: report?.title,
      description: report?.description,
      dateRange: report?.defaultFilter.dateRange,
      startDate,
      endDate,
      timezone: report?.timezone || null,
      hideGlobalFilter: report?.hideGlobalFilter || false,
      includeHolidays: report?.defaultFilter.includeHolidays || false,
      includeWeekends: report?.defaultFilter.includeWeekends || false,
      startTime: report?.defaultFilter.startTime || '08:00:00',
      endTime: report?.defaultFilter.endTime || '16:00:00',
      layoutColumns: report?.layout?.columns || columns,
      layoutWidth: report?.layout?.width || width,
      locations: report?.defaultFilter.locations || [],
      types: report?.defaultFilter.types || [],
      componentsForms,
      layouts,
      companyId: report?.companyId,
      reportId: report?.id,
      locationtags: report?.defaultFilter.locationtags,
      sensortags: report?.defaultFilter.sensortags,
      customTags: report?.defaultFilter.customTags,
      refreshInterval: report?.refreshInterval || 'none',
    };
  }
  public getDateRanges() {
    return [
      {
        label: translator.t('date.today'),
        value: 'today',
      },

      {
        label: translator.t('date.last15min'),
        value: 'last15Min',
      },
      {
        label: translator.t('date.last30min'),
        value: 'last30Min',
      },
      {
        label: translator.t('date.last45min'),
        value: 'last45Min',
      },
      {
        label: translator.t('date.lastHour'),
        value: 'lastHour',
      },
      {
        label: translator.t('date.last6Hour'),
        value: 'last6Hour',
      },
      {
        label: translator.t('date.last12Hour'),
        value: 'last12Hour',
      },
      {
        label: translator.t('date.last5Days'),
        value: 'last5Days',
      },
      {
        label: translator.t('date.last10Days'),
        value: 'last10Days',
      },
      {
        label: translator.t('date.thisWeek'),
        value: 'thisWeek',
      },
      {
        label: translator.t('date.lastWeek'),
        value: 'lastWeek',
      },
      {
        label: translator.t('date.last2Weeks'),
        value: 'last2Weeks',
      },
      {
        label: translator.t('date.last4Weeks'),
        value: 'last4Weeks',
      },
      {
        label: translator.t('date.last6Weeks'),
        value: 'last6Weeks',
      },

      {
        label: translator.t('date.thisMonth'),
        value: 'thisMonth',
      },
      {
        label: translator.t('date.lastMonth'),
        value: 'lastMonth',
      },
      {
        label: translator.t('date.last2Month'),
        value: 'last2Month',
      },
      {
        label: translator.t('date.last3Month'),
        value: 'last3Month',
      },
      {
        label: translator.t('date.last6Month'),
        value: 'last6Month',
      },
    ];
  }

  public fromDateRangeToStartAndEndDate(
    range: string,
    defaultDate?: Partial<
      Pick<API.IDefaultFilter, 'startDate' | 'endDate' | 'startTime' | 'endTime'>
    >
  ) {
    
    let startDate = defaultDate ? defaultDate.startDate : undefined;
    let endDate = defaultDate ? defaultDate.endDate : undefined;
    let startTime = defaultDate ? defaultDate.startTime : undefined;
    let endTime = defaultDate ? defaultDate.endTime : undefined;

    switch (range) {
      case 'last15Min':
        startDate = moment().format();
        startTime = moment()
          .subtract(15, 'minute')
          .startOf('minute')
          .format('HH:mm:ss');

        endDate = undefined;
        endTime = undefined;

        break;
      case 'last30Min':
        startDate = moment().format();
        startTime = moment()
          .subtract(30, 'minute')
          .startOf('minute')
          .format('HH:mm:ss');

        endDate = undefined;
        endTime = undefined;

        break;
      case 'last45Min':
        startDate = moment().format();
        startTime = moment()
          .subtract(45, 'minute')
          .startOf('minute')
          .format('HH:mm:ss');

        endDate = undefined;
        endTime = undefined;

        break;
      case 'lastHour':
        startDate = moment().format();
        startTime = moment()
          .subtract(60, 'minute')
          .startOf('minute')
          .format('HH:mm:ss');

        endDate = undefined;
        endTime = undefined;

        break;
      case 'last6Hour':
        startDate = moment().format();
        startTime = moment()
          .subtract(6, 'hour')
          .startOf('hour')
          .format('HH:mm:ss');


        endDate = undefined;
        endTime = undefined;

        break;
      case 'last12Hour':
        startDate = moment().format();
        startTime = moment()
          .subtract(12, 'hour')
          .startOf('hour')
          .format('HH:mm:ss');

        endDate = undefined;
        endTime = undefined;

        break;
      case 'today':
        startDate = moment()
          .startOf('day')
          .format();

        endDate = undefined;
        
        break;
      case 'last5Days':
        startDate = moment()
          .subtract(5, 'days')
          .startOf('day')
          .format();

        endDate = undefined;

        break;
      case 'last10Days':
        startDate = moment()
          .subtract(10, 'days')
          .startOf('day')
          .format();

        endDate = undefined;

        break;
      case 'last4Weeks':
        startDate = moment()
          .subtract(4, 'weeks')
          .startOf('day')
          .format();

        endDate = undefined;

        break;
      case 'last2Weeks':
        startDate = moment()
          .subtract(2, 'weeks')
          .startOf('day')
          .format();

        endDate = moment().format();

        break;
      case 'last6Weeks':
        startDate = moment()
          .subtract(6, 'weeks')
          .startOf('day')
          .format();

        endDate = undefined;

        break;
      case 'lastMonth':
        startDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .format();

        endDate = undefined;

        break;
      case 'last2Month':
        startDate = moment()
          .subtract(2, 'month')
          .startOf('month')
          .format();

        endDate = undefined;

        break;
      case 'last3Month':
        startDate = moment()
          .subtract(3, 'month')
          .startOf('month')
          .format();

        endDate = undefined;

        break;
      case 'last6Month':
        startDate = moment()
          .subtract(6, 'month')
          .startOf('month')
          .format();

        endDate = moment()
          .startOf('month')
          .format();

        break;
      case 'thisMonth':
        startDate = moment()
          .startOf('month')
          .format();

        endDate = undefined;

        break;
      case 'lastWeek':
        startDate = moment()
          .subtract(1, 'week')
          .startOf('week')
          .format();

        endDate = undefined;

        break;
      case 'thisWeek':
        startDate = moment()
          .startOf('week')
          .format();

        endDate = undefined;

        break;
    }

    startDate = startDate && moment(startDate).format('YYYY-MM-DD');
    endDate = endDate && moment(endDate).format('YYYY-MM-DD');

    return {
      startDate,
      endDate,
      startTime,
      endTime,
    };
  }

  protected mapObject(
    obj: {
      reportData: API.IReport;
    },
    id: IIdentifier
  ): MODEL.IReportModel {
    const {
      defaultFilter,
      components,
      title,
      description,
      layout,
      timezone,
      hideGlobalFilter,
      refreshInterval,
    } = obj.reportData;
    const { companyId, reportId } = id;

    return {
      title,
      description,
      id: reportId,
      companyId,
      components,
      layout,
      defaultFilter,
      timezone,
      hideGlobalFilter,
      refreshInterval,
    };
  }

  protected async single(id: IIdentifier) {
    const { companyId, reportId } = id;

    const { data: reportData } = await this.api.get<API.IReport>(
      `dashboard/companies/${companyId}/reports/${reportId}`
    );

    return {
      data: {
        reportData,
      },
      identifier: id,
    };
  }
  private preparePost(report: FORM.IReportForm) {
    const components: API.IComponent[] = [];
    values(report.componentsForms).forEach(form => {
      switch (form.type) {
        case ComponentType.TEXT:
          components.push({
            id: form.id,
            textComponent: {
              text: null,
              html: form.html,
              attributes: form.attributes,
            },
          });
          break;
        case ComponentType.GRAPH:
          components.push({
            id: form.id,
            graphComponent: {
              property: form.property,
              graphTitle: form.graphTitle,
              graphType: form.graphType,
              xLabel: form.xLabel,
              yLabel: form.yLabel,
              legendLabel: form.legendLabel,
              aggregation: form.aggregation && form.aggregation.agg1 && form.aggregation,
              overrideFilter: isEmpty(form.overrideFilter) ? undefined : form.overrideFilter,
              showDataLabels: form.showDataLabels,
              showNullValuedLabels: form.showNullValuedLabels,

              attributes: form.attributes,
            },
          });
          break;
        case ComponentType.IMAGE:
          components.push({
            id: form.id,
            imageComponent: {
              base64: form.base64,
              src: form.base64,
              alt: form.alt,
              attributes: form.attributes,
            },
          });
          break;
        case ComponentType.LABEL:
          components.push({
            id: form.id,
            labelComponent: {
              aggregation: form.aggregation,
              ranges: form.ranges,
              displayMode: form.displayMode,
              overrideFilter: isEmpty(form.overrideFilter) ? undefined : form.overrideFilter,
              label: form.label,
              icon: form.icon,
              description: form.description,
              property: form.property,
              attributes: form.attributes,
            },
          });
          break;
        case ComponentType.MAP:
          components.push({
            id: form.id,
            mapComponent: {
              aggregation: form.aggregation,
              mapType: form.mapType,
              property: form.property,
              ranges: form.ranges,
              overrideFilter: isEmpty(form.overrideFilter) ? undefined : form.overrideFilter,
              attributes: form.attributes,
            },
          });
          break;
        case ComponentType.CLOCK:
          components.push({
            id: form.id,
            clockComponent: {
              hour: form.hour,
              refreshInterval: form.refreshInterval,
              backgroundColor: form.backgroundColor,
              textColor: form.textColor,
            },
          });
          break;
      }
    });
    const gridLayout = values(report.layouts).filter(x => report.componentsForms[x.i]);
    const hourlyRange = report.dateRange
      ? ['hour', 'min'].some(f => report.dateRange.toLowerCase().includes(f))
      : false;

    const postForm: API.IPostReport = {
      components,
      defaultFilter: {
        startDate: report.dateRange || hourlyRange ? undefined : report.startDate,
        endDate: report.dateRange || hourlyRange ? undefined : report.endDate,
        startTime: hourlyRange ? undefined : report.startTime,
        endTime: hourlyRange ? undefined : report.endTime,
        includeHolidays: report.includeHolidays,
        includeWeekends: report.includeWeekends,
        locations: report.locations,
        types: report.types,
        locationtags: report.locationtags,
        sensortags: report.sensortags,
        customTags: report.customTags,
        dateRange: report.dateRange,
      },
      hideGlobalFilter: report.hideGlobalFilter,
      timezone: report.timezone,
      refreshInterval: report.refreshInterval,
      layout: {
        columns: report.layoutColumns,
        width: report.layoutWidth,
        grid: gridLayout.map(u => {
          return {
            i: u.i,
            x: u.x,
            h: u.h,
            w: u.w,
            y: u.y,
          };
        }),
      },
      title: report.title,
      description: report.description,
    };
    return postForm;
  }
}
export default new ReportModel('ReportModel');
