import React, { useContext } from 'react';
export interface IBuilderContext {
  companyId: string;
  reportId: string;
  form: FORM.IReportForm;
  filters: API.IDefaultFilter;
  viewOnly: boolean;
  isNew: boolean;
  pendingChanges: boolean;

  cached: boolean;
  onFormChange: (form: Partial<FORM.IReportForm>) => void;
  setFormComponent: (id: string, form: Partial<FORM.IComponentFormType>) => void;
  getFormComponent: (id: string) => FORM.IComponentFormType;
  getLayout: (id: string) => ReactGridLayout.Layout;

  setLayout: (id: string, layout: ReactGridLayout.Layout) => void;

  deleteFormComponent: (id: string) => void;

  onFilterChange: (filter: Partial<API.IDefaultFilter>) => void;
  syncRemote: () => Promise<string>;
  applyCurrentCached: (apply: boolean) => void;
}
const BuilderContext = React.createContext<IBuilderContext>(null);
export const BuilderProvider = BuilderContext.Provider;

export function useBuilder(): IBuilderContext {
  return useContext(BuilderContext);
}
