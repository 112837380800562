import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react';
import {
  SmartForm as SF,
  translator,
  BStyle,
  Center,
  Actions,
  BS,
  Button,
  BType,
  Badge,
  Api,
  useDidUpdate,
  Static,
  BadgeStyle,
  Seperator,
  useThemeContext,
} from '@smart/design';
import AggregateForm from './AggregateForm';
import AggregateUsageForm from './AggregateUsageForm';
import { cloneDeep, clone, last, uniqueId, difference, uniq } from 'lodash';
import { aggregationArrayToObject, isValidAggregation } from '../common';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { reorder, Aggregate, addAggregate, handleTypes } from './common';

interface IProps {
  aggregation: Aggregate[];
  newAggregationDir?: 'append' | 'prepend';
  usages: FORM.IAggregationUsage[];
  type?: FORM.AggregationUseType;
  useMeta?: boolean;
  maxLevel?: number;
  isValid?: (valid: boolean) => boolean;
  useSort?: boolean;
  property?: string;
  onApply: (aggregation: Aggregate[], usages: FORM.IAggregationUsage[]) => void;
}
const AggregationForm = (props: IProps) => {
  const { useMeta = true, maxLevel = 3, useSort = true, newAggregationDir = 'append' } = props;

  const [aggregation, setAggregation] = useState<Aggregate[]>([]);
  useEffect(() => {
    setAggregation(
      (props.aggregation || []).map(d => {
        d.renderKey = uniqueId('renderKey');
        return d;
      })
    );
  }, []);
  const [aggregateUsage, setAggregateUsage] = useState<FORM.IAggregationUsage[]>(props.usages);

  const newAggregate = useCallback(() => {
    const aggs = addAggregate(newAggregationDir, props.type, aggregation);
    setAggregation(aggs);
  }, [aggregation]);

  const orderChange = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const aggregations = reorder(aggregation, result.source.index, result.destination.index);

      aggregations.forEach((aggr, index, array) => {
        const aggAgs = aggr.aggregate.map(x => x.as);
        const nexts = array.slice(index, array.length);
        const lasts = array.slice(0, index);

        const nextBys = nexts.map(x => x.by).flat();

        const nextGates = nexts.map(x => x.aggregate.map(v => v.as).flat()).flat();

        nextBys.forEach(x => {
          if (!aggr.by.includes(x)) {
            aggr.by.push(x);
          }
        });
        nextGates.forEach(x => {
          aggr.by = aggr.by.filter(f => x !== f);
        });
      });
      setAggregation(Array.from(aggregations));
    },
    [aggregation]
  );
  const forms = useMemo(() => {
    return (
      <Static strong underline label={translator.t('aggregation.groups')}>
        <DragDropContext nonce={'sdo'} onDragEnd={orderChange}>
          <Droppable droppableId={'aggregation'} type={'aggregation'}>
            {(provided, snapshot) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {aggregation.map((aggr, index, array) => {
                    const { renderKey } = aggr;
                    return (
                      <Draggable key={renderKey} draggableId={renderKey} index={index}>
                        {(dragableProvided, dragableSnapshot) => {
                          return (
                            <div
                              draggable={false}
                              {...dragableProvided.dragHandleProps}
                              {...dragableProvided.draggableProps}
                              ref={dragableProvided.innerRef}
                            >
                              <AggregateForm
                                type={props.type}
                                aggregation={aggr}
                                previousAggregation={array[index - 1]}
                                nextAggregation={array[index + 1]}
                                index={index}
                                useSort={useSort}
                                aggregations={array}
                                newAggregate={
                                  index === array.length - 1 && array.length < 3
                                    ? newAggregate
                                    : null
                                }
                                onRemove={() => {
                                  aggregation.splice(index, 1);
                                  setAggregation(handleTypes(props.type, aggregation));
                                }}
                                onChange={f => {
                                  aggregation.splice(index, 1, {
                                    ...f,
                                    renderKey,
                                  });

                                  setAggregation([...aggregation]);
                                  const lastAggregation = last(aggregation);

                                  const propeties = [
                                    ...lastAggregation.by,
                                    ...lastAggregation.aggregate.map(x => x.as),
                                  ];
                                  const filter = aggregateUsage.filter(x =>
                                    propeties.includes(x.property)
                                  );

                                  setAggregateUsage(filter);
                                }}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Static>
    );
  }, [aggregation, props.type, newAggregate, orderChange]);

  const metaForm = useMemo(() => {
    if (!useMeta) return null;
    if (!aggregation.length) return null;
    return (
      <Static strong underline label={translator.t('aggregation.meta')}>
        <AggregateUsageForm
          onChange={f => {
            setAggregateUsage(f);
          }}
          property={props.property}
          type={props.type}
          index={aggregation.length - 1}
          usages={aggregateUsage}
          aggregation={last(aggregation)}
        />
      </Static>
    );
  }, [aggregation, props.type, props.property]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {forms}
      {metaForm}
      <Actions.Container
        style={{ position: 'sticky', bottom: 0, height: 60, backgroundColor: 'inherit' }}
      >
        <Actions.Right>
          {useMemo(() => {
            return (
              <Button
                disabled={
                  !isValidAggregation(aggregationArrayToObject(aggregation, aggregateUsage))
                }
                onClick={() => {
                  props.onApply(aggregation, aggregateUsage);
                }}
                icon="icon-check"
                bStyle={BStyle.SUCCESS}
                iconPlacement="right"
                label={translator.t('btn.applyAggregation')}
              />
            );
          }, [aggregation, aggregateUsage])}
        </Actions.Right>
      </Actions.Container>
    </div>
  );
};

export default AggregationForm;
