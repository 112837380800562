import {
  BS,
  BType,
  Center,
  Col,
  Icon,
  IconStyle,
  IFormField,
  ModelSubscription,
  OptionTrigger,
  Row,
  Seperator,
  SmartForm as SF,
  Static,
  styled,
  translator,
  useDidUpdate,
  useThemeContext,
} from '@smart/design';
import { first, get, isObject } from 'lodash';
import AggregationPropertyModel from '../../../../models/AggregationPropertyModel';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import { useBuilder } from '../../BuilderContext';
import chartService from '../../../../services/chartService';

const Property = styled.div<{
  isDragging: boolean;
  type: 'aggregates' | 'groups';
}>`
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.07);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-left-color: ${({ theme, type }) =>
    type === 'aggregates' ? theme.colors.success : theme.colors.warning};
  border-left-style: solid;
  border-left-width: 5px;
  position: relative;
  background-color: ${({ isDragging, theme }) => isDragging && theme.colors.primary};
  color: ${({ isDragging, theme }) => isDragging && '#fff'};
`;

const ListItem = styled.div`
  cursor: pointer;
  padding: 5px;
  height: 25px;
  margin-bottom: 3px;
  border: 1px solid ${({ theme }) => theme.colors.light};
`;

interface IProps {
  aggregation: FORM.IIPipelineAggregation;
  index: number;
  usages?: API.IAggregationMetaObject[];
  type: FORM.AggregationUseType;
  property: string;
  onChange: (type: API.IAggregationMetaObject[]) => void;
}

interface IForm {
  use?: 'xAxis' | 'value' | 'series' | 'ignore';
  color?: string;
  property?: string;
  stackedChartType?: 'bar' | 'line' | 'area' | string;
  valuePostProcess?: Array<{
    type: 'date' | 'number' | 'string';
    value: string;
  }>;
  stacked?: boolean;
  order?: number;
}
export const AggregateUsageForm = ({
  type,
  onChange,
  aggregation,
  usages = [],
  property,
}: IProps) => {
  const { companyId, reportId } = useBuilder();
  const [groups, setGroups] = useState<API.IAggregationMetaObject[]>([]);
  const [aggregates, setAggregates] = useState<API.IAggregationMetaObject[]>([]);

  const theme = useThemeContext();
  const getUsage = useCallback(
    (use: string, order: number, uses: API.IAggregationMetaObject[] = []) => {
      const found = uses.find(y => y.property === use);

      return {
        property: use,
        color: found ? found.color : '#ABB8C3',
        order: order || 0,
        use: found ? found.use : 'ignore',
        stackedChartType: found ? found.stackedChartType : undefined,
        stacked: found ? found.stacked : undefined,
        valuePostProcess: found ? found.valuePostProcess : undefined,
      };
    },
    []
  );

  useEffect(() => {
    if (!aggregation) return;
    setGroups(aggregation.by.map((x, i) => getUsage(x, i, usages)));
    setAggregates(aggregation.aggregate.map((x, i) => getUsage(x.as, i, usages)));
  }, []);

  useDidUpdate(() => {
    if (!aggregation) return;
    setGroups(aggregation.by.map((x, i) => getUsage(x, i, groups)));
    setAggregates(aggregation.aggregate.map((x, i) => getUsage(x.as, i, aggregates)));
  }, [aggregation]);
  useDidUpdate(() => {
    [...groups, ...aggregates].every(x => x.property && x.use) &&
      onChange([...groups, ...aggregates]);
  }, [groups, aggregates]);

  const reOrder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      if (result.source.droppableId === 'groups') {
        const items = reOrder(groups, result.source.index, result.destination.index);
        setGroups(items);
      } else if (result.source.droppableId === 'aggregates') {
        const items = reOrder(aggregates, result.source.index, result.destination.index);
        setAggregates(items);
      }
    },
    [groups, aggregates]
  );

  const render = useCallback(
    (t: 'groups' | 'aggregates') => {
      let items: API.IAggregationMetaObject[] = [];
      let change = (a: API.IAggregationMetaObject[]) => {
        // noop
      };
      switch (t) {
        case 'groups':
          items = groups;
          change = setGroups;
          break;
        case 'aggregates':
          items = aggregates;
          change = setAggregates;
          break;
      }

      return (
        <Droppable droppableId={t} type={t}>
          {(provided, snapshot) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items
                  .filter(l => chartService.getUsage(type, l.property).length)
                  .map((l, index) => {
                    return (
                      <Draggable key={l.property} draggableId={l.property} index={index}>
                        {(dragableProvided, dragableSnapshot) => {
                          return (
                            <div
                              {...dragableProvided.draggableProps}
                              ref={dragableProvided.innerRef}
                            >
                              <Property type={t} isDragging={dragableSnapshot.isDragging}>
                                <div
                                  {...dragableProvided.dragHandleProps}
                                  style={{
                                    position: 'absolute',
                                    top: 'calc(50% - 25px)',
                                    left: -20,
                                    width: 18,
                                    height: 50,
                                    color: '#fff',
                                    backgroundColor:
                                      t === 'aggregates'
                                        ? theme.colors.success
                                        : theme.colors.warning,
                                    borderRadius: 5,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  {index + 1}
                                </div>
                                <div {...dragableProvided.dragHandleProps}>
                                  <Static
                                    strong={true}
                                    center={true}
                                    underline={true}
                                    noMargin={true}
                                    label={l.property}
                                  ></Static>
                                </div>

                                <Seperator />
                                <SF.Form<IForm>
                                  noSubmit={true}
                                  onChange={(f, forms, wasChanged, lastValue) => {
                                    items.splice(index, 1, {
                                      ...f,
                                      valuePostProcess: first(f.valuePostProcess),
                                    });
                                    change([...items]);
                                  }}
                                  form={[
                                    {
                                      label: translator.t('label.property'),
                                      required: () => true,
                                      type: 'input',
                                      property: 'property',
                                      value: l.property,
                                      visible: () => false,
                                      boxSize: 50,
                                    } as IFormField<IForm, 'property'>,
                                    {
                                      label: translator.t('label.use'),
                                      required: () => true,
                                      type: 'boxSelect',
                                      property: 'use',
                                      boxSize: 30,
                                      value: l.use,
                                      options: chartService.getUsage(type, l.property),
                                      optionRender: f => {
                                        return (
                                          <Center style={f.style} full={true}>
                                            {f.label}
                                          </Center>
                                        );
                                      },
                                    } as IFormField<IForm, 'use'>,
                                    {
                                      label: f => {
                                        if (f.use === 'series')
                                          return translator.t('label.backgroundColor');
                                        else if (f.use === 'value') {
                                          return translator.t('label.borderColor');
                                        }
                                        return null;
                                      },
                                      type: 'color',
                                      property: 'color',

                                      value: l.color,
                                      required: () => true,
                                      visible: f =>
                                        type !== 'label' &&
                                        type !== 'map' &&
                                        (f.use === 'series' || f.use === 'value'),
                                      picker: 'twitter',
                                      mode: 'trigger',
                                    } as IFormField<IForm, 'color'>,

                                    {
                                      label: translator.t('label.stackedChartType'),
                                      type: 'boxSelect',
                                      required: () => true,
                                      boxSize: 30,
                                      value: l.stackedChartType,
                                      visible: f =>
                                        f.use === 'value' &&
                                        aggregation.aggregate.length > 1 &&
                                        ['bar', 'line'].includes(type) &&
                                        aggregation.aggregate.findIndex(x => x.as === f.property) >
                                          0,
                                      property: 'stackedChartType',
                                      options: chartService.getStackedChartTypes(),
                                      optionRender: f => {
                                        return (
                                          <Center full={true}>
                                            <Icon icon={f.icon} iStyle={IconStyle.PRIMARY} />
                                          </Center>
                                        );
                                      },
                                    } as IFormField<IForm, 'stackedChartType'>,

                                    {
                                      label: translator.t('label.valuePostProcess'),
                                      property: 'valuePostProcess',
                                      type: 'spreadsheet',

                                      value:
                                        l.valuePostProcess && isObject(l.valuePostProcess)
                                          ? [l.valuePostProcess]
                                          : [],
                                      single: true,
                                      beforeRowChange: (changeRow, currentRow) => {
                                        if (changeRow?.type !== currentRow?.type) {
                                          changeRow.value = '';
                                        }

                                        return changeRow;
                                      },
                                      rowAction: (row, setRowData) => {
                                        if (!row || !row.type) return null;

                                        return (
                                          <OptionTrigger
                                            placement="left"
                                            actionButton={{
                                              icon: 'icon-bars',
                                              bType: BType.ICON,
                                            }}
                                            height={250}
                                            width={225}
                                            render={close => {
                                              return (
                                                <ModelSubscription
                                                  model={AggregationPropertyModel}
                                                  render={modelData => {
                                                    const values = get(
                                                      modelData.modelData.$data.postprocessors,
                                                      row.type,
                                                      []
                                                    );
                                                    if (!values.length) {
                                                      return (
                                                        <Center>
                                                          {translator.t(
                                                            'label.noTemplatesAvailable'
                                                          )}
                                                        </Center>
                                                      );
                                                    }
                                                    return values.map(v => {
                                                      return (
                                                        <ListItem
                                                          key={v}
                                                          onClick={() => {
                                                            row.value = v;

                                                            setRowData({
                                                              ...row,
                                                            });
                                                            close();
                                                          }}
                                                        >
                                                          {v}
                                                        </ListItem>
                                                      );
                                                    });
                                                  }}
                                                  id={{
                                                    reportId,
                                                    companyId,
                                                  }}
                                                ></ModelSubscription>
                                              );
                                            }}
                                          ></OptionTrigger>
                                        );
                                      },
                                      columns: [
                                        {
                                          label: 'type',
                                          form: {
                                            type: 'select',
                                            property: 'type',
                                            options: [
                                              {
                                                label: 'date',
                                                value: 'date',
                                              },
                                              {
                                                label: 'number',
                                                value: 'number',
                                              },
                                              {
                                                label: 'string',
                                                value: 'string',
                                              },
                                              {
                                                label: 'none',
                                                value: 'none',
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          label: 'process',
                                          form: {
                                            type: 'input',
                                            property: 'value',
                                          },
                                        },
                                      ],
                                    } as IFormField<IForm, 'valuePostProcess'>,
                                  ]}
                                >
                                  <Row>
                                    <Col size={6}>
                                      <SF.Field property="use"></SF.Field>
                                    </Col>

                                    <Col size={2}>
                                      <SF.Field property="color"></SF.Field>
                                    </Col>
                                  </Row>
                                  <SF.Field property="valuePostProcess"></SF.Field>

                                  <SF.Field property="stackedChartType"></SF.Field>
                                </SF.Form>
                              </Property>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      );
    },
    [groups, aggregates]
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {render('groups')}
      {render('aggregates')}
    </DragDropContext>
  );
  // );
};
export default AggregateUsageForm;
