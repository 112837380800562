import { uniqueId, uniq, last } from 'lodash';
export type Aggregate = FORM.IIPipelineAggregation & {
  renderKey?: string;
};
export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function addAggregate(
  newAggregationDir: 'append' | 'prepend',
  type: FORM.AggregationUseType,
  list: Aggregate[]
): Aggregate[] {
  switch (newAggregationDir) {
    case 'append':
      const sorts = [...last(list).sorts];

      list.push({
        aggregate: [],

        by: uniq(list.map(x => x.by).flat()),
        sorts,
        renderKey: uniqueId('renderKey'),
      });
      break;
    case 'prepend':
      const rev = list.reverse();
      list.unshift({
        aggregate: uniq(rev.map(x => x.aggregate).flat()),
        renderKey: uniqueId('renderKey'),
        by: list
          .reverse()
          .map(x => x.by)
          .flat(),
        sorts: [],
      });
      break;
  }
  return handleTypes(type, list);
}

export function handleTypes(type: FORM.AggregationUseType, list: Aggregate[]) {
  switch (type) {
    case 'label':
      list.forEach((x, index, ray) => {
        x.renderKey = uniqueId('renderKey');
        if (ray.length === 1) {
          x.by.push('merge');
        } else if (index === ray.length - 1) {
          x.by = ['merge'];
        } else {
          x.by = x.by.filter(f => f !== 'merge');
        }
      });
      break;
    case 'map':
      list.forEach((x, index, ray) => {
        x.renderKey = uniqueId('renderKey');
        if (ray.length === 1) {
          x.by.push('entityId');
        } else if (index === ray.length - 1) {
          x.by = ['entityId'];
        } else {
          x.by = x.by.filter(f => f !== 'entityId');
        }
      });
      break;
  }
  return [...list];
}
