import React from 'react';
interface IProps {
  component: FORM.ITextComponentForm;
}
const TextDisplay = (props: IProps) => {
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: props.component.html,
        }}
      ></div>
    </div>
  );
};

export default TextDisplay;
