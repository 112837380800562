import BaseChart from './BaseChart';
import {
  ILegendItem,
  ICategoryScaleOptions,
  ILinearScaleOptions,
  IChartOptions,
  ICoreChartOptions,
  IChartType,
  IEvent,
  LegendElement,
} from 'chart.js';
import { moment } from '@smart/design';
import { keys, groupBy, get, last, clone, orderBy, uniq, toNumber } from 'lodash';
import { flattenObject, groupMultiple, nestedGroupBy } from './common';
interface IProps {
  component: FORM.IGraphComponentForm;
  dataModel: MODEL.IAggregatedDataModel;
  type: IChartType;
}
abstract class CartesianChart extends BaseChart {
  protected readonly datasetAxisID: string;
  protected readonly datasetAxisIndex: number = 0;
  protected readonly xAxisUseTime: boolean = false;
  protected readonly labels: any[] = [];
  protected readonly datasetLabels = [];
  protected readonly chartType: CHART.ChartType;
  protected readonly dataMap: Map<string, string | number> = new Map();
  constructor(props: IProps) {
    super({
      component: props.component,
      dataModel: props.dataModel,
      type: props.type,
    });
    this.chartType = this.type as CHART.ChartType;
    this.datasetAxisID = last(this.xAxisValues);
    this.datasetAxisIndex = this.xAxisValues.length - 1;
    this.labels = this.getLabels();
    this.datasetLabels = this.labels[this.datasetAxisIndex];
    this.xAxisUseTime = this.hasTimeGroup(this.datasetAxisID);

    const obj = nestedGroupBy(this.dataModel.data, this.xAxisValues.map(this.convertDataKey));
    this.dataMap = new Map(Object.entries(flattenObject(obj)));
  }
  public getConfig(): CHART.ChartConfigurationType {
    return {
      type: this.type as any,
      data: {
        datasets: this.getDataSets(),
        labels: [],
      },
      options: this.getOptions(),
    };
  }

  protected getDataValue(label: string, value: string, index = 0) {
    return this.dataMap.get(`${label}|${index}|${value}`);
  }
  protected getName(label: string, index = 0, name: string = 'name') {
    return this.dataMap.get(`${label}|${index}|${name}`);
  }
  protected abstract getDatasetOptions(stack?: string);
  protected getDataSets() {
    const dataSets: any = [];
    if (!this.hasSeries) {
      this.valueValues.forEach((value, valueIndex) => {
        const datasetOptions = this.getDatasetOptions();
        const dataset = {
          backgroundColor: this.getColor('value', value, valueIndex),
          borderColor: this.getColor('value', value, valueIndex),
          borderWidth: 1,
          ...datasetOptions,
          data: [],
          label: value,
          stack: value,
          legend: value,
          type: this.getMeta(value, 'stackedChartType'),
          xAxisID: this.datasetAxisID,
        };

        dataSets.push(dataset);

        this.datasetLabels.forEach((label, index) => {
          dataset.data.push({
            x: label,
            y: this.getDataValue(label, value),
          });
        });
      });
    } else if (this.groupAsSeries) {
      this.valueValues.forEach((value, valueIndex) => {
        const d = groupMultiple(this.dataModel.data, this.serieValues);

        d.forEach((x, index) => {
          const labelArray = this.serieValues.map(cs => {
            return {
              label: this.getLabelName(cs, get(x[0], cs)),
              serie: cs,
            };
          });

          let datagroup = x;
          if (this.xAxisUseTime) {
            datagroup = orderBy(datagroup, ['datetime'], ['asc']);
          }

          // const keyArray = this.serieValues.map(cs =>  get(x[0], cs));
          const label = labelArray.map(l => l.label).join('|');
          const datasetOptions = this.getDatasetOptions();

          const dataset = {
            backgroundColor: this.getColor('series', labelArray[0].serie, index),
            borderColor: this.getColor('value', value, valueIndex),
            borderWidth: 1,
            ...datasetOptions,
            label,

            data: [],
            legend: label,
            type: this.getMeta(value, 'stackedChartType'),
            xAxisID: this.datasetAxisID,
          };
          if (this.getMeta(value, 'stackedChartType')) {
            dataset.stack = value;
          }
          dataSets.push(dataset);

          datagroup.forEach(dt => {
            dataset.data.push({
              x: get(dt, this.convertDataKey(this.datasetAxisID)),
              y: get(dt, value) as number,
            });
          });
        });
      });
    } else if (this.aggregateAsSeries) {
      this.serieValues.forEach((serie, serieIndex) => {
        const data = [];
        this.labels[this.datasetAxisIndex].forEach(xLabel => {
          const axisData = this.getAxisData(this.datasetAxisID, xLabel);

          axisData.forEach(xData => {
            data.push({
              x: xLabel,
              y: get(xData, serie),
            });
          });
        });
        const datasetOptions = this.getDatasetOptions();

        dataSets.push({
          borderColor: this.getColor('series', serie, serieIndex),
          borderWidth: 1,
          backgroundColor: this.getColor('series', serie, serieIndex),

          ...datasetOptions,
          data,
          legend: serie,
          label: serie,
          xAxisID: this.datasetAxisID,
        });
      });
    }
    return dataSets;
  }

  protected getXAxisDataObject(groupKey: string) {
    const convertedKey = this.convertDataKey(groupKey);
    return groupBy(this.dataModel.data, convertedKey);
  }
  protected getAxisData(groupKey: string, dataKey: string) {
    return get(this.getXAxisDataObject(groupKey), dataKey);
  }
  protected getLabels() {
    return this.xAxisValues.reduce((acc, xValue, index) => {
      let labels = keys(this.getXAxisDataObject(xValue));

      if (this.convertDataKey(xValue) === 'datetime') {
        labels = labels.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      }
      if (acc.length) {
        acc.push(last(acc).flatMap(c => labels.map(v => [c, v].join('|')).flatMap(x => x)));
      } else {
        acc.push(labels);
      }
      return acc;
    }, []);
  }

  protected getScales() {
    const xScales = {};
    if (this.hasX) {
      this.xAxisValues.forEach((key, index) => {
        const labels = this.labels[index];
        const scale = {
          type: 'category',
          labels,
          axis: 'x',
          display: true,
          scaleLabel: {
            display: this.component.xLabel && true,
            labelString: this.component.xLabel,
          },
          weight: this.xAxisValues.length - index,
          ticks: {
            callback: (i: number) => {
              return this.getLabelName(key, labels[i]);
            },
          },
        } as DeepPartial<ICategoryScaleOptions>;
        xScales[key] = clone(scale);
      });
    } else {
      // tslint:disable-next-line
      xScales['x'] = {
        type: 'category',
        labels: [],
        axis: 'x',
        display: true,
        scaleLabel: {
          display: this.component.xLabel && true,
          labelString: this.component.xLabel,
        },
      };
    }

    return {
      ...xScales,
      y: {
        type: 'linear',
        ...this.getYScaleOptions(),
        scaleLabel: {
          display: this.component.yLabel && true,
          labelString: this.component.yLabel,
        },
      } as DeepPartial<ILinearScaleOptions>,
    };
  }

  protected getYScaleOptions(): DeepPartial<ILinearScaleOptions> {
    return {
      beginAtZero: false,
      stacked: false,
    };
  }

  protected formatLabel(groupKey: string, value: string) {
    const v = last(value.split('|'));

    const format = this.getDateFormatter(groupKey);
    if (format === "dayOfWeek") {
      return moment.weekdays(toNumber(v));
    }
    else if (format) {
      const m = moment(v);
      if (m.isValid()) {
        return m.format(this.getDateFormatter(groupKey));
      }
    }
    return v;
  }

  protected getOptions() {
    return {
      maintainAspectRatio: false,
      responsive: true,

      scales: this.getScales(),
      tooltips: {
        callbacks: {
          title: l => {
            return uniq(l.map(o => this.formatLabel(this.datasetAxisID, o.label))).join(' | ');
          },
        },
      },
      title: {
        display: this.component.graphTitle && true,
        text: this.component.graphTitle,
      },
      plugins: {
        datalabels: {
          display: false,
          formatter: x => {
            return x.y;
          },
        },
      },
      layout: {
        padding: {
          top: 15,
          right: 15,
          bottom: 15,
          left: 15,
        },
      },

      legend: {
        title: {
          display: this.component.legendLabel ? true : false,
          text: this.component.legendLabel,
          position: 'center',
        },
        // tslint:disable-next-line: object-literal-shorthand
        onClick: function(e: IEvent, legendItem, legend) {
          const index = legendItem.datasetIndex;
          const ci = (this as any).chart;

          const alreadyHidden =
            ci.getDatasetMeta(index).hidden === null ? false : ci.getDatasetMeta(index).hidden;
          if (e.native instanceof MouseEvent && e.native.shiftKey) {
            const meta = ci.getDatasetMeta(index);
            meta.hidden = !meta.hidden;
            ci.update();
            return;
          }

          ci.data.datasets.forEach((e2, i) => {
            const meta = ci.getDatasetMeta(i);

            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
              } else if (meta.hidden === null) {
                meta.hidden = true;
              }
            } else if (i === index) {
              meta.hidden = null;
            }
          });

          ci.update();
        },
        labels: {
          generateLabels: chart => {
            const datasets = chart.data.datasets;
            const options = chart.options.legend || {};
            const usePointStyle = options.labels && options.labels.usePointStyle;
            const sortedMetas = chart._getSortedDatasetMetas();

            const items = sortedMetas.map(meta => {
              const style = meta.controller.getStyle(usePointStyle ? 0 : undefined);

              return {
                text: datasets[meta.index].legend,
                fillStyle: style.backgroundColor,
                hidden: !meta.visible,
                lineCap: style.borderCapStyle,
                lineDash: style.borderDash,
                lineDashOffset: style.borderDashOffset,
                lineJoin: style.borderJoinStyle,
                lineWidth: style.borderWidth,
                strokeStyle: style.borderColor,
                pointStyle: style.pointStyle,
                rotation: style.rotation,
                datasetIndex: meta.index,
              } as ILegendItem;
            });
            return orderBy(items, 'text');
          },
        },
      },
    } as IChartOptions<'bar'> | IChartOptions<'line'>;
  }
}

export default CartesianChart;
