import React from 'react';
import { Center, IconSize, IconStyle, translator, useThemeContext } from '@smart/design';

const NoData = () => {
  return (
    <Center icon="icon-chart-area" iconSize={IconSize.LARGE} iconStyle={IconStyle.MUTED}>
      {translator.t('label.noDataToShow')}
    </Center>
  );
};

export default NoData;
