import { Model, IApi, Api, ITree } from '@smart/design';
import { REPORT_ID } from '../statics';
interface IConfig {
  apiURL: string;
}
interface IIdentifier {
  reportId: string;
  companyId: string;
}

class LocationTreeModel extends Model<ITree, IConfig, IIdentifier> {
  private locationMapper = new Map<string, string>();
  private floorMapper = new Map<
    string,
    {
      floorLevel: number;
      floorId: string;
      locationId: string;
      locationIsFloor: boolean;
    }
  >();

  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
    // this.api.registerMock<API.ILocationTree>(new RegExp('/*/'), 'GET').reply(200, locationtree);
  }
  public idMapper(id: IIdentifier) {
    return `hierarchy|${id.companyId}`;
  }

  public getLocationName(locationId: string) {
    return this.locationMapper.get(locationId) || locationId;
  }
  public getFloor(locationId: string) {
    return this.floorMapper.get(locationId);
  }

  public fromLocationToTree(
    data: API.ILocationTree,
    id: IIdentifier,
    level: number
  ): ITree<API.ILocationTree> {
    let icon = null;
    switch (data.type) {
      case 'building':
        icon = 'icon-building';
        break;
      case 'floor':
        icon = 'icon-layer-group';
        break;
      case 'zone':
        icon = 'icon-square-full';
        break;
    }
    this.locationMapper.set(data._id, data.name);
    if (data.floor) {
      this.floorMapper.set(data._id, {
        floorId: data.floor._id,
        locationId: data._id,
        floorLevel: data.floor.floorLevel,
        locationIsFloor: data.floor._id === data._id,
      });
    }
    return {
      id: data._id,
      title: data.name,
      children: data.children
        ? data.children.map(t => this.fromLocationToTree(t, id, level + 1))
        : [],
      description: data.type,
      iconColor: '#000',
      icon,
      isHidden: false,
      selected: false,
      level,
      originalData: data,
    };
  }
  public loadCompanyTree(companyId: string) {
    return this.load({
      companyId,
      reportId: REPORT_ID,
    });
  }
  protected mapObject(obj: API.ILocationTree[], id: IIdentifier): ITree {
    return {
      id: null,
      title: 'location',
      isHidden: true,
      isRoot: true,
      expanded: true,
      children: obj.map(t => this.fromLocationToTree(t, id, 1)),
    };
  }

  protected async single(id: IIdentifier) {
    const { companyId, reportId } = id;
    //

    const { data } = await this.api.get<API.ILocationTree[]>(
      `dashboard/companies/${companyId}/reports/${reportId}/location-hierarchy`
    );
    return {
      data,
      identifier: id,
    };
  }
}
export default new LocationTreeModel('LocationModel');
