import {
  Actions,
  BStyle,
  BType,
  Icon,
  IconSize,
  ModelSubscription,
  OptionTrigger,
  useDeepEqualEffect,
} from '@smart/design';
import { IChartDataset } from 'chart.js';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChartModelDisplay from '../../../components/ChartModelDisplay';
import Loader from '../../../components/ContentLoader';
import PropertyDataModel from '../../../models/PropertyDataModel';
import AggregationModel from '../../../services/charts/builders/AggregationModel';
import { useBuilder } from '../BuilderContext';
import { isValidAggregation } from '../forms/common';
import DefaultFilterForm from '../forms/DefaultFilterForm';
import { findRange } from './common';
import { useComponentContext } from './ComponentContext';
import NoData from './NoData';

interface IProps {
  component: FORM.ILabelComponentForm;
}

const Label = (props: {
  range: FORM.IRange;
  label: string;
  value: number;
  valueLabel: string;
  description: string;
  icon: string;
}) => {
  return (
    <div
      style={{
        backgroundColor: props.range?.color || '#eee',
        color: props.range?.color ? '#fff' : null,
        textAlign: 'center',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <h4>{props.label}</h4>
        {props.description && <em>{props.description}</em>}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        {props.icon && (
          <Icon
            style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}
            size={IconSize.LARGE}
            icon={props.icon}
          ></Icon>
        )}
        {props.range && (
          <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <div style={{ fontSize: 32 }}>{props?.value}</div>
          </div>
        )}
      </div>

      {props.range ? <p>{props.range.name}</p> : <NoData />}
    </div>
  );
};
const Gauge = (props: {
  ranges: FORM.IRange[];
  label: string;
  value: number;
  valueLabel: string;
}) => {
  if (!props.ranges || !props.ranges.length) return null;
  return (
    <ChartModelDisplay
      chartConfig={{
        options: {
          title: {
            display: props.label ? true : false,
            text: props.label,
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            title: {
              text: props.valueLabel,
              display: props.valueLabel ? true : false,
            },
            labels: {
              generateLabels: chart => {
                const options = chart.options.legend || {};
                return props.ranges.map(r => {
                  return {
                    fillStyle: r.color,
                    text: `${r.name} (${[r.min, r.max].join(' - ')})`,
                    datasetIndex: 1,
                  };
                });
              },
            },
          },
        },

        data: {
          datasets: [
            {
              value: props.value,
              label: props.ranges.map(x => [x.min, x.max].join(' - ')).join(' '),
              data: props.ranges.map(x => x.max),
              backgroundColor: props.ranges.map(x => x.color),
              borderWidth: 2,

              datalabels: {
                display: true,
                backgroundColor: context => {
                  return context.dataset.backgroundColor;
                },
                borderColor: 'white',
                borderRadius: 25,
                borderWidth: 2,
                color: '#000',
                font: {
                  weight: 'bold',
                },
              },
            } as IChartDataset<'gauge'> & {
              legend?: string;
            },
          ],
          labels: [],
        },
        type: 'gauge',
      }}
    />
  );
};

const Display = (props: {
  data: MODEL.IAggregatedDataModel;
  component: FORM.ILabelComponentForm;
}) => {
  const { ranges, displayMode, label } = props.component;
  const componentContext = useComponentContext();
  const context = useBuilder();

  const [value, setValue] = useState<{
    value: number;
    valueLabel: string;
    range: FORM.IRange;
  }>({
    value: null,
    valueLabel: null,
    range: null,
  });

  useEffect(() => {
    componentContext.renderActions(
      <Actions.Container noPadding={true}>
        <OptionTrigger
          actionButton={{
            icon: 'icon-filter',
            bType: BType.ICON,
            bStyle: props.component.overrideFilter ? BStyle.WARNING : BStyle.MUTED,
          }}
          onOpen={() => {
            componentContext.blockBlur(true);
          }}
          onClose={() => {
            componentContext.blockBlur(false);
          }}
          render={() => {
            return (
              <DefaultFilterForm
                form={props.component.overrideFilter}
                overrideAble={true}
                onChange={filter => {
                  props.component.overrideFilter = filter;
                  context.setFormComponent(props.component.id, props.component);
                }}
              />
            );
          }}
        />
      </Actions.Container>
    );
  }, [props.component.overrideFilter]);

  const load = useCallback((data: MODEL.IAggregatedDataModel, r: FORM.IRange[] = []) => {
    if (!data) return;
    if (isValidAggregation(data.aggregation)) {
      setValue({
        range: null,
        value: null,
        valueLabel: null,
      });
      const model = new AggregationModel({
        aggregation: data.aggregation,
        dataModel: data,
      });

      const v = get(data.data, [0, model.valueValues[0]], null);
      const val = {
        value: v,
        valueLabel: model.valueValues[0],
        range: null,
      };
      if (v) {
        val.range = findRange(v, r);
      }

      setValue(val);
    }
  }, []);
  useEffect(() => {
    load(props.data, props.component.ranges);
  }, []);
  useDeepEqualEffect(() => {
    load(props.data, props.component.ranges);
  }, [props.data, props.component]);
  return useMemo(() => {
    switch (props.component.displayMode) {
      case 'gauge':
        return (
          <Gauge valueLabel={value.valueLabel} value={value.value} ranges={ranges} label={label} />
        );
      case 'text':
        return (
          <Label
            description={props.component.description}
            range={value.range}
            icon={props.component.icon}
            label={props.component.label}
            value={value.value}
            valueLabel={value.valueLabel}
          />
        );
      default:
        return null;
    }
  }, [value, props.component]);
};
const LabelDisplay = (props: IProps) => {
  const context = useBuilder();
  const { filters, companyId, reportId } = context;
  const { aggregation, property, overrideFilter } = props.component;
  const c = useCallback(
    data => {
      const {
        modelData: { $data },
      } = data;
      if (data.modelData.$data.type === 'aggregated') {
        return (
          <Display
            key={props.component.id}
            data={data.modelData.$data}
            component={props.component}
          />
        );
      }
      return null;
    },
    [props.component]
  );
  return (
    <ModelSubscription
      model={PropertyDataModel}
      id={{
        property,
        companyId,
        reportId,
        aggregation,

        filter: PropertyDataModel.combineFilter(filters, overrideFilter),
      }}
      options={{
        loader: Loader,
      }}
      render={c}
    />
  );
};

export default LabelDisplay;
