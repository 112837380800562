import { Model, IApi, Api } from '@smart/design';
import mapService from '../services/mapService';

import FloorMapModel from './FloorMapModel';
interface IConfig {
  apiURL: string;
}

class LocationModel extends Model<MODEL.ILocationModel, IConfig, MAP.ILocationIdentifier> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
    // this.api.registerMock(new RegExp('/*/'),'GET').reply(200,locationmap);
  }

  public idMapper(id: MAP.ILocationIdentifier) {
    return `${id.companyId}:${id.locationId}`;
  }

  protected mapObject(
    obj:{
      location:API.CLIENT.ILocation,
    },
    identifier: MAP.ILocationIdentifier
  ): MODEL.ILocationModel {
    return {
      identifier,
      collection: {
        features: [obj.location.geoJsonFeature],
        type: 'FeatureCollection',
      },
      bounds: mapService.getMapBoundsFromFeatures([obj.location.geoJsonFeature]),
    };
  }

  protected async single(identifier: MAP.ILocationIdentifier) {
    const { companyId, locationId } = identifier;
    const { data } = await this.api.get<API.CLIENT.ILocation>(`client/companies/${companyId}/locations/${locationId}`);
   

    return {
      data:{
        location:data,
      },
      identifier,
    };
  }
}
export default new LocationModel('LocationMapModel');
