import { modals, notifications, Router, Deferred, modalManager } from '@smart/design';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReportModel from '../../models/ReportModel';
import { IBuilderContext } from './BuilderContext';
import DuplicateDashboard from './modals/DuplicateDashboard';

export async function duplicateDashboard(form: {
  companyId: string;
  reportId: string;
  title: string;
}) {
  const changed = await modalManager.show<FORM.IReportForm>(DuplicateDashboard, {
    companyId: form.companyId,
    reportId: form.reportId,
    title: form.title,
  });
  if (changed) {
    try {
      await ReportModel.create(changed);
      notifications.success();
      goBoards();
    } catch (e) {
      modals.error(e);
    }
  }
}

export async function saveDashboard(store: IBuilderContext) {
  if (store.isNew) {
    return createDashboard(store);
  }
  return updateDashboard(store);
}
export async function createDashboard(store: IBuilderContext) {
  const confirmed = await modals.confirm();
  if (confirmed) {
    try {
      await store.syncRemote();
      notifications.success();
      goBoards();
    } catch (e) {
      modals.error(e);
    }
  }
}

export async function updateDashboard(store: IBuilderContext) {
  const confirmed = await modals.confirm();
  if (confirmed) {
    try {
      await store.syncRemote();
      notifications.success();
    } catch (e) {
      modals.error(e);
    }
  }
}

export async function cancelCreate(form: FORM.IReportForm) {
  const confirmed = await modals.confirm();
  if (confirmed) {
    goBoards();
  }
}
export function goBoards() {
  const m = Router.matchPath(
    Router.BrowserHistory.location.pathname,
    '/companies/:companyId/dashboards'
  );

  if (m) {
    Router.BrowserHistory.push(m.url);
  }
}

export async function generatePDF(
  form: FORM.IReportForm,
  htmlnode: HTMLElement,
  mode: 'print' | 'download' = 'download'
): Promise<void> {
  htmlnode.classList.add('printing');
  const def = new Deferred<void>();
  const nodeWidth = htmlnode.offsetWidth;
  const nodeHeight = htmlnode.scrollHeight;

  const topLeftMargin = 0;
  const pdfWidth = nodeWidth + topLeftMargin * 2;
  const pdfHeight = pdfWidth * 1.5 + topLeftMargin * 2;
  const canvasImageWidth = nodeWidth;
  const canvasImageHeight = nodeHeight;
  const numPages = Math.ceil(nodeHeight / pdfHeight) - 1;

  const canvas = await html2canvas(htmlnode, {
    allowTaint: true,

    ignoreElements: (element: Element) => {
      return element.classList.contains('print-ignore');
    },
  });
  const imgData = canvas.toDataURL('image/png', 1.0);
  const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
  pdf.addImage(imgData, 'png', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);

  for (let i = 1; i <= numPages; i++) {
    pdf.addPage([pdfWidth, pdfHeight]);

    pdf.addImage(
      imgData,
      'png',
      topLeftMargin,
      -(pdfHeight * i) + topLeftMargin * 4,
      canvasImageWidth,
      canvasImageHeight
    );
  }
  if (mode === 'download') {
    try {
      await pdf.save(`${form.title}.pdf`, {
        returnPromise: true,
      });
      def.resolve();
    } catch {
      def.reject();
    }
  } else if (mode === 'print') {
    printPage(pdf.output('bloburl'))
      .then(def.resolve)
      .catch(error => {
        // Fallback printing method
        pdf.autoPrint();
        pdf.output('dataurlnewwindow');
        def.reject();
      });
  }
  def.promise.finally(() => {
    htmlnode.classList.remove('printing');
  });
  return def.promise;
}
async function printPage(sURL: URL) {
  const frame = document.createElement('iframe');
  const def = new Deferred();

  frame.onload = () => {
    try {
      frame.contentWindow.focus(); // Required for IE
      try {
        frame.contentWindow.document.execCommand('print', false, null);
      } catch {
        frame.contentWindow.print();
      }
      def.resolve();
    } catch (error) {
      def.reject(error);
    }
  };
  frame.style.position = 'fixed';
  frame.style.right = '0';
  frame.style.bottom = '0';
  frame.style.width = '0';
  frame.style.height = '0';
  frame.style.border = '0';
  frame.src = sURL.toString();
  document.body.appendChild(frame);

  const focus = () => {
    document.body.removeChild(frame);
    window.removeEventListener('focus', focus);
  };
  window.addEventListener('focus', focus);
  return def.promise;
}
