import { Service } from "@smart/design";
import {get}from 'lodash';
interface IConfig{
  campusTag:string;
  mazeMapToken:string;
  mapboxToken:string;
}
class MazeMapService extends Service{
  private campus:string;
  private token :string;
  public async initialize(config:IConfig){
    this.campus = config.campusTag;
    this.token = config.mazeMapToken;
  }
  public getCampus(){
    return this.campus;
  }

  public getMapBoundsFromFeatures(features:GeoJSON.Feature[])  {
    let minLng = 180;
    let maxLng = -180;
    let minLat = 90;
    let maxLat = -90;
  
    features.forEach(feature => {
      if (feature.geometry.type === "Point") {
        const [lng,lat] = feature.geometry.coordinates;
        if (lng < minLng) {
          minLng = lng;
        }
        if (lng > maxLng) {
          maxLng = lng;
        }
        if (lat < minLat) {
          minLat = lat;
        }
        if (lat > maxLat) {
          maxLat = lat;
        }
      }
      else if (feature.geometry.type === "Polygon") {
        const coordinates = get(feature, "geometry.coordinates[0]", []);
        if (Array.isArray(coordinates)) {
          coordinates.forEach(([lng,lat]) => {
  
            if (lng < minLng) {
              minLng = lng;
            }
            if (lng > maxLng) {
              maxLng = lng;
            }
            if (lat < minLat) {
              minLat = lat;
            }
            if (lat > maxLat) {
              maxLat = lat;
            }
          });
        }
      }
      else if (feature.geometry.type === "MultiPolygon") {
        const polygons = get(feature, "geometry.coordinates", []);
        if (Array.isArray(polygons)) {
          polygons.forEach(polygon => {
            const coordinates = get(polygon, "[0]", []);
            if (Array.isArray(coordinates)) {
              coordinates.forEach(([lng,lat]) => {
                if (lng < minLng) {
                  minLng = lng;
                }
                if (lng > maxLng) {
                  maxLng = lng;
                }
                if (lat < minLat) {
                  minLat = lat;
                }
                if (lat > maxLat) {
                  maxLat = lat;
                }
              });
            }
          });
        }
      }
    });
    
    const sw = new mapboxgl.LngLat(minLng, minLat);
    const ne = new mapboxgl.LngLat(maxLng, maxLat);
    const llb = new mapboxgl.LngLatBounds(sw, ne);
    
    return llb;
  };
  

}

export default new MazeMapService()
