import { useState, useEffect } from 'react';
import { ITree, useAsyncEffect } from '@smart/design';
import CustomTagModel from '../models/CustomTagModel';
export default function useCustomTag(companyId: string) {
  const [tags, setTags] = useState<ITree>(null);

  useAsyncEffect(function*(onCancel, c) {
    const tree = yield* c(CustomTagModel.load(companyId));
    setTags(tree);
  }, []);

  return tags;
}
