import {
  Actions,
  BS,
  BShape,
  BStyle,
  IconStyle,
  modals,
  TooltipPlacement,
  translator,
  useHover,
  useDebounceCallback,
  useIsMounted,
} from '@smart/design';
import React, { useCallback, useMemo, useState, RefObject, useEffect, useRef } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ComponentType } from '../../../statics';
import GraphDisplay from './GraphDisplay';
import ImageDisplay from './ImageDisplay';
import LabelDisplay from './LabelDisplay';
import MapDisplay from './MapDisplay';
import TextDisplay from './TextDisplay';
import { copyComponentToClipboard } from './UiActions';
import { ComponentContext } from './ComponentContext';
import ClockDisplay from './ClockDisplay';

const ComponentDisplay = (props: {
  component: FORM.IComponentFormType;
  remove?: () => void;
  clone?: () => void;
  onClick: () => void;
  viewOnly?: boolean;
  layoutLocks?: boolean;

  expand: (expanded: boolean, element: HTMLDivElement) => void;
}) => {
  const [actions, setActions] = useState<JSX.Element>(null);
  const mounted = useIsMounted();
  const [ref, hover] = useHover<HTMLDivElement>();
  const [expanded, setExpanded] = useState<boolean>(false);

  const blockRef = useRef(false);
  const renderActions = useDebounceCallback(node => {
    mounted() && setActions(node);
  }, 300);
  const blockBlur = useCallback((value = false) => {
    blockRef.current = value;
  }, []);

  const component = useMemo(() => {
    let C = null;
    switch (props.component.type) {
      case ComponentType.GRAPH:
        C = <GraphDisplay component={props.component} />;
        break;
      case ComponentType.TEXT:
        C = <TextDisplay component={props.component} />;
        break;
      case ComponentType.IMAGE:
        C = <ImageDisplay component={props.component} />;
        break;
      case ComponentType.MAP:
        C = <MapDisplay component={props.component} />;
        break;
      case ComponentType.CLOCK:
        C = <ClockDisplay component={props.component} />;
        break;
      case ComponentType.LABEL:
      case ComponentType.GAUGE:
        C = <LabelDisplay component={props.component} />;
        break;
    }

    return C;
  }, [props.component]);

  return (
    <ComponentContext.Provider
      value={{
        renderActions: renderActions.callback,
        blockBlur,
      }}
    >
      <div
        style={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          backgroundColor: '#fff',
        }}
        className="printable-dashboard-item"
        ref={ref}
      >
        {component}

        {(hover || (!hover && blockRef.current)) && !props.layoutLocks && (
          <div
            className="boxed"
            style={{
              position: 'absolute',
              right: 2,
              top: 2,
              backgroundColor: '#fff',
            }}
          >
            <div style={{ padding: 3 }} className="print-ignore">
              <Actions.Container noPadding={true} buttonSpace={4} wrapped={true}>
                {!props.viewOnly && (
                  <BS.IconButton
                    bStyle={BStyle.PRIMARY}
                    icon="icon-cog"
                    onClick={props.onClick}
                    tooltipConf={{
                      placement: TooltipPlacement.LEFT,
                    }}
                  />
                )}

                {actions}
                {navigator.clipboard ? (
                  <BS.IconButton
                    tooltip={translator.t('tooltip.copyToClipboard')}
                    icon="icon-clipboard"
                    onClick={() => copyComponentToClipboard(ref.current)}
                  />
                ) : null}
                {!props.viewOnly ? (
                  <BS.IconButton
                    tooltip={translator.t('tooltip.createDuplicate')}
                    icon="icon-clone"
                    onClick={() => {
                      props.clone();
                    }}
                  />
                ) : null}
                {props.remove && (
                  <BS.IconButton
                    icon={expanded ? 'icon-compress' : 'icon-expand'}
                    tooltip={translator.t('btn.expand')}
                    iconStyle={IconStyle.MUTED}
                    onClick={async () => {
                      if (!expanded) {
                        props.expand(true, ref.current);
                      } else {
                        props.expand(false, ref.current);
                      }

                      setExpanded(!expanded);
                    }}
                  />
                )}

                {!expanded && props.remove && !props.viewOnly ? (
                  <>
                    |
                    <BS.IconButton
                      icon="icon-times"
                      tooltip={translator.t('btn.remove')}
                      bStyle={BStyle.DANGER}
                      onClick={async () => {
                        const confirmed = await modals.confirm();
                        if (confirmed) {
                          props.remove();
                        }
                      }}
                    />
                  </>
                ) : null}
              </Actions.Container>
            </div>
          </div>
        )}
      </div>
    </ComponentContext.Provider>
  );
};
export default ComponentDisplay;
