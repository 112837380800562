import {
  Center,
  helper,
  Icon,
  IconStyle,
  Seperator,
  SmartForm,
  Tabs,
  translator,
  useForceUpdate,
} from '@smart/design';
import React, { useCallback, useRef } from 'react';
import GaugeIconUrl from '../../../../statics/img/gauge.svg';
import LabelIconUrl from '../../../../statics/img/label.svg';
import PropertyTypeModel from '../../../models/PropertyTypeModel';
import { ComponentType } from '../../../statics';
import { useBuilder } from '../BuilderContext';
import AggregationForm from './aggregation/AggregationForm';
import { aggregationArrayToObject, aggregationObjectToArray, isValidAggregation } from './common';
import DefaultFilterForm from './DefaultFilterForm';
interface IProps {
  form: Partial<FORM.ILabelComponentForm>;
  onChange: (component: FORM.ILabelComponentForm) => void;
}

const LabelForm = (props: IProps) => {
  const { form, onChange } = props;
  const { form: builderForm, companyId, reportId } = useBuilder();
  const update = useForceUpdate();
  const componentForm = useRef<Partial<FORM.ILabelComponentForm>>(props.form);
  const [s1, s2] = aggregationObjectToArray(form?.aggregation, {
    agg1: {
      by: ['merge'],
      aggregate: [],
    },
  });
  const aggregateForm = useRef<FORM.IIPipelineAggregation[]>(s1);
  const aggregateUsages = useRef<FORM.IAggregationUsage[]>(s2);

  const filterForm = useRef<FORM.IOverideableReportFilterForm>(form.overrideFilter);
  const applyChanges = useCallback(() => {
    update();
    if (!componentForm.current.property) return;
    const changes: FORM.ILabelComponentForm = {
      property: componentForm.current.property,
      label: componentForm.current.label,
      ranges: componentForm.current.ranges,
      type: componentForm.current.type,
      displayMode: componentForm.current.displayMode,
      description: componentForm.current.description,
      icon: componentForm.current.icon,
      id: form.id,
      overrideFilter: filterForm.current ? filterForm.current : undefined,
    };

    if (aggregateForm.current.length) {
      changes.aggregation = aggregationArrayToObject(
        aggregateForm.current,
        aggregateUsages.current
      );
    }
    if (isValidAggregation(changes.aggregation)) onChange(changes);
  }, [aggregateForm, filterForm, componentForm, form, onChange]);

  const displayMode =
    componentForm.current?.displayMode || componentForm.current.type === ComponentType.LABEL
      ? 'text'
      : 'gauge';

  return (
    <>
      <SmartForm.Form<FORM.ILabelComponentForm>
        onChange={async (f, formState) => {
          componentForm.current = {
            ...componentForm.current,
            ...f,
          };

          applyChanges();
        }}
        noSubmit={true}
        form={[
          {
            property: 'label',
            type: 'input',
            label: translator.t('label.title'),
            required: () => true,
            value: componentForm.current?.label,
          },

          {
            property: 'icon',
            type: 'input',
            label: translator.t('label.icon'),
            value: componentForm.current?.icon,
          },

          {
            property: 'property',
            type: 'searchSelect',
            value: componentForm.current?.property,
            required: () => true,
            fetch: async q => {
              const d = await PropertyTypeModel.load(companyId);
              return helper.searchArray(d, 'value', q);
            },
            preload: async () => {
              return PropertyTypeModel.load(companyId);
            },
            component: value => {
              return <div>{value}</div>;
            },
            label: translator.t('label.property'),
          },
          {
            property: 'ranges',
            type: 'spreadsheet',
            value: componentForm.current?.ranges,

            required: () => true,
            onNewRow: (index, rows) => {
              const max = rows ? Math.max(...rows.map(r => r.max)) : null;
              return {
                min: max,
                max,
                color: undefined,
                name: undefined,
              };
            },
            validateRow: x => (x.min && x.max ? true : false),
            rowKey: (r, index) => `${r?.name}_${index}`,
            columns: [
              {
                label: translator.t('label.label'),
                form: {
                  type: 'input',
                  property: 'name',
                  required: () => true,
                },
              },
              {
                label: translator.t('label.min'),

                form: {
                  type: 'input',
                  number: true,
                  property: 'min',
                  clearAble: true,
                },
              },
              {
                label: translator.t('label.max'),
                form: {
                  type: 'input',
                  number: true,
                  property: 'max',
                  clearAble: true,
                },
              },
              {
                label: translator.t('label.color'),
                form: {
                  type: 'color',
                  property: 'color',
                  mode: 'trigger',
                  picker: 'twitter',
                },
              },
            ],

            label: translator.t('label.ranges'),
          },
          SmartForm.createFormField({
            property: 'icon',
            type: 'searchSelect',
            dropUp: true,
            value: componentForm.current?.icon,
            placeholder: translator.t('label.search'),
            removeAble: () => true,
            allowNull: true,
            fetch: async q => {
              const { icons } = await import('../../../../statics/icon.json');

              return helper
                .searchArray(icons, 'name', q)
                .map(x => ({
                  label: (
                    <>
                      <Icon icon={`icon-${x.id}`}></Icon>
                    </>
                  ),

                  value: `icon-${x.id}`,
                  description: x.name,
                }))
                .splice(0, 20);
            },
            component: t => <Icon icon={t} iStyle={IconStyle.PRIMARY} />,
            label: translator.t('label.icon'),
          }),
          {
            property: 'displayMode',
            type: 'boxSelect',
            label: translator.t('label.mode'),
            required: () => true,
            value: displayMode,
            options: [
              {
                label: 'label',
                iconUrl: LabelIconUrl,

                value: 'text',
              },
              {
                label: 'gauge',
                iconUrl: GaugeIconUrl,
                value: 'gauge',
              },
            ],
            optionRender: v => {
              if (v.iconUrl) {
                return (
                  <Center full>
                    <img
                      width="80%"
                      style={{ objectFit: 'contain' }}
                      height="80%"
                      src={v.iconUrl}
                    ></img>
                  </Center>
                );
              }
              return <div>{v.label}</div>;
            },
          },
        ]}
      >
        <SmartForm.Field property="label" />

        <SmartForm.Field property="property" />
        <SmartForm.Field property="ranges" />
        <SmartForm.Field property="icon" />

        <SmartForm.Field property="displayMode" />

        <Seperator underline={true} />
      </SmartForm.Form>
      {componentForm.current.property &&
      componentForm.current.ranges &&
      componentForm.current.ranges.length &&
      componentForm.current.displayMode ? (
        <div>
          <Tabs.Container noPadding={true}>
            <Tabs.Tab tabId="aggregates" label={translator.t('label.aggregates')} noPadding={true}>
              <AggregationForm
                aggregation={aggregateForm.current}
                usages={aggregateUsages.current}
                maxLevel={1}
                property={componentForm?.current?.property}
                type="label"
                onApply={(agg, usages) => {
                  aggregateForm.current = agg;
                  aggregateUsages.current = usages;
                  applyChanges();
                }}
              />
            </Tabs.Tab>

            <Tabs.Tab tabId="filter" label={translator.t('label.filter')} noPadding={true}>
              <div>
                <Seperator />
                <DefaultFilterForm
                  overrideAble={true}
                  form={filterForm.current}
                  onChange={f => {
                    filterForm.current = f;
                    applyChanges();
                  }}
                />
              </div>
            </Tabs.Tab>
          </Tabs.Container>
        </div>
      ) : null}
    </>
  );
};

export default LabelForm;
