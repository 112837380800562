import { useState, useEffect } from 'react';
import { ITree, useAsyncEffect } from '@smart/design';
import LocationTreeModel from '../models/LocationTreeModel';
import LocationTypeTreeModel from '../models/LocationTypeTreeModel';
export default function useLocationAndTypes(companyId: string) {
  const [state, setState] = useState<{
    tree: ITree;
    types: ITree;
  }>({
    tree: null,
    types: null,
  });
  useAsyncEffect(function*(onCancel, c) {
    const tree = yield* c(LocationTreeModel.loadCompanyTree(companyId));
    const types = yield* c(LocationTypeTreeModel.loadCompanyTypes(companyId));

    setState({
      tree,
      types,
    });
  }, []);
  return [state.tree, state.types];
}
