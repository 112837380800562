import { Model, IApi, Api, IDataTableFetchResponse, helper } from '@smart/design';

interface IConfig {
  apiURL: string;
}
// Generated by https://quicktype.io

interface IModel {
  id: string;
  name: string;
}
class CompanyModel extends Model<IModel, IConfig, string> {
  private cacheApi: IApi;

  public async initialize(config: IConfig) {
    this.cacheApi = Api.newApi(config.apiURL, {
      useCache: true,
    });
  }

  public mapObject(obj, identifier) {
    return obj;
  }

  public idMapper(id) {
    return `allCompanies`;
  }

  protected async single(identifier) {
    const { data } = await this.cacheApi.get<{
      offset: number;
      limit: number;
      count: number;
      companies: Array<{
        name: string;
        _id: string;
      }>;
    }>('client/companies');

    return {
      data: data.companies.find(x => x._id === identifier),
      identifier,
    };
  }
}
export default new CompanyModel('CompanyModel');
