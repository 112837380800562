import { Model, IApi, Api, moment, helper, IListItem, translator } from '@smart/design';
import { REPORT_ID } from '../statics';

interface IConfig {
  apiURL: string;
}

interface IIdentifier {
  companyId: string;
}
/*
translator.t('property.atmosphericPressure')
translator.t('property.backgroundSoundPressure')
translator.t('property.brightness')
translator.t('property.carbonDioxideLevel')
translator.t('property.colorTemperature')
translator.t('property.footfallCountIn')
translator.t('property.footfallCountOut')
translator.t('property.hadCall')
translator.t('property.hadPresentation')
translator.t('property.humidity')
translator.t('property.motionCount')
translator.t('property.occupiedMinutes')
translator.t('property.peopleCount')
translator.t('property.soundLevelPressure')
translator.t('property.soundPressure')
translator.t('property.temperature')
translator.t('property.vocLevel')

*/
class PropertyTypeModel extends Model<Array<IListItem<string>>, IConfig, string> {
  private api: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  protected mapObject(obj: { properties: string[] }, id: string): Array<IListItem<string>> {
    if (obj && obj.properties) {
      return obj.properties.map(x => ({
        label: x,
        value: x,
      }));
    }
    return [];
  }

  protected async single(id: string) {
    const { data } = await this.api.get(
      `dashboard/companies/${id}/reports/${REPORT_ID}/property-types`
    );

    return {
      data,
      identifier: id,
    };
  }
}

export default new PropertyTypeModel('PropertyTypeModel');
