import { Model, IApi, Api } from '@smart/design';

interface IConfig {
  apiURL: string;
}
interface IIdentifier {
  companyId: string;
  reportId: string;
}
class ReportAccessModel extends Model<API.IAccess, IConfig, IIdentifier> {
  private api: IApi;
  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  public idMapper(id: IIdentifier) {
    return `reportAccess:${id.companyId}|${id.reportId}`;
  }

  public async setAccess(companyId: string, reportId: string, accessList: string[]) {
    return this.api.put(`dashboard/companies/${companyId}/reports/${reportId}/access`, {
      companyIds: accessList,
      allCompanies: false,
    });
  }
  public async setAccessAll(companyId: string, reportId: string, all: boolean) {
    return this.api.put(`dashboard/companies/${companyId}/reports/${reportId}/access`, {
      allCompanies: all,
      companyIds: [],
    });
  }

  protected mapObject(obj: API.IAccess, id: IIdentifier): API.IAccess {
    return obj;
  }

  protected async single(id: IIdentifier) {
    const { companyId, reportId } = id;

    const { data: accessData } = await this.api.get<API.IReport>(
      `dashboard/companies/${companyId}/reports/${reportId}/access`
    );
    return {
      data: accessData,
      identifier: id,
    };
  }
}
export default new ReportAccessModel('ReportAccessModel');
