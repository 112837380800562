import { Api, IApi, Model } from '@smart/design';
import { union, uniq, intersection, flatten, values, omit } from 'lodash';
import { REPORT_ID } from '../statics';

/*
translator.t('aggregation.group.merge')
translator.t('aggregation.group.entityId')
translator.t('aggregation.group.locationType')
translator.t('aggregation.group.softCapacityThreshold')
translator.t('aggregation.group.hardCapacityThreshold')
translator.t('aggregation.group.dayOfWeek')
translator.t('aggregation.group.quarter')
translator.t('aggregation.group.hour')
translator.t('aggregation.group.day')
translator.t('aggregation.group.week')
translator.t('aggregation.group.month')

translator.t('aggregation.property.max')
translator.t('aggregation.property.avg')
translator.t('aggregation.property.max')
translator.t('aggregation.property.first')
translator.t('aggregation.property.min')

translator.t('aggregation.property.sum')
translator.t('aggregation.property.numberOfSamples')
translator.t('aggregation.property.softCapacityThreshold')
translator.t('aggregation.property.hardCapacityThreshold')

translator.t('aggregation.operation.max')
translator.t('aggregation.operation.avg')
translator.t('aggregation.operation.min')
translator.t('aggregation.operation.first')
translator.t('aggregation.operation.sum')





*/

interface IConfig {
  apiURL: string;
}

interface IIdentifier {
  companyId: string;
  reportId?: string;
}
class AggregationPropertyModel extends Model<API.IAggregationProperty, IConfig, IIdentifier> {
  private api: IApi;

  public async initialize(config: IConfig) {
    this.api = Api.newApi(config.apiURL);
  }

  public idMapper(identifier: IIdentifier) {
    return `${identifier.companyId}|${identifier.reportId}`;
  }
  public getReplaceableBy(model: API.IAggregationProperty, prevBy: string[], currentBy: string[]) {
    let b = currentBy;

    if (prevBy && prevBy.length) {
      prevBy.forEach(c => {
        const gp = model.groupByProperties.find(x => x.name === c);

        if (gp) {
          const int = intersection(gp.replaceableWith || [], currentBy);

          if (!int.length) {
            b = union(prevBy, gp.replaceableWith || []);
          }
        }
      });
    }
    return uniq(b);
  }
  public getCombinableBy(model: API.IAggregationProperty, currentBy: string[]) {
    let b = [];
    if (currentBy && currentBy.length) {
      currentBy.forEach(c => {
        const gp = model.groupByProperties.find(x => x.name === c);

        if (gp) {
          b = union(b, gp.combinableWith);
        }
      });
      b = b.filter(x => !currentBy.includes(x));
    } else {
      b = model.groupByProperties.map(x => x.name);
    }
    return uniq(b);
  }
  public getProperties(model: API.IAggregationProperty, properties: string[]) {
    let b = [];

    if (properties && properties.length) {
      properties.forEach(c => {
        const gp = model.operations.find(x => x.name === c);
        b = union(b, gp.name);
      });
      b = b.filter(x => !properties.includes(x));
    } else {
      b = model.initialProperties.map(x => x.name);
    }

    return uniq(b);
  }
  public toGroupList(groupBy: string[] = []) {
    return groupBy.map(x => ({
      label: x,
      value: x,
      id: x,
    }));
  }
  public toPropertyList(groupBy: string[] = []) {
    return groupBy.map(x => ({
      label: x,
      value: x,
      id: x,
    }));
  }
  public toOperationList(list: string[] = []) {
    return list.map(x => ({
      label: x,
      value: x,
      id: x,
    }));
  }

  public async hasPostProcessing(identifier: IIdentifier, value: string) {
    const t = await this.load(identifier);

    if (t.postprocessors) {
      return flatten(values(omit(t.postprocessors, 'date', 'string'))).includes(value);
    }
    return false;
  }
  protected mapObject(obj: API.IAggregationProperty): API.IAggregationProperty {
    obj.postprocessors = Object.assign({}, obj.postprocessors, {
      date: [
        'DD/MM/YYYY',
        'dddd DD MMMM YYYY',
        'MMMM Do YYYY',
        'L',
        'l',
        'LL',
        'll',
        'LLL',
        'lll',
        'LLLL',
        'llll',
      ],
    });
    return obj;
  }
  protected async single(identifier: IIdentifier) {
    const { companyId, reportId = REPORT_ID } = identifier;
    const { data } = await this.api.get(
      `dashboard/companies/${companyId}/reports/${reportId}/aggregation-properties`
    );

    return {
      data,
      identifier,
    };
  }
}

export default new AggregationPropertyModel('AggregationPropertyModel');
